import React, { useState, useEffect, useCallback } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import { Save } from '@mui/icons-material';
import axiosInstance from '../../../utils/axios';
import BrandCard from '../../Brands/BrandCard';
import SubBrandCard from '../../Brands/SubBrandCard';
import PricingStrategySelection from '../../Pricing/PricingStrategySelection';

function AddSubService({ serviceId, onSubServiceAdded }) {
  const [newSubServiceData, setNewSubServiceData] = useState({
    name: '',
    installTime: '',
    cureTime: '',
    pricingStrategy: 'BySquareFootage', // Set a default value for pricing strategy
  });

  const [brands, setBrands] = useState([]);

  const fetchBrands = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`/services/${serviceId}/brands`);
      const brandsData = await Promise.all(
        response.data.map(async (brand) => {
          const subBrandsResponse = await axiosInstance.get(`/brands/${brand._id}/sub-brands`);
          return {
            ...brand,
            subBrands: subBrandsResponse.data,
          };
        })
      );
      setBrands(brandsData);
    } catch (error) {
// $&

    }
  }, [serviceId]);

  useEffect(() => {
    if (serviceId) {
      fetchBrands();
    }
  }, [serviceId, fetchBrands]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewSubServiceData({ ...newSubServiceData, [name]: value });
  };

  const handlePricingStrategyChange = (e) => {
    setNewSubServiceData({ ...newSubServiceData, pricingStrategy: e.target.value });
  };

  const handleToggleBrand = (brandId) => {
    setBrands((prevBrands) =>
      prevBrands.map((brand) =>
        brand._id === brandId ? { ...brand, active: !brand.active } : brand
      )
    );
  };

  const handleUpdateSubBrand = (subBrandId, updateData) => {
    setBrands((prevBrands) =>
      prevBrands.map((brand) => {
        if (brand.subBrands && brand.subBrands.some((subBrand) => subBrand._id === subBrandId)) {
          const updatedSubBrands = brand.subBrands.map((subBrand) =>
            subBrand._id === subBrandId ? { ...subBrand, ...updateData } : subBrand
          );
          return { ...brand, subBrands: updatedSubBrands };
        }
        return brand;
      })
    );
  };

  const handleSave = async () => {
    try {
      const payload = {
        ...newSubServiceData,
        brands: brands.map((brand) => ({
          brandId: brand._id,
          active: brand.active,
          subBrands: brand.subBrands.map((subBrand) => ({
            subBrandId: subBrand._id,
            active: subBrand.active,
            price: subBrand.price,
          })),
        })),
      };

// $&


      const response = await axiosInstance.post(`/services/${serviceId}/sub-services`, payload);
      onSubServiceAdded(response.data);
      setNewSubServiceData({
        name: '',
        installTime: '',
        cureTime: '',
        pricingStrategy: 'BySquareFootage',
      });
    } catch (error) {
// $&

    }
  };

  return (
    <Box sx={{ mt: 3, bgcolor: '#1C1C1C', p: 3, borderRadius: 2, color: '#C0C0C0' }}>
      <Typography variant="h6" gutterBottom sx={{ color: '#39FF14' }}>
        Add New Sub-Service
      </Typography>
      <TextField
        label="Sub-Service Name"
        name="name"
        value={newSubServiceData.name}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        InputLabelProps={{ style: { color: '#C0C0C0' } }}
        InputProps={{ style: { color: '#00FFFF' } }}
      />
      <TextField
        label="Install Time (minutes)"
        name="installTime"
        value={newSubServiceData.installTime}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        InputLabelProps={{ style: { color: '#C0C0C0' } }}
        InputProps={{ style: { color: '#00FFFF' } }}
      />
      <TextField
        label="Cure Time (minutes)"
        name="cureTime"
        value={newSubServiceData.cureTime}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        InputLabelProps={{ style: { color: '#C0C0C0' } }}
        InputProps={{ style: { color: '#00FFFF' } }}
      />
      <PricingStrategySelection
        pricingStrategy={newSubServiceData.pricingStrategy}
        onChange={handlePricingStrategyChange}
      />
      <Box sx={{ mt: 3 }}>
        <Typography variant="h6" gutterBottom sx={{ color: '#39FF14' }}>
          Brands and Sub-Brands
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
          {brands.map((brand) => (
            <Box key={brand._id} sx={{ width: '30%', bgcolor: '#2C2C2C', p: 2, borderRadius: 2 }}>
              <BrandCard brand={brand} onToggle={handleToggleBrand} />
              {brand.active && (
                <Box sx={{ mt: 2 }}>
                  {brand.subBrands && brand.subBrands.map((subBrand) => (
                    <Box key={subBrand._id} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <Typography sx={{ color: '#C0C0C0', mr: 1 }}>{subBrand.name}</Typography>
                      <SubBrandCard
                        subBrand={subBrand}
                        onToggle={(subBrandId) =>
                          handleUpdateSubBrand(subBrandId, { active: !subBrand.active })
                        }
                        onPriceChange={(subBrandId, price) =>
                          handleUpdateSubBrand(subBrandId, { price: parseFloat(price) })
                        }
                      />
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Save />}
          onClick={handleSave}
          sx={{ bgcolor: '#39FF14', color: '#000000', '&:hover': { bgcolor: '#00FFFF' } }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
}

export default AddSubService;
