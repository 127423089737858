import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Box, Typography, Button, TextField, Alert } from '@mui/material';
import { styled } from '@mui/system';
import DriveSuiteLogo from '../../assets/images/Drive Suite Website.png';
import GoogleLoginButton from '../GoogleLoginButton';
import axiosAuth from '../../utils/axiosAuth'; 
import { jwtDecode } from 'jwt-decode'; 

const Container = styled(Box)({
  backgroundColor: '#1C1C1C',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#C0C0C0',
  padding: '2rem',
});

const Header = styled(Typography)({
  color: '#39FF14',
  marginBottom: '1rem',
  fontSize: '2rem',
  fontWeight: 'bold',
  textAlign: 'center',
});

const Logo = styled('img')({
  width: '300px',
  marginBottom: '2rem',
});

const StyledForm = styled(Form)({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  width: '100%',
  maxWidth: '400px',
});

function Login() {
  const navigate = useNavigate();

  const initialValues = {
    emailOrUsername: '',
    password: '',
  };

  const validationSchema = Yup.object({
    emailOrUsername: Yup.string().required('Required'),
    password: Yup.string().required('Required'),
  });

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      const response = await axiosAuth.post('/auth/login', {
        emailOrUsername: values.emailOrUsername,
        password: values.password,
      });
  
      const token = response.data.token;
      localStorage.setItem('token', token);
  
      const decodedToken = jwtDecode(token);
      console.log('[Login] Decoded Token:', decodedToken);
  
      const { accountLevelRole, appLevelRole, subdomain } = decodedToken;
  
      const currentSubdomain = window.location.hostname.split('.')[0];
      const isOnMainDomain = window.location.hostname === 'drivesuitecrm.com';
  
      if (!subdomain && !isOnMainDomain) {
        console.warn('[Login] Redirecting to main domain for user without subdomain.');
        window.location.href = `https://drivesuitecrm.com?token=${token}`;
        return;
      }
  
      if (subdomain && currentSubdomain !== subdomain) {
        console.warn('[Login] Redirecting to correct subdomain:', subdomain);
        window.location.href = `https://${subdomain}.drivesuitecrm.com?token=${token}`;
        return;
      }
  
      // Navigate to the correct dashboard
      if (accountLevelRole === 'Owner') {
        console.info('[Login] Redirecting Owner to dashboard.');
        navigate('/dashboard');
      } else if (appLevelRole === 'App-Admin') {
        console.info('[Login] Redirecting App-Admin to admin dashboard.');
        navigate('/admin');
      } else {
        console.info('[Login] Redirecting user to home.');
        navigate('/');
      }
    } catch (error) {
      console.error('[Login] Error during login:', error.response?.data || error.message);
      setErrors({ submit: error.response?.data?.message || 'Invalid credentials' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container>
      <Header>Welcome</Header>
      <Logo src={DriveSuiteLogo} alt="DriveSuite Logo" />
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting, handleChange, handleBlur, values, errors, touched }) => (
          <StyledForm>
            <TextField
              name="emailOrUsername"
              label="Email or Username"
              variant="outlined"
              fullWidth
              value={values.emailOrUsername}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.emailOrUsername && Boolean(errors.emailOrUsername)}
              helperText={touched.emailOrUsername && errors.emailOrUsername}
              InputLabelProps={{ style: { color: '#C0C0C0' } }}
              sx={{
                '& .MuiInputBase-root': { backgroundColor: '#2F2F2F', color: '#C0C0C0' },
                '& .MuiOutlinedInput-notchedOutline': { borderColor: '#00FFFF' },
                '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#39FF14' },
              }}
            />

            <TextField
              name="password"
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.password && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              InputLabelProps={{ style: { color: '#C0C0C0' } }}
              sx={{
                '& .MuiInputBase-root': { backgroundColor: '#2F2F2F', color: '#C0C0C0' },
                '& .MuiOutlinedInput-notchedOutline': { borderColor: '#00FFFF' },
                '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#39FF14' },
              }}
            />

            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitting}
              sx={{ backgroundColor: '#39FF14', color: '#1C1C1C', '&:hover': { backgroundColor: '#00FF00' } }}
            >
              Login
            </Button>
            {errors.submit && (
              <Alert severity="error" sx={{ marginTop: '1rem', backgroundColor: 'transparent', color: '#C0C0C0' }}>
                {errors.submit}
              </Alert>
            )}
          </StyledForm>
        )}
      </Formik>

      <GoogleLoginButton />
    </Container>
  );
}

export default Login;
