import React, { useState, useEffect } from 'react';
import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Button,
  TextField,
} from '@mui/material';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axios';
import axiosCustomerInstance from '../../utils/axiosCustomer';
import BayManagement from '../Bays/BayManagement'; // Import BayManagement component
import '../../styles/CalendarCustom.css';
import AppointmentCard from '../AppointmentCard/AppointmentCard';

const localizer = momentLocalizer(moment);

function SchedulerDashboard() {
  const navigate = useNavigate();
  const [schedulingMethod, setSchedulingMethod] = useState('bayOnly');
  const [businessHours, setBusinessHours] = useState({
    Monday: { open: '09:00', close: '17:00', isClosed: false },
    Tuesday: { open: '09:00', close: '17:00', isClosed: false },
    Wednesday: { open: '09:00', close: '17:00', isClosed: false },
    Thursday: { open: '09:00', close: '17:00', isClosed: false },
    Friday: { open: '09:00', close: '17:00', isClosed: false },
    Saturday: { open: '09:00', close: '17:00', isClosed: true },
    Sunday: { open: '09:00', close: '17:00', isClosed: true },
  });
  const [showBusinessHours, setShowBusinessHours] = useState(true); // Control visibility of business hours
  const [events, setEvents] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [appointmentModalOpen, setAppointmentModalOpen] = useState(false);
  const [bayManagementOpen, setBayManagementOpen] = useState(false); // Control bay management modal

  useEffect(() => {
    fetchSchedulerSettings();
    fetchAppointments(); // Ensure appointments are fetched at the start
  }, []);

  const fetchSchedulerSettings = async () => {
    try {
      const response = await axiosInstance.get('/scheduler-settings');
      if (response.data) {
        setSchedulingMethod(response.data.schedulingMethod);
        setBusinessHours(response.data.businessHours);
      }
    } catch (error) {
// $&

    }
  };

  const fetchAppointments = async () => {
    try {
      const response = await axiosInstance.get('/appointments');
      if (response.data) {
        const appointments = response.data;
  
        // Map appointment data to calendar events
        const mappedEvents = appointments.map((appointment) => {
          // Extracting services information from the array
          const serviceNames = appointment.services.map(
            (serviceData) => `${serviceData.service?.name || 'Service'} (${serviceData.subService?.name || 'SubService'})`
          ).join(', ');
  
          return {
            id: appointment._id,
            title: `${serviceNames} - ${appointment.customer?.firstName || 'Customer'} ${appointment.customer?.lastName || ''}`,
            start: new Date(appointment.startTime),
            end: new Date(appointment.endTime),
            resource: appointment,
          };
        });
  
        setEvents(mappedEvents);
        console.log('Fetched Events:', mappedEvents); // Log events for debugging
      }
    } catch (error) {
// $&

    }
  };


  const handleSchedulingMethodChange = (event) => {
    setSchedulingMethod(event.target.value);
  };

  const handleBusinessHoursChange = (day, field, value) => {
    setBusinessHours({
      ...businessHours,
      [day]: {
        ...businessHours[day],
        [field]: value,
      },
    });
  };

  const handleSaveSettings = async () => {
    try {
      const settings = {
        schedulingMethod,
        businessHours,
      };
      await axiosInstance.post('/scheduler-settings', settings);
// $&

    } catch (error) {
// $&

    }
  };

  const handleViewPublicScheduler = () => {
    navigate('/public-scheduler');
  };

  const handleSelectEvent = (event) => {
    setSelectedAppointment(event.resource);
    setAppointmentModalOpen(true);
  };

  const handleCloseAppointmentModal = () => {
    setAppointmentModalOpen(false);
    setSelectedAppointment(null);
    fetchAppointments(); // Refresh appointments after any changes
  };

  const toggleBusinessHours = () => {
    setShowBusinessHours(!showBusinessHours);
  };

  return (
    <Box sx={{ p: 4, backgroundColor: '#1C1C1C', color: '#C0C0C0' }}>
      <Typography variant="h4" gutterBottom sx={{ color: '#39FF14' }}>
        Scheduler Dashboard
      </Typography>

      {/* Scheduling Method */}
      <FormControl fullWidth sx={{ my: 3 }}>
        <InputLabel sx={{ color: '#C0C0C0' }}>Scheduling Method</InputLabel>
        <Select
          value={schedulingMethod}
          onChange={handleSchedulingMethodChange}
          sx={{ color: '#C0C0C0', backgroundColor: '#333' }}
        >
          <MenuItem value="bayOnly">Bay Only</MenuItem>
          <MenuItem value="bayAndEmployee">Bay and Employee Availability</MenuItem>
        </Select>
      </FormControl>

      {/* Toggle Business Hours Button */}
      <Button
        variant="contained"
        color="primary"
        sx={{ mb: 3 }}
        onClick={toggleBusinessHours}
        style={{ backgroundColor: '#39FF14', color: '#1C1C1C' }}
      >
        {showBusinessHours ? 'Collapse Business Hours' : 'Expand Business Hours'}
      </Button>

      {/* Business Hours */}
      {showBusinessHours && (
        <>
          <Typography variant="h6" sx={{ color: '#00FFFF' }}>Business Hours and Days Open</Typography>
          {Object.keys(businessHours).map((day) => (
            <Box key={day} sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
              <Typography sx={{ width: 100, color: '#C0C0C0' }}>{day}</Typography>
              <TextField
                label="Opening Time"
                type="time"
                value={businessHours[day].open}
                onChange={(e) => handleBusinessHoursChange(day, 'open', e.target.value)}
                sx={{ color: '#C0C0C0', backgroundColor: '#333' }}
                InputLabelProps={{ style: { color: '#C0C0C0' } }}
              />
              <TextField
                label="Closing Time"
                type="time"
                value={businessHours[day].close}
                onChange={(e) => handleBusinessHoursChange(day, 'close', e.target.value)}
                sx={{ color: '#C0C0C0', backgroundColor: '#333' }}
                InputLabelProps={{ style: { color: '#C0C0C0' } }}
              />
              <Button
                variant="outlined"
                onClick={() => handleBusinessHoursChange(day, 'isClosed', !businessHours[day].isClosed)}
                sx={{
                  color: businessHours[day].isClosed ? '#FF6347' : '#39FF14',
                  borderColor: businessHours[day].isClosed ? '#FF6347' : '#39FF14',
                }}
              >
                {businessHours[day].isClosed ? 'Closed' : 'Open'}
              </Button>
            </Box>
          ))}
        </>
      )}

      {/* Calendar Component */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" sx={{ color: '#00FFFF' }}>Appointments Calendar</Typography>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500, margin: '20px 0', backgroundColor: '#333', color: '#C0C0C0' }}
          views={['month', 'week', 'day']}
          selectable
          onSelectEvent={handleSelectEvent}
          onSelectSlot={(slotInfo) => console.log('Selected slot:', slotInfo)}
          components={{
            month: {
              dateHeader: ({ date, label }) => {
                const count = events.filter((event) => moment(event.start).isSame(date, 'day')).length;
                return (
                  <div style={{ position: 'relative' }}>
                    {label}
                    {count > 0 && (
                      <span style={{
                        position: 'absolute',
                        bottom: 5,
                        left: 5,
                        backgroundColor: '#39FF14',
                        color: '#1C1C1C',
                        padding: '2px 4px',
                        borderRadius: '50%',
                        fontSize: '0.8rem',
                      }}>
                        {count}
                      </span>
                    )}
                  </div>
                );
              },
            },
          }}
          eventPropGetter={(event) => ({
            style: {
              backgroundColor: event.resource?.status === 'Cancelled' ? 'red' : '#39FF14',
              color: '#1C1C1C',
              borderRadius: '5px',
              border: 'none',
            },
          })}
        />
      </Box>

      {/* View Public Scheduler Button */}
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 4, mr: 2 }}
        onClick={handleViewPublicScheduler}
        style={{ backgroundColor: '#39FF14', color: '#1C1C1C' }}
      >
        View Public Scheduler
      </Button>

      {/* Save Settings Button */}
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 4 }}
        onClick={handleSaveSettings}
      >
        Save Settings
      </Button>

      {/* Manage Bays Button */}
      <Button
        variant="contained"
        sx={{ mt: 4, ml: 2 }}
        onClick={() => setBayManagementOpen(true)}
        style={{ backgroundColor: '#00FFFF', color: '#1C1C1C' }}
      >
        Manage Bays
      </Button>

      {/* Appointment Modal */}
      {selectedAppointment && (
        <AppointmentCard
          appointment={selectedAppointment}
          open={appointmentModalOpen}
          handleClose={handleCloseAppointmentModal}
          refreshAppointments={fetchAppointments}
        />
      )}

      {/* Bay Management Modal */}
      {bayManagementOpen && (
        <BayManagement
          open={bayManagementOpen}
          onClose={() => setBayManagementOpen(false)}
        />
      )}
    </Box>
  );
}

export default SchedulerDashboard;
