import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axiosCustomerInstance from '../../utils/axiosCustomer';
import axiosVehicleInstance from '../../utils/axiosVehicle'; // Assuming a separate axios instance for VehicleService
import VehicleSearchMini from '../VehicleSearch/VehicleSearch';
import CustomerAppointmentHistory from './CustomerAppointmentHistory';
import InvoiceList from '../Invoice/InvoiceList';
import { Box, Typography, Button, IconButton, Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

function CustomerDetails() {
  const { id } = useParams(); // `id` is the customer ID
  const [customer, setCustomer] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const response = await axiosCustomerInstance.get(`/customers/${id}`);
        const customerData = response.data;

        if (customerData.vehicles && customerData.vehicles.length > 0) {
          const vehicleDetails = await Promise.all(
            customerData.vehicles.map(async (vehicleId) => {
              try {
                const vehicleResponse = await axiosVehicleInstance.get(`/vehicles/${vehicleId}`);
                return vehicleResponse.data;
              } catch (vehicleError) {
                console.error('Error fetching vehicle:', vehicleError.message);
                return null;
              }
            })
          );

          customerData.vehicles = vehicleDetails.filter((vehicle) => vehicle !== null);
        }

        setCustomer(customerData);
      } catch (error) {
        console.error('Error fetching customer:', error.message);
      }
    };

    fetchCustomer();
  }, [id]);

  const addVehicleToCustomer = async (vehicle) => {
    try {
      const response = await axiosVehicleInstance.post(`/vehicles/${id}/vehicles`, vehicle);
      setCustomer((prevState) => ({
        ...prevState,
        vehicles: [...prevState.vehicles, response.data],
      }));
    } catch (error) {
      console.error('Error adding vehicle:', error.message);
    }
  };

  const deleteVehicle = async (vehicleId) => {
    try {
      await axiosVehicleInstance.delete(`/vehicles/${id}/vehicles/${vehicleId}`);
      setCustomer((prevState) => ({
        ...prevState,
        vehicles: prevState.vehicles.filter((vehicle) => vehicle._id !== vehicleId),
      }));
    } catch (error) {
      console.error('Error deleting vehicle:', error.message);
    }
  };

  const handleDeleteCustomer = async () => {
    try {
      await axiosCustomerInstance.delete(`/customers/${id}`);
      navigate('/customers');
    } catch (error) {
      console.error('Error deleting customer:', error.message);
    }
  };

  if (!customer) {
    return <Typography color="#00FFFF">Loading...</Typography>;
  }

  return (
    <Box sx={{ display: 'flex', gap: 3, p: 3 }}>
      {/* Left Side: Customer Info and Vehicles */}
      <Box sx={{ flex: 1, bgcolor: '#1C1C1C', color: '#C0C0C0', p: 3, borderRadius: 2 }}>
        <Typography variant="h4" gutterBottom sx={{ color: '#39FF14' }}>
          {customer.firstName} {customer.lastName}
        </Typography>

        <Box mb={2}>
          <Typography variant="body1" sx={{ color: '#C0C0C0' }}>
            Email: {customer.email}
          </Typography>
          <Typography variant="body1" sx={{ color: '#C0C0C0' }}>
            Phone: {customer.phone}
          </Typography>
          <Typography variant="body1" sx={{ color: '#C0C0C0' }}>
            Address: {customer.address}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
          <Button
            component={Link}
            to={`/customers/${id}/edit`}
            startIcon={<EditIcon />}
            sx={{
              bgcolor: '#00FFFF',
              color: '#000',
              '&:hover': { bgcolor: '#39FF14', color: '#000' },
            }}
          >
            Edit
          </Button>
          <Button
            onClick={handleDeleteCustomer}
            startIcon={<DeleteIcon />}
            sx={{
              bgcolor: '#FF0000',
              color: '#FFF',
              '&:hover': { bgcolor: '#FF6347' },
            }}
          >
            Delete
          </Button>
          <Button
            component={Link}
            to="/customers"
            sx={{
              bgcolor: '#2C2C2C',
              color: '#C0C0C0',
              '&:hover': { bgcolor: '#00FFFF', color: '#000' },
            }}
          >
            Back to Customers
          </Button>
        </Box>

        <Typography variant="h5" gutterBottom sx={{ color: '#00FFFF', mt: 3 }}>
          Add Vehicle to Customer
        </Typography>
        <VehicleSearchMini onVehicleSelect={addVehicleToCustomer} />

        <Typography variant="h5" gutterBottom sx={{ color: '#00FFFF', mt: 4 }}>
          Customer Vehicles
        </Typography>

        {customer.vehicles && customer.vehicles.length > 0 ? (
          <Box
            className="customer-vehicles-list"
            sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 2 }}
          >
            {customer.vehicles.map((vehicle, index) => (
              <Paper
                key={index}
                elevation={3}
                sx={{
                  width: '200px',
                  p: 2,
                  bgcolor: '#2C2C2C',
                  color: '#C0C0C0',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  borderRadius: 1,
                }}
              >
                <Typography variant="body1" sx={{ color: '#00FFFF' }}>
                  {vehicle.year} {vehicle.make} {vehicle.model} {vehicle.trim}
                </Typography>
                <img src={vehicle.thumbnail} alt="Vehicle thumbnail" style={{ width: '100%' }} />
                <Typography variant="body2" sx={{ color: '#C0C0C0', mt: 1 }}>
                  Color: {vehicle.color}
                </Typography>
                <IconButton
                  onClick={() => deleteVehicle(vehicle._id)}
                  sx={{
                    mt: 1,
                    color: '#FF0000',
                    '&:hover': { color: '#FF6347' },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Paper>
            ))}
          </Box>
        ) : (
          <Typography variant="body1" sx={{ color: '#C0C0C0', mt: 2 }}>
            No vehicles added.
          </Typography>
        )}
      </Box>

      {/* Right Side: Appointment History and Invoice List */}
      <Box sx={{ flex: 1, bgcolor: '#1C1C1C', color: '#C0C0C0', p: 3, borderRadius: 2 }}>
        <Typography variant="h4" gutterBottom sx={{ color: '#39FF14' }}>
          Appointment History
        </Typography>
        <CustomerAppointmentHistory customerId={id} />

        <Typography variant="h4" gutterBottom sx={{ color: '#39FF14', mt: 4 }}>
          Invoices
        </Typography>
        <InvoiceList customerId={id} />
      </Box>
    </Box>
  );
}

export default CustomerDetails;
