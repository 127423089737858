// ServiceSettings.jsx
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../utils/axios';
import WizardSetup from './WizardSetup';

function ServiceSettings() {
  const { serviceId } = useParams();
  const isNew = !serviceId || serviceId === 'new'; // Updated condition
  const [service, setService] = useState(isNew ? {} : null);

  useEffect(() => {
    if (!isNew) {
      const fetchService = async () => {
        try {
          const response = await axiosInstance.get(`/services/${serviceId}`);
          setService(response.data);
        } catch (error) {
// $&

        }
      };
      fetchService();
    }
  }, [serviceId, isNew]);

  return (
    <div>
      <h1>{isNew ? 'Create a New Service' : `Service Settings for ${service?.name}`}</h1>
      {isNew || service ? (
        <WizardSetup service={service} isNew={isNew} />
      ) : (
        <div>Loading service details...</div>
      )}
    </div>
  );
}

export default ServiceSettings;
