import React, { useState, useEffect } from 'react';
import axiosAppointmentInstance from '../../utils/axiosAppointment';
import { Box, Typography, Paper, Collapse } from '@mui/material';
import AppointmentCard from '../AppointmentCard/AppointmentCard'; 

const CustomerAppointmentHistory = ({ customerId }) => {
    const [appointments, setAppointments] = useState({
        upcoming: [],
        current: [],
        past: []
    });
    const [expandedAppointmentId, setExpandedAppointmentId] = useState(null);
    const [showUpcoming, setShowUpcoming] = useState(true);
    const [showCurrent, setShowCurrent] = useState(false);
    const [showPast, setShowPast] = useState(false);

    const fetchAppointments = async () => {
        try {
            const response = await axiosAppointmentInstance.get(`/appointments/customer/${customerId}`);
            setAppointments({
                upcoming: response.data.upcomingAppointments,
                current: response.data.currentAppointments,
                past: response.data.pastAppointments
            });
        } catch (error) {
// $&

        }
    };

    useEffect(() => {
        fetchAppointments();
    }, [customerId]);

    const toggleAppointmentExpansion = (appointmentId) => {
        setExpandedAppointmentId((prev) => (prev === appointmentId ? null : appointmentId));
    };

    const renderAppointment = (appointment) => (
        <Box key={appointment._id} sx={{ mb: 2 }}>
            {/* Collapsed view with basic info */}
            <Paper
                onClick={() => toggleAppointmentExpansion(appointment._id)}
                sx={{
                    bgcolor: '#2C2C2C',
                    color: '#C0C0C0',
                    p: 2,
                    borderRadius: 1,
                    cursor: 'pointer'
                }}
            >
                <Typography variant="h6" sx={{ color: '#00FFFF' }}>
                    {new Date(appointment.startTime).toLocaleDateString()} - {appointment.services[0]?.service?.name || 'Service'}
                </Typography>
                <Typography variant="body2" sx={{ color: '#39FF14' }}>
                    Time: {new Date(appointment.startTime).toLocaleTimeString()} - {new Date(appointment.endTime).toLocaleTimeString()}
                </Typography>
                <Typography variant="body2">Sub-Service: {appointment.services[0]?.subService?.name || 'Sub-Service'}</Typography>
            </Paper>

            {/* Expanded view with full details */}
            <Collapse in={expandedAppointmentId === appointment._id} timeout="auto" unmountOnExit>
                <AppointmentCard
                    appointment={appointment}
                    open={expandedAppointmentId === appointment._id}
                    handleClose={() => setExpandedAppointmentId(null)}
                    refreshAppointments={fetchAppointments} // Use fetchAppointments to refresh data
                />
            </Collapse>
        </Box>
    );

    return (
        <Box sx={{ bgcolor: '#1C1C1C', p: 3, borderRadius: 2 }}>
            {/* Upcoming Appointments */}
            <Typography
                variant="h5"
                sx={{ color: '#39FF14', cursor: 'pointer', mt: 2 }}
                onClick={() => setShowUpcoming(!showUpcoming)}
            >
                Upcoming Appointments
            </Typography>
            {showUpcoming && (
                <Box sx={{ mt: 1 }}>
                    {appointments.upcoming.length ? appointments.upcoming.map(renderAppointment) :
                        <Typography sx={{ color: '#C0C0C0' }}>No upcoming appointments.</Typography>}
                </Box>
            )}

            {/* Current Appointments */}
            <Typography
                variant="h5"
                sx={{ color: '#39FF14', cursor: 'pointer', mt: 2 }}
                onClick={() => setShowCurrent(!showCurrent)}
            >
                Current Appointments
            </Typography>
            {showCurrent && (
                <Box sx={{ mt: 1 }}>
                    {appointments.current.length ? appointments.current.map(renderAppointment) :
                        <Typography sx={{ color: '#C0C0C0' }}>No current appointments.</Typography>}
                </Box>
            )}

            {/* Past Appointments */}
            <Typography
                variant="h5"
                sx={{ color: '#39FF14', cursor: 'pointer', mt: 2 }}
                onClick={() => setShowPast(!showPast)}
            >
                Past Appointments
            </Typography>
            {showPast && (
                <Box sx={{ mt: 1 }}>
                    {appointments.past.length ? appointments.past.map(renderAppointment) :
                        <Typography sx={{ color: '#C0C0C0' }}>No past appointments.</Typography>}
                </Box>
            )}
        </Box>
    );
};

export default CustomerAppointmentHistory;
