import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';

const AdminSubServiceList = ({ subServices, onSubServiceClick, onDeleteSubService, isLoading }) => {
  if (isLoading) {
    return <CircularProgress />;
  }

  if (!subServices.length) {
    return <Typography>No sub-services available. Please add a new sub-service.</Typography>;
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Install Time</TableCell>
          <TableCell>Cure Time</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {subServices.map((subService) => (
          <TableRow key={subService._id}>
            <TableCell>{subService.name}</TableCell>
            <TableCell>{subService.installTime} Minutes</TableCell>
            <TableCell>{subService.cureTime} Minutes</TableCell>
            <TableCell>
              <Button onClick={() => onSubServiceClick(subService)} variant="outlined">
                Edit
              </Button>
              <Button
                onClick={() => onDeleteSubService(subService._id)}
                variant="outlined"
                color="error"
                sx={{ marginLeft: 1 }}
              >
                Delete
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default AdminSubServiceList;
