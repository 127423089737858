import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axios';
import {
  TextField,
  Button,
  Paper,
  Typography,
  Box,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Collapse,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import BrandCard from '../Brands/BrandCard';

function ServiceDetails({ selectedService, onSave, onCancel }) {
  const [serviceData, setServiceData] = useState({
    name: '',
    description: '',
  });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [brands, setBrands] = useState([]);
  const [isBrandsExpanded, setIsBrandsExpanded] = useState(false);

  useEffect(() => {
    if (selectedService) {
      setServiceData({
        name: selectedService.name,
        description: selectedService.description || '',
      });
      fetchBrands();
    }
  }, [selectedService]);

  const fetchBrands = async () => {
    try {
      const response = await axiosInstance.get('/brands');
      if (response.data) {
        const serviceBrands = selectedService.brands || [];
        const updatedBrands = response.data.map((brand) => ({
          ...brand,
          active: serviceBrands.includes(brand._id),
        }));
        setBrands(updatedBrands);
      }
    } catch (error) {
// $&

    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setServiceData({ ...serviceData, [name]: value });
  };

  const handleSaveService = async () => {
    try {
      const updatedServiceData = {
        ...serviceData,
        brands: brands.filter((brand) => brand.active).map((brand) => brand._id),
      };
      const response = await axiosInstance.put(`/services/${selectedService._id}`, updatedServiceData);
      onSave(response.data);
    } catch (error) {
// $&

    }
  };

  const handleDeleteService = async () => {
    try {
      await axiosInstance.delete(`/services/${selectedService._id}`);
      onCancel();
    } catch (error) {
// $&

    }
  };

  const handleOpenDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleToggleBrand = (brandId) => {
    setBrands((prevBrands) =>
      prevBrands.map((brand) =>
        brand._id === brandId ? { ...brand, active: !brand.active } : brand
      )
    );
  };

  const toggleBrandsSection = () => {
    setIsBrandsExpanded((prev) => !prev);
  };

  return (
    <Paper elevation={3} sx={{ padding: 3, bgcolor: '#1C1C1C', color: '#C0C0C0', marginBottom: 4 }}>
      <Typography variant="h4" gutterBottom sx={{ color: '#39FF14' }}>
        Service Details
      </Typography>

      <Box mb={2}>
        <TextField
          label="Service Name"
          name="name"
          value={serviceData.name}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          InputLabelProps={{ style: { color: '#C0C0C0' } }}
          InputProps={{ style: { color: '#00FFFF' } }}
        />
        <TextField
          label="Service Description"
          name="description"
          value={serviceData.description}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          multiline
          rows={4}
          InputLabelProps={{ style: { color: '#C0C0C0' } }}
          InputProps={{ style: { color: '#00FFFF' } }}
        />
      </Box>

      <Box display="flex" alignItems="center" justifyContent="space-between" mt={4}>
        <Typography variant="h5" sx={{ color: '#00FFFF' }}>
          Associated Brands
        </Typography>
        <IconButton onClick={toggleBrandsSection} color="inherit">
          {isBrandsExpanded ? <ExpandLessIcon sx={{ color: '#00FFFF' }} /> : <ExpandMoreIcon sx={{ color: '#00FFFF' }} />}
        </IconButton>
      </Box>

      <Collapse in={isBrandsExpanded}>
        <Box sx={{ mt: 2 }}>
          {brands.map((brand) => (
            <BrandCard key={brand._id} brand={brand} onToggle={handleToggleBrand} />
          ))}
        </Box>
      </Collapse>

      <Box display="flex" justifyContent="space-between" mt={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveService}
          startIcon={<SaveIcon />}
          sx={{ bgcolor: '#39FF14', color: '#000000', '&:hover': { bgcolor: '#00FFFF' } }}
        >
          Save
        </Button>
        <IconButton onClick={onCancel} color="inherit" sx={{ color: '#FF0000' }}>
          <CancelIcon />
        </IconButton>
        <IconButton onClick={handleOpenDeleteDialog} color="inherit" sx={{ color: '#FF0000' }}>
          <DeleteIcon />
        </IconButton>
      </Box>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Delete Service</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this service? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="secondary" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDeleteService();
              setDeleteDialogOpen(false);
            }}
            color="primary"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

export default ServiceDetails;
