import React, { useState, useEffect } from 'react';
import axios from '../../utils/axiosInvoice'; // Handles authentication and headers
import { useNavigate, useParams } from 'react-router-dom';
import './SalesTax.css';

const SalesTaxForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    stateRate: 0,
    countyRate: 0,
    combinedRate: 0,
    type: 'StateOnly', // Default type
  });

  useEffect(() => {
    if (id) {
      fetchSalesTax(id);
    }
  }, [id]);

  const fetchSalesTax = async (taxId) => {
    try {
      const response = await axios.get(`/sales-taxes/${taxId}`);
      setFormData(response.data);
    } catch (error) {
// $&

      alert('Failed to load sales tax data. Please try again.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => {
      const updatedData = { ...prev, [name]: value };

      // Automatically calculate the combined rate for "Combined" type
      if (name === 'stateRate' || name === 'countyRate') {
        if (prev.type === 'Combined') {
          updatedData.combinedRate = 
            parseFloat(updatedData.stateRate || 0) + parseFloat(updatedData.countyRate || 0);
        }
      }

      return updatedData;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (id) {
        await axios.put(`/sales-taxes/${id}`, formData); // Update existing sales tax
      } else {
        await axios.post('/sales-taxes', formData); // Create a new sales tax
      }
      navigate('/settings/tax-rates'); // Redirect to the tax rates list page
    } catch (error) {
// $&

      alert('Failed to save the tax rate. Please check your input and try again.');
    }
  };

  return (
    <div className="sales-tax-form">
      <h2>{id ? 'Edit Sales Tax Rate' : 'Create New Sales Tax Rate'}</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Tax Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Type</label>
          <select name="type" value={formData.type} onChange={handleChange}>
            <option value="StateOnly">State Only</option>
            <option value="CountyOnly">County Only</option>
            <option value="Combined">Combined (State + County)</option>
          </select>
        </div>
        {formData.type === 'StateOnly' && (
          <div className="form-group">
            <label>State Rate (%)</label>
            <input
              type="number"
              name="stateRate"
              value={formData.stateRate}
              onChange={handleChange}
              min="0"
              step="0.001"
            />
          </div>
        )}
        {formData.type === 'CountyOnly' && (
          <div className="form-group">
            <label>County Rate (%)</label>
            <input
              type="number"
              name="countyRate"
              value={formData.countyRate}
              onChange={handleChange}
              min="0"
              step="0.001"
            />
          </div>
        )}
        {formData.type === 'Combined' && (
          <>
            <div className="form-group">
              <label>State Rate (%)</label>
              <input
                type="number"
                name="stateRate"
                value={formData.stateRate}
                onChange={handleChange}
                min="0"
                step="0.001"
              />
            </div>
            <div className="form-group">
              <label>County Rate (%)</label>
              <input
                type="number"
                name="countyRate"
                value={formData.countyRate}
                onChange={handleChange}
                min="0"
                step="0.001"
              />
            </div>
            <div className="form-group">
              <label>Combined Rate (%)</label>
              <input
                type="number"
                name="combinedRate"
                value={formData.combinedRate}
                onChange={handleChange}
                min="0"
                step="0.001"
                disabled // Combined rate is calculated automatically
              />
            </div>
          </>
        )}
        <button type="submit" className="save-button">
          {id ? 'Update Tax Rate' : 'Create Tax Rate'}
        </button>
      </form>
    </div>
  );
};

export default SalesTaxForm;
