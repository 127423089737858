import React from 'react';
import { useNavigate } from 'react-router-dom';
import axiosCustomerInstance from '../../utils/axiosCustomer';
import CustomerForm from './CustomerForm';

function AddCustomer() {
  const navigate = useNavigate();

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
  };

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      await axiosCustomerInstance.post('/customers', values);
      navigate('/customers');
    } catch (error) {
      console.error('Error adding customer:', error.message);
      setErrors({ submit: error.response?.data?.message || 'An error occurred' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <h1>Add Customer</h1>
      <CustomerForm initialValues={initialValues} onSubmit={handleSubmit} />
    </div>
  );
}

export default AddCustomer;
