import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Breadcrumbs, Link } from '@mui/material';

function CustomerInformationEntry({ onBack, onComplete, ownerId }) {
  const [customerInfo, setCustomerInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    notes: '',
  });

  const handleChange = (field) => (event) => {
    setCustomerInfo({ ...customerInfo, [field]: event.target.value });
  };

  const handleNext = () => {
    // Add validation if needed
// $&

    onComplete({ ...customerInfo, ownerId }); // Include ownerId when calling onComplete
  };

  return (
    <Box sx={{ p: 4, backgroundColor: '#1C1C1C', color: '#C0C0C0' }}>
      {/* Breadcrumb or Back Button */}
      <Breadcrumbs aria-label="breadcrumb" sx={{ color: '#00FFFF', mb: 3 }}>
        <Link color="inherit" onClick={onBack} sx={{ cursor: 'pointer', color: '#00FFFF' }}>
          Back to Date & Time Selection
        </Link>
        <Typography color="textPrimary">Step 3: Customer Information Entry</Typography>
      </Breadcrumbs>

      <Typography variant="h4" gutterBottom sx={{ color: '#39FF14' }}>
        Customer Information Entry
      </Typography>

      <TextField
        label="First Name"
        value={customerInfo.firstName}
        onChange={handleChange('firstName')}
        fullWidth
        margin="normal"
        sx={{ color: '#C0C0C0', backgroundColor: '#333' }}
        InputLabelProps={{ style: { color: '#C0C0C0' } }}
      />
      <TextField
        label="Last Name"
        value={customerInfo.lastName}
        onChange={handleChange('lastName')}
        fullWidth
        margin="normal"
        sx={{ color: '#C0C0C0', backgroundColor: '#333' }}
        InputLabelProps={{ style: { color: '#C0C0C0' } }}
      />
      <TextField
        label="Email"
        type="email"
        value={customerInfo.email}
        onChange={handleChange('email')}
        fullWidth
        margin="normal"
        sx={{ color: '#C0C0C0', backgroundColor: '#333' }}
        InputLabelProps={{ style: { color: '#C0C0C0' } }}
      />
      <TextField
        label="Phone"
        type="tel"
        value={customerInfo.phone}
        onChange={handleChange('phone')}
        fullWidth
        margin="normal"
        sx={{ color: '#C0C0C0', backgroundColor: '#333' }}
        InputLabelProps={{ style: { color: '#C0C0C0' } }}
      />
      <TextField
        label="Address"
        value={customerInfo.address}
        onChange={handleChange('address')}
        fullWidth
        margin="normal"
        sx={{ color: '#C0C0C0', backgroundColor: '#333' }}
        InputLabelProps={{ style: { color: '#C0C0C0' } }}
      />
      <TextField
        label="Additional Notes"
        value={customerInfo.notes}
        onChange={handleChange('notes')}
        multiline
        rows={4}
        fullWidth
        margin="normal"
        sx={{ color: '#C0C0C0', backgroundColor: '#333' }}
        InputLabelProps={{ style: { color: '#C0C0C0' } }}
      />

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
        <Button variant="contained" color="secondary" onClick={onBack}>
          Back
        </Button>
        <Button variant="contained" color="primary" onClick={handleNext}>
          Next
        </Button>
      </Box>
    </Box>
  );
}

export default CustomerInformationEntry;
