import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

function VehicleList() {
  const [vehicles, setVehicles] = useState([]);
  const [customers, setCustomers] = useState({});
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const response = await axios.get('http://localhost:5002/api/vehicles', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setVehicles(response.data);

        // Fetch customer details for each vehicle
        const customerIds = [...new Set(response.data.map(v => v.customer))];
        const customerResponses = await Promise.all(
          customerIds.map(id =>
            axios.get(`http://localhost:5001/api/customers/${id}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
          )
        );
        const customerData = {};
        customerResponses.forEach(res => {
          customerData[res.data._id] = res.data;
        });
        setCustomers(customerData);
      } catch (error) {
// $&

      }
    };

    fetchVehicles();
  }, [token]);

  return (
    <div>
      <h1>Vehicles</h1>
      <Link to="/vehicles/add">Add New Vehicle</Link>
      {vehicles.length === 0 ? (
        <p>No vehicles found.</p>
      ) : (
        <ul>
          {vehicles.map(vehicle => (
            <li key={vehicle._id}>
              {vehicle.make} {vehicle.model} ({vehicle.year}) - Owner:{' '}
              {customers[vehicle.customer]?.firstName} {customers[vehicle.customer]?.lastName}
              <Link to={`/vehicles/${vehicle._id}`}>View</Link>
              <Link to={`/vehicles/${vehicle._id}/edit`}>Edit</Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default VehicleList;
