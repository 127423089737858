// PricingManagement.jsx

import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../../utils/axios';
import {
  Typography,
  Box,
  Button,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import { Add, Delete, Save, Cancel } from '@mui/icons-material';

function PricingManagement({ brandId, subBrand }) {
  const [pricingStrategies, setPricingStrategies] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [newPricing, setNewPricing] = useState({
    pricingStrategy: '',
    basePrice: '',
    pricingDetails: {},
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [pricingToDelete, setPricingToDelete] = useState(null);

  const fetchPricingStrategies = useCallback(async () => {
    if (subBrand && brandId) {
      try {
        const response = await axiosInstance.get(
          `/brands/${brandId}/sub-brands/${subBrand._id}/pricing`
        );
        setPricingStrategies(response.data);
      } catch (error) {
// $&

      }
    }
  }, [brandId, subBrand]);

  useEffect(() => {
    fetchPricingStrategies();
  }, [fetchPricingStrategies]);

  const handleAddPricing = async () => {
    const { pricingStrategy, basePrice } = newPricing;
    if (!pricingStrategy || !basePrice) return;
    try {
      const response = await axiosInstance.post(
        `/brands/${brandId}/sub-brands/${subBrand._id}/pricing`,
        newPricing
      );
      setPricingStrategies([...pricingStrategies, response.data]);
      setNewPricing({ pricingStrategy: '', basePrice: '', pricingDetails: {} });
      setIsAdding(false);
    } catch (error) {
// $&

    }
  };

  const handleOpenDialog = (pricing) => {
    setPricingToDelete(pricing);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setPricingToDelete(null);
  };

  const confirmDeletePricing = async () => {
    if (pricingToDelete) {
      try {
        await axiosInstance.delete(
          `/brands/${brandId}/sub-brands/${subBrand._id}/pricing/${pricingToDelete._id}`
        );
        setPricingStrategies(pricingStrategies.filter((ps) => ps._id !== pricingToDelete._id));
        handleCloseDialog();
      } catch (error) {
// $&

        handleCloseDialog();
      }
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" gutterBottom>
        Pricing Strategies for Sub-Brand: {subBrand.name}
      </Typography>

      {isAdding ? (
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Add New Pricing Strategy
          </Typography>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="pricing-strategy-label">Pricing Strategy</InputLabel>
            <Select
              labelId="pricing-strategy-label"
              value={newPricing.pricingStrategy}
              label="Pricing Strategy"
              onChange={(e) => setNewPricing({ ...newPricing, pricingStrategy: e.target.value })}
            >
              <MenuItem value="BySquareFootage">By Square Footage</MenuItem>
              <MenuItem value="ByPanel">By Panel</MenuItem>
              <MenuItem value="ByPackage">By Package</MenuItem>
            </Select>
          </FormControl>

          <TextField
            label="Base Price"
            variant="outlined"
            type="number"
            value={newPricing.basePrice}
            onChange={(e) => setNewPricing({ ...newPricing, basePrice: e.target.value })}
            fullWidth
            sx={{ mb: 2 }}
          />
          {/* Add additional fields for pricingDetails, upcharges, etc. */}

          <Box display="flex" gap={2}>
            <Button variant="contained" color="primary" onClick={handleAddPricing} startIcon={<Save />}>
              Save
            </Button>
            <Button variant="outlined" color="secondary" onClick={() => setIsAdding(false)} startIcon={<Cancel />}>
              Cancel
            </Button>
          </Box>
        </Box>
      ) : (
        <Button variant="contained" color="primary" onClick={() => setIsAdding(true)} startIcon={<Add />} sx={{ mb: 2 }}>
          Add Pricing Strategy
        </Button>
      )}

      <Typography variant="subtitle1" gutterBottom>
        Existing Pricing Strategies
      </Typography>
      {pricingStrategies.length > 0 ? (
        <List>
          {pricingStrategies.map((ps) => (
            <ListItem key={ps._id} sx={{ bgcolor: 'background.paper', mb: 1, borderRadius: 1 }}>
              <ListItemText
                primary={`Strategy: ${ps.pricingStrategy}`}
                secondary={`Base Price: $${ps.basePrice}`}
              />
              <ListItemSecondaryAction>
                <IconButton onClick={() => handleOpenDialog(ps)} color="secondary">
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography variant="body1">No pricing strategies set up for this sub-brand.</Typography>
      )}

      {/* Confirmation Dialog for Deleting Pricing Strategy */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="delete-pricing-dialog-title"
        aria-describedby="delete-pricing-dialog-description"
      >
        <DialogTitle id="delete-pricing-dialog-title">Delete Pricing Strategy</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-pricing-dialog-description">
            Are you sure you want to delete the pricing strategy for "{pricingToDelete?.pricingStrategy}"? This action
            cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={confirmDeletePricing} color="primary" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default PricingManagement;
