// components/Brands/BrandCard.jsx
import React from 'react';
import { Box, Typography, Switch } from '@mui/material';

function BrandCard({ brand, onToggle }) {
  return (
    <Box
      sx={{
        bgcolor: '#2C2C2C',
        p: 2,
        borderRadius: 2,
        mb: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Typography sx={{ color: '#C0C0C0' }}>{brand.name}</Typography>
      <Switch
        checked={brand.active}
        onChange={() => onToggle(brand._id)}
        color="primary"
      />
    </Box>
  );
}

export default BrandCard;
