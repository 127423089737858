import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../utils/axios';
import AdminSubBrandList from './AdminSubBrandList';
import AdminSubBrandDetails from './AdminSubBrandDetails';
import { Box, Typography, Button, CircularProgress } from '@mui/material';

const AdminSubBrandManagement = () => {
  const [subBrands, setSubBrands] = useState([]);
  const [selectedSubBrand, setSelectedSubBrand] = useState(null);
  const [brands, setBrands] = useState([]); // Available brands for selection
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchSubBrands();
    fetchBrands();
  }, []);

  const fetchSubBrands = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get('/admin/sub-brands');
      setSubBrands(response.data);
    } catch (err) {
// $&

      setError('Failed to fetch sub-brands.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchBrands = async () => {
    try {
      const response = await axiosInstance.get('/admin/brands');
      setBrands(response.data);
    } catch (err) {
// $&

      setError('Failed to fetch brands.');
    }
  };

  const handleCreateSubBrand = async (newSubBrand) => {
    try {
      const response = await axiosInstance.post('/admin/sub-brands', newSubBrand);
      setSubBrands([...subBrands, response.data]);
      setSelectedSubBrand(null);
    } catch (err) {
// $&

      setError('Failed to create sub-brand.');
    }
  };

  const handleUpdateSubBrand = async (updatedSubBrand) => {
    try {
      const response = await axiosInstance.put(
        `/admin/sub-brands/${updatedSubBrand._id}`,
        updatedSubBrand
      );
      setSubBrands(
        subBrands.map((b) =>
          b._id === updatedSubBrand._id ? response.data : b
        )
      );
      setSelectedSubBrand(null);
    } catch (err) {
// $&

      setError('Failed to update sub-brand.');
    }
  };

  const handleSaveSubBrand = (subBrand) => {
    if (subBrand._id) {
      handleUpdateSubBrand(subBrand);
    } else {
      handleCreateSubBrand(subBrand);
    }
  };

  const handleDeleteSubBrand = async (subBrandId) => {
    try {
      const response = await axiosInstance.delete(`/admin/sub-brands/${subBrandId}`);
      if (response.status === 200) {
        setSubBrands(subBrands.filter((b) => b._id !== subBrandId));
      }
    } catch (err) {
// $&

      setError(err.response?.data?.message || 'Failed to delete sub-brand.');
    }
  };
  

  if (isLoading) return <CircularProgress />;

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Admin: Manage Sub-Brands
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      <Button
        variant="contained"
        color="primary"
        onClick={() => setSelectedSubBrand({})}
        sx={{ marginBottom: 2 }}
      >
        Add New Sub-Brand
      </Button>
      <AdminSubBrandList
        subBrands={subBrands}
        onSubBrandClick={(subBrand) => setSelectedSubBrand(subBrand)}
        onDeleteSubBrand={handleDeleteSubBrand}
      />
      {selectedSubBrand && (
        <AdminSubBrandDetails
          subBrand={selectedSubBrand}
          brands={brands} // Pass the list of brands
          onSave={handleSaveSubBrand}
          onCancel={() => setSelectedSubBrand(null)}
        />
      )}
    </Box>
  );
};

export default AdminSubBrandManagement;
