// Step4.jsx

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../../utils/axios';
import { Paper, Typography, Box, Button, List, ListItem } from '@mui/material';

function Step4({ serviceData, prevStep }) {
  const navigate = useNavigate();
  const [subBrands, setSubBrands] = useState([]);

  useEffect(() => {
    if (serviceData.subBrands?.length > 0) {
      const fetchSubBrands = async () => {
        try {
          const response = await axiosInstance.post('/sub-brands/multiple', {
            subBrandIds: serviceData.subBrands,
          });
          setSubBrands(response.data);
        } catch (error) {
// $&

        }
      };
      fetchSubBrands();
    }
  }, [serviceData.subBrands]);

  const handleFinish = () => {
    navigate('/services');
  };

  return (
    <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Step 4: Review and Confirm
      </Typography>
      <Typography variant="h6" gutterBottom>
        Service Details:
      </Typography>
      <Typography>
        <strong>Name:</strong> {serviceData.name}
      </Typography>
      <Typography>
        <strong>Description:</strong> {serviceData.description}
      </Typography>

      <Typography variant="h6" sx={{ mt: 3 }}>
        Associated Brands and Sub-Brands:
      </Typography>
      {subBrands.length > 0 ? (
        <List>
          {subBrands.map((subBrand) => (
            <ListItem key={subBrand._id}>
              <Typography>
                <strong>Brand:</strong> {subBrand.brand.name} - <strong>Sub-Brand:</strong>{' '}
                {subBrand.name}
              </Typography>
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography>No sub-brands associated with this service.</Typography>
      )}

      <Box sx={{ mt: 2 }}>
        <Button variant="outlined" color="secondary" onClick={prevStep} sx={{ mr: 2 }}>
          Back
        </Button>
        <Button variant="contained" color="primary" onClick={handleFinish}>
          Finish
        </Button>
      </Box>
    </Paper>
  );
}

export default Step4;
