import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../utils/axios';
import AdminSubServiceList from './AdminSubServiceList';
import AdminSubServiceDetails from './AdminSubServiceDetails';
import { Box, Typography, Button, MenuItem, Select, CircularProgress } from '@mui/material';

const AdminSubServiceManagement = () => {
  const [services, setServices] = useState([]);
  const [subServices, setSubServices] = useState([]);
  const [selectedService, setSelectedService] = useState('');
  const [selectedSubService, setSelectedSubService] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
      const response = await axiosInstance.get('/admin/services'); // Updated route for admin services
      setServices(response.data);
    } catch (err) {
// $&

      setError('Failed to fetch services.');
    }
  };

  const fetchSubServices = async (serviceId) => {
// $&

    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/admin/sub-services/${serviceId}`);
      console.log('Fetched Sub-Services:', response.data); // Log response
      setSubServices(response.data);
    } catch (err) {
// $&

      setError('Failed to fetch sub-services.');
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleServiceChange = (event) => {
    const serviceId = event.target.value;
    setSelectedService(serviceId);
    fetchSubServices(serviceId);
  };

  const handleSubServiceCreate = async (newSubService) => {
    try {
// $&

      const payload = {
        ...newSubService,
        installTime: newSubService.installTimeHours * 60 + parseInt(newSubService.installTimeMinutes, 10),
        cureTime: newSubService.cureTimeHours * 60 + parseInt(newSubService.cureTimeMinutes, 10),
        service: selectedService, // Include the selected service ID
        isDefault: true, // Ensure this is set for default sub-services
      };
      console.log('Final Payload for Submission:', payload); // Log the final payload
      const response = await axiosInstance.post('/admin/sub-services/default', payload);
      setSubServices([...subServices, response.data]);
// $&

    } catch (err) {
// $&

      setError('Failed to create sub-service.');
    }
  };

  const handleSubServiceUpdate = async (updatedSubService) => {
    try {
// $&

      const response = await axiosInstance.put(`/admin/sub-services/default/${updatedSubService._id}`, {
        ...updatedSubService,
        installTime: updatedSubService.installTimeHours * 60 + updatedSubService.installTimeMinutes,
        cureTime: updatedSubService.cureTimeHours * 60 + updatedSubService.cureTimeMinutes,
      });
      setSubServices(
        subServices.map((subService) =>
          subService._id === updatedSubService._id ? response.data : subService
        )
      );
    } catch (err) {
// $&

      setError('Failed to update sub-service.');
    }
  };

  const handleSubServiceDelete = async (subServiceId) => {
    try {
      await axiosInstance.delete(`/admin/sub-services/default/${subServiceId}`);
      setSubServices(subServices.filter((subService) => subService._id !== subServiceId));
    } catch (err) {
// $&

      setError('Failed to delete sub-service.');
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Admin: Manage Sub-Services
      </Typography>
      {error && <Typography color="error">{error}</Typography>}

      <Box mb={2}>
        <Typography>Select a Service:</Typography>
        <Select
          value={selectedService}
          onChange={handleServiceChange}
          displayEmpty
          fullWidth
        >
          <MenuItem value="">Select Service</MenuItem>
          {services.map((service) => (
            <MenuItem key={service._id} value={service._id}>
              {service.name}
            </MenuItem>
          ))}
        </Select>
      </Box>

      {selectedService && (
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setSelectedSubService({})}
            sx={{ marginBottom: 2 }}
          >
            Add New Sub-Service
          </Button>

          <AdminSubServiceList
            subServices={subServices}
            onSubServiceClick={(subService) => setSelectedSubService(subService)}
            onDeleteSubService={handleSubServiceDelete}
          />

          {selectedSubService && (
            <AdminSubServiceDetails
              subService={selectedSubService}
              onSave={selectedSubService._id ? handleSubServiceUpdate : handleSubServiceCreate}
              onCancel={() => setSelectedSubService(null)}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default AdminSubServiceManagement;
