// components/PricingStrategySelection.jsx
import React from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

function PricingStrategySelection({ pricingStrategy, onChange }) {
  return (
    <Box sx={{ mt: 3 }}>
      <FormControl fullWidth>
        <InputLabel sx={{ color: '#C0C0C0' }}>Pricing Strategy</InputLabel>
        <Select
          value={pricingStrategy}
          onChange={onChange}
          sx={{ color: '#00FFFF', borderColor: '#C0C0C0' }}
        >
          <MenuItem value="BySquareFootage">By Square Footage</MenuItem>
          <MenuItem value="ByPanel">By Panel</MenuItem>
          <MenuItem value="ByPackage">By Package</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

export default PricingStrategySelection;
