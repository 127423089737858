import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, TextField, Button, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Save, Cancel, Delete } from '@mui/icons-material';
import axiosInstance from '../../../utils/axios';
import BrandCard from '../../Brands/BrandCard';
import SubBrandCard from '../../Brands/SubBrandCard';
import PricingStrategySelection from '../../Pricing/PricingStrategySelection';

function SubServiceDetails({ subServiceId, serviceId, onUpdateSubService, onDeleteSubService = () => {} }) {
  const [editSubServiceData, setEditSubServiceData] = useState({
    name: '',
    installTime: '',
    cureTime: '',
    pricingStrategy: '',
    subBrands: [],
    subBrandPricing: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [brands, setBrands] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const fetchSubServiceDetails = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.get(`/services/${serviceId}/sub-services/${subServiceId}`);
// $&

      setEditSubServiceData({
        name: response.data.name,
        installTime: response.data.installTime,
        cureTime: response.data.cureTime,
        pricingStrategy: response.data.pricingStrategy,
        subBrands: response.data.subBrands || [],
        subBrandPricing: response.data.subBrandPricing || [],
      });
    } catch (error) {
      setError('Error fetching sub-service details');
// $&

    } finally {
      setLoading(false);
    }
  }, [serviceId, subServiceId]);

  const fetchBrands = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`/services/${serviceId}/brands`);
      const brandsData = await Promise.all(
        response.data.map(async (brand) => {
          const subBrandsResponse = await axiosInstance.get(`/brands/${brand._id}/sub-brands`);
          return {
            ...brand,
            subBrands: subBrandsResponse.data,
          };
        })
      );
// $&

      setBrands(brandsData);
    } catch (error) {
// $&

    }
  }, [serviceId]);

  useEffect(() => {
    if (subServiceId && serviceId) {
      fetchSubServiceDetails();
      fetchBrands();
    }
  }, [subServiceId, serviceId, fetchSubServiceDetails, fetchBrands]);

  const handleSave = async () => {
    try {
      const subBrandPricingArray = brands.flatMap((brand) =>
        brand.subBrands.map((subBrand) => {
          const matchingPricing = editSubServiceData.subBrandPricing.find(
            (pricing) => pricing.subBrand === subBrand._id
          );
          return {
            subBrand: subBrand._id,
            price: matchingPricing ? matchingPricing.price : subBrand.price || 0,
            brand: brand._id,
            active: matchingPricing ? matchingPricing.active : subBrand.active || false,
          };
        })
      );

      const payload = {
        ...editSubServiceData,
        subBrandPricing: subBrandPricingArray,
      };

// $&


      const response = await axiosInstance.put(
        `/services/${serviceId}/sub-services/${subServiceId}`,
        payload
      );
      onUpdateSubService(response.data);
    } catch (error) {
// $&

    }
  };

  const handleToggleBrand = async (brandId) => {
    try {
      const updatedBrand = brands.find((brand) => brand._id === brandId);
      if (!updatedBrand) {
// $&

        return;
      }
      const newActiveStatus = !updatedBrand.active;
  
      await axiosInstance.put(`/brands/${brandId}/toggle-active`, {
        active: newActiveStatus,
      });
  
      // Update the local brands and subBrandPricing states
      setBrands((prevBrands) =>
        prevBrands.map((brand) =>
          brand._id === brandId ? { ...brand, active: newActiveStatus } : brand
        )
      );
  
      // Toggle the active status of the associated sub-brands within `editSubServiceData.subBrandPricing`
      setEditSubServiceData((prevData) => ({
        ...prevData,
        subBrandPricing: prevData.subBrandPricing.map((pricing) =>
          pricing.brand === brandId ? { ...pricing, active: newActiveStatus } : pricing
        ),
      }));
    } catch (error) {
// $&

    }
  };

  const handleUpdateSubBrand = async (subBrandId, updateData) => {
    try {
// $&


      const existingPricing = editSubServiceData.subBrandPricing.find((entry) => entry.subBrand === subBrandId);

      if (existingPricing) {
        setEditSubServiceData((prevData) => ({
          ...prevData,
          subBrandPricing: prevData.subBrandPricing.map((entry) =>
            entry.subBrand === subBrandId ? { ...entry, ...updateData } : entry
          ),
        }));
      } else {
        setEditSubServiceData((prevData) => ({
          ...prevData,
          subBrandPricing: [...prevData.subBrandPricing, { subBrand: subBrandId, ...updateData }],
        }));
      }
    } catch (error) {
// $&

    }
  };

  const handleDeleteSubService = async () => {
    try {
      await axiosInstance.delete(`/services/${serviceId}/sub-services/${subServiceId}`);
      onDeleteSubService(subServiceId);
      handleCloseDeleteDialog();
    } catch (error) {
// $&

    }
  };

  const handleOpenDeleteDialog = () => setDeleteDialogOpen(true);
  const handleCloseDeleteDialog = () => setDeleteDialogOpen(false);

  if (loading) return <Typography sx={{ color: '#00FFFF' }}>Loading sub-service details...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box sx={{ mt: 3, bgcolor: '#1C1C1C', p: 3, borderRadius: 2, color: '#C0C0C0' }}>
      <Typography variant="h6" gutterBottom sx={{ color: '#39FF14' }}>Sub-Service Details</Typography>
      <TextField
        label="Sub-Service Name"
        value={editSubServiceData.name}
        onChange={(e) => setEditSubServiceData({ ...editSubServiceData, name: e.target.value })}
        fullWidth
        margin="normal"
        InputLabelProps={{ style: { color: '#C0C0C0' } }}
        InputProps={{ style: { color: '#00FFFF' } }}
      />
      <TextField
        label="Install Time (minutes)"
        value={editSubServiceData.installTime}
        onChange={(e) => setEditSubServiceData({ ...editSubServiceData, installTime: e.target.value })}
        fullWidth
        margin="normal"
        InputLabelProps={{ style: { color: '#C0C0C0' } }}
        InputProps={{ style: { color: '#00FFFF' } }}
      />
      <TextField
        label="Cure Time (minutes)"
        value={editSubServiceData.cureTime}
        onChange={(e) => setEditSubServiceData({ ...editSubServiceData, cureTime: e.target.value })}
        fullWidth
        margin="normal"
        InputLabelProps={{ style: { color: '#C0C0C0' } }}
        InputProps={{ style: { color: '#00FFFF' } }}
      />
      <PricingStrategySelection
        pricingStrategy={editSubServiceData.pricingStrategy}
        onChange={(e) => setEditSubServiceData({ ...editSubServiceData, pricingStrategy: e.target.value })}
      />
      <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Save />}
          onClick={handleSave}
          sx={{ bgcolor: '#39FF14', color: '#000000', '&:hover': { bgcolor: '#00FFFF' } }}
        >
          Save
        </Button>
        <IconButton color="inherit" onClick={fetchSubServiceDetails} sx={{ color: '#FF0000' }}>
          <Cancel />
        </IconButton>
        <IconButton color="inherit" onClick={handleOpenDeleteDialog} sx={{ color: '#FF0000' }}>
          <Delete />
        </IconButton>
      </Box>

      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Delete Sub-Service</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this sub-service? This action cannot be undone.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">Cancel</Button>
          <Button onClick={handleDeleteSubService} color="primary" autoFocus>Delete</Button>
        </DialogActions>
      </Dialog>

      {/* Brands and Sub-Brands Section */}
      <Box sx={{ mt: 3 }}>
        <Typography variant="h6" gutterBottom sx={{ color: '#39FF14' }}>Brands and Sub-Brands</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
          {brands.map((brand) => (
            <Box key={brand._id} sx={{ width: '30%', bgcolor: '#2C2C2C', p: 2, borderRadius: 2 }}>
              <BrandCard brand={brand} onToggle={handleToggleBrand} />
              {brand.active && (
                <Box sx={{ mt: 2 }}>
                  {brand.subBrands && brand.subBrands.map((subBrand) => {
                    const existingEntry = editSubServiceData.subBrandPricing.find((entry) => entry.subBrand === subBrand._id);
                    return (
                      <Box key={subBrand._id} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <SubBrandCard
  subBrand={{
    ...subBrand,
    price: existingEntry ? existingEntry.price : subBrand.price || 0,
    active: existingEntry ? existingEntry.active : subBrand.active || false,
  }}
  onToggle={(subBrandId) => {
// $&

    handleUpdateSubBrand(subBrandId, { active: existingEntry ? !existingEntry.active : !subBrand.active });
  }}
  onPriceChange={(subBrandId, price) => {
// $&

    handleUpdateSubBrand(subBrandId, { price: parseFloat(price) });
  }}
/>
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default SubServiceDetails;
