import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import axiosInstance from '../../utils/axios';
import axiosAuthInstance from '../../utils/axiosAuth';

function NewEmployeeForm({ open, onClose, onSave }) {
  const [employeeData, setEmployeeData] = useState({
    firstName: '',
    lastName: '',
    role: '',
    assignedServices: [],
    availability: [],
    username: '',
    email: '',
    password: '',
  });
  const [services, setServices] = useState([]);
  const [applyToAllDays, setApplyToAllDays] = useState(false);
  const [availability, setAvailability] = useState([]);

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
      const response = await axiosInstance.get('/services');
      setServices(response.data);
    } catch (error) {
// $&

      alert('Failed to fetch services. Please try again.');
    }
  };

  const handleSave = async () => {
    try {
      const newEmployeeData = {
        ...employeeData,
        availability,
      };
  
// $&

  
      // Create new employee
      const response = await axiosInstance.post('/employees', newEmployeeData); // This uses the generic axiosInstance
      const createdEmployee = response.data;
  
// $&

  
      // Create user account for the employee using AuthService
      const userData = {
        username: employeeData.username || createdEmployee.firstName.toLowerCase(),
        email: employeeData.email,
        password: employeeData.password,
        accountLevelRole: 'Employee', // Adjust role if needed
        firstName: createdEmployee.firstName,
        lastName: createdEmployee.lastName,
      };
  
// $&

  
      await axiosAuthInstance.post('/users/create-account-user', userData); // Use axiosAuthInstance for AuthService
  
// $&

  
      onSave();
      onClose();
    } catch (error) {
// $&

    }
  };

  const handleAddAvailability = () => {
    setAvailability([...availability, { day: '', startTime: '', endTime: '' }]);
  };

  const handleAvailabilityChange = (index, field, value) => {
    const updatedAvailability = [...availability];
    updatedAvailability[index][field] = value;

    if (applyToAllDays && field !== 'day') {
      setAvailability((prev) =>
        prev.map((slot, i) => ({
          ...slot,
          ...(i === index || slot.day ? {} : { day: updatedAvailability[index].day }),
          startTime: field === 'startTime' ? value : slot.startTime,
          endTime: field === 'endTime' ? value : slot.endTime,
        }))
      );
    } else {
      setAvailability(updatedAvailability);
    }
  };

  const handleRemoveAvailability = (index) => {
    const updatedAvailability = availability.filter((_, i) => i !== index);
    setAvailability(updatedAvailability);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add New Employee</DialogTitle>
      <DialogContent>
        <TextField
          label="First Name"
          value={employeeData.firstName}
          onChange={(e) => setEmployeeData({ ...employeeData, firstName: e.target.value })}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Last Name"
          value={employeeData.lastName}
          onChange={(e) => setEmployeeData({ ...employeeData, lastName: e.target.value })}
          fullWidth
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Role</InputLabel>
          <Select
            value={employeeData.role}
            onChange={(e) => setEmployeeData({ ...employeeData, role: e.target.value })}
          >
            <MenuItem value="Manager">Manager</MenuItem>
            <MenuItem value="Technician">Technician</MenuItem>
            <MenuItem value="Subcontractor">Subcontractor</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel>Assigned Services</InputLabel>
          <Select
            multiple
            value={employeeData.assignedServices}
            onChange={(e) => setEmployeeData({ ...employeeData, assignedServices: e.target.value })}
          >
            {services.map((service) => (
              <MenuItem key={service._id} value={service._id}>
                {service.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Typography variant="h6" sx={{ mt: 2 }}>
          Availability
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={applyToAllDays}
              onChange={(e) => setApplyToAllDays(e.target.checked)}
            />
          }
          label="Apply time to all days"
        />
        {availability.map((slot, index) => (
          <Box key={index} sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Day</InputLabel>
              <Select
                value={slot.day}
                onChange={(e) => handleAvailabilityChange(index, 'day', e.target.value)}
              >
                <MenuItem value="Monday">Monday</MenuItem>
                <MenuItem value="Tuesday">Tuesday</MenuItem>
                <MenuItem value="Wednesday">Wednesday</MenuItem>
                <MenuItem value="Thursday">Thursday</MenuItem>
                <MenuItem value="Friday">Friday</MenuItem>
                <MenuItem value="Saturday">Saturday</MenuItem>
                <MenuItem value="Sunday">Sunday</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Start Time"
              type="time"
              value={slot.startTime}
              onChange={(e) => handleAvailabilityChange(index, 'startTime', e.target.value)}
              fullWidth
            />
            <TextField
              label="End Time"
              type="time"
              value={slot.endTime}
              onChange={(e) => handleAvailabilityChange(index, 'endTime', e.target.value)}
              fullWidth
            />
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => handleRemoveAvailability(index)}
            >
              Remove
            </Button>
          </Box>
        ))}
        <Button variant="contained" color="primary" onClick={handleAddAvailability} sx={{ mt: 2 }}>
          Add Availability Slot
        </Button>

        <Typography variant="h6" sx={{ mt: 2 }}>
          Set Credentials
        </Typography>
        <TextField
          label="Username"
          value={employeeData.username}
          onChange={(e) => setEmployeeData({ ...employeeData, username: e.target.value })}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          value={employeeData.email}
          onChange={(e) => setEmployeeData({ ...employeeData, email: e.target.value })}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Password"
          type="password"
          value={employeeData.password}
          onChange={(e) => setEmployeeData({ ...employeeData, password: e.target.value })}
          fullWidth
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NewEmployeeForm;
