// src/components/AdminDashboard/Users/ManageUser.jsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

function ManageUser() {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [selectedPlanId, setSelectedPlanId] = useState('');
  const [trialEndDate, setTrialEndDate] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch user data
        const userResponse = await axios.get(`http://localhost:5000/api/users/${userId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUser(userResponse.data);

        // Fetch subscription plans
        const plansResponse = await axios.get('http://localhost:5000/api/subscription/plans', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSubscriptionPlans(plansResponse.data);
      } catch (err) {
// $&

        setError('Failed to load user data.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId, token]);

  const handleApplyFreeTrial = async () => {
    try {
      await axios.post(
        'http://localhost:5000/api/users/apply-free-trial',
        {
          userId,
          subscriptionId: selectedPlanId,
          trialEnd: trialEndDate,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      alert('Free trial applied successfully.');
      navigate('/admin');
    } catch (err) {
// $&

      alert('Failed to apply free trial.');
    }
  };

  if (loading) {
    return <div>Loading user data...</div>;
  }

  if (error || !user) {
    return <div>{error || 'User not found'}</div>;
  }

  return (
    <div>
      <h1>Manage User: {user.username}</h1>
      <p>Email: {user.email}</p>
      <p>Role: {user.accountLevelRole || user.appLevelRole}</p>
      <p>Subscription: {user.subscriptionPlan ? user.subscriptionPlan.name : 'None'}</p>

      <h2>Apply Free Trial</h2>
      <div>
        <label>Select Plan:</label>
        <select value={selectedPlanId} onChange={(e) => setSelectedPlanId(e.target.value)}>
          <option value="">-- Select a Plan --</option>
          {subscriptionPlans.map((plan) => (
            <option key={plan._id} value={plan._id}>
              {plan.name}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label>Trial End Date:</label>
        <input
          type="date"
          value={trialEndDate}
          onChange={(e) => setTrialEndDate(e.target.value)}
        />
      </div>
      <button onClick={handleApplyFreeTrial} disabled={!selectedPlanId || !trialEndDate}>
        Apply Free Trial
      </button>
    </div>
  );
}

export default ManageUser;
