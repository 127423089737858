// components/Invoice/PaymentForm.jsx
import React, { useState } from 'react';
import axiosInvoiceInstance from '../../utils/axiosInvoice';
import { useParams, useNavigate } from 'react-router-dom';

const PaymentForm = () => {
  const { id } = useParams(); // Invoice ID
  const [formData, setFormData] = useState({ amount: 0, method: 'Credit Card', reference: '' });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInvoiceInstance.post(`/invoices/${id}/payment`, formData);
      navigate(`/invoices/${id}`);
    } catch (error) {
// $&

    }
  };

  return (
    <div className="payment-form">
      <h2>Record Payment</h2>
      <form onSubmit={handleSubmit}>
        <input name="amount" type="number" placeholder="Amount" value={formData.amount} onChange={handleChange} required />
        <select name="method" value={formData.method} onChange={handleChange}>
          <option value="Credit Card">Credit Card</option>
          <option value="Cash">Cash</option>
          <option value="Bank Transfer">Bank Transfer</option>
          <option value="Other">Other</option>
        </select>
        <input name="reference" placeholder="Reference" value={formData.reference} onChange={handleChange} />
        <button type="submit">Record Payment</button>
      </form>
    </div>
  );
};

export default PaymentForm;
