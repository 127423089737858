import { styled } from '@mui/system';

export const Root = styled('div')(({ theme }) => ({
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }));

export const TopBar = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end', // Align to the top-right corner
    position: 'absolute',
    top: 16,
    right: 16,
    gap: 8, // Spacing between buttons
  });

export const Title = styled('h1')(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontWeight: 'bold',
  textAlign: 'center',
}));

export const Subtitle = styled('p')(({ theme }) => ({
  marginBottom: theme.spacing(4),
  textAlign: 'center',
}));

export const PlanContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: theme.spacing(3), // Add consistent spacing between cards
}));

export const PlanCard = styled('div')(({ theme }) => ({
  width: '300px', // Fixed width for all cards
  height: '350px', // Fixed height for all cards
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const PlanTitle = styled('h2')(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
}));

export const PlanDescription = styled('p')(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: theme.palette.text.secondary,
}));

export const PlanPrice = styled('h3')(({ theme }) => ({
  marginBottom: theme.spacing(3),
  fontWeight: 'bold',
  color: theme.palette.text.primary,
}));

export const SignUpButton = styled('button')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1),
  border: 'none',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export const SmallButton = styled('button')(({ theme }) => ({
    width: 80,
    height: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    border: 'none',
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    fontSize: '0.8rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  }));
