import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, MenuItem } from '@mui/material';
import axiosInstance from '../../utils/axios';
import { useNavigate } from 'react-router-dom';
import AddressAutocomplete from '../AddressAutocomplete'; // Import AddressAutocomplete component

function BusinessProfile({ onSave }) {
  const [profileData, setProfileData] = useState({
    businessName: '',
    address: '',
    phone: '',
    email: '',
    workingHours: '',
    daysOfOperation: '',
    numberOfBays: '',
    timeZone: '', // Add time zone state
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const timeZones = [
    'America/New_York',
    'America/Los_Angeles',
    'America/Chicago',
    'America/Denver',
    'Europe/London',
    'Europe/Paris',
    // Add more time zones as needed
  ];

  useEffect(() => {
    // Fetch existing business profile data
    const fetchBusinessProfile = async () => {
      try {
        const response = await axiosInstance.get('/business-profile');
        if (response.data) {
          setProfileData(response.data);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 404) {
// $&

          } else if (error.response.status === 401) {
// $&

            navigate('/login'); // Use navigate instead of history.push
          } else {
// $&

            setError('Error fetching business profile. Please try again later.');
          }
        } else {
// $&

          setError('Error fetching business profile. Please try again later.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchBusinessProfile();
  }, [navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const handleAddressChange = (address) => {
    setProfileData({ ...profileData, address }); // Update address field
  };

  const handleSave = async () => {
    try {
      const response = await axiosInstance.put('/business-profile', profileData);
// $&

      if (onSave) {
        onSave(); // Notify the parent component
      }
    } catch (error) {
// $&

      setError('Error saving business profile. Please try again later.');
    }
  };

  if (loading) {
    return <Typography sx={{ color: '#00FFFF' }}>Loading business profile...</Typography>;
  }

  return (
    <Box sx={{ mt: 3, bgcolor: '#1C1C1C', p: 3, borderRadius: 2, color: '#C0C0C0' }}>
      <Typography variant="h6" gutterBottom sx={{ color: '#39FF14' }}>
        Business Profile
      </Typography>
      {error && (
        <Typography color="error" gutterBottom>
          {error}
        </Typography>
      )}
      <TextField
        label="Business Name"
        name="businessName"
        value={profileData.businessName}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        InputLabelProps={{ style: { color: '#C0C0C0' } }}
        InputProps={{ style: { color: '#00FFFF' } }}
      />
      {/* Replace Address Input with AddressAutocomplete */}
      <AddressAutocomplete
        label="Address"
        value={profileData.address}
        onChange={handleAddressChange}
      />
      <TextField
        label="Phone"
        name="phone"
        value={profileData.phone}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        InputLabelProps={{ style: { color: '#C0C0C0' } }}
        InputProps={{ style: { color: '#00FFFF' } }}
      />
      <TextField
        label="Email"
        name="email"
        value={profileData.email}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        InputLabelProps={{ style: { color: '#C0C0C0' } }}
        InputProps={{ style: { color: '#00FFFF' } }}
      />
      <TextField
        label="Working Hours"
        name="workingHours"
        value={profileData.workingHours}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        InputLabelProps={{ style: { color: '#C0C0C0' } }}
        InputProps={{ style: { color: '#00FFFF' } }}
      />
      <TextField
        label="Days of Operation"
        name="daysOfOperation"
        value={profileData.daysOfOperation}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        InputLabelProps={{ style: { color: '#C0C0C0' } }}
        InputProps={{ style: { color: '#00FFFF' } }}
      />
      <TextField
        label="Number of Bays"
        name="numberOfBays"
        value={profileData.numberOfBays}
        onChange={handleInputChange}
        type="number"
        fullWidth
        margin="normal"
        InputLabelProps={{ style: { color: '#C0C0C0' } }}
        InputProps={{ style: { color: '#00FFFF' } }}
      />

      {/* Time Zone Selection */}
      <TextField
        select
        label="Time Zone"
        name="timeZone"
        value={profileData.timeZone}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        InputLabelProps={{ style: { color: '#C0C0C0' } }}
        InputProps={{ style: { color: '#00FFFF' } }}
      >
        {timeZones.map((zone) => (
          <MenuItem key={zone} value={zone}>
            {zone}
          </MenuItem>
        ))}
      </TextField>

      <Box sx={{ mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          sx={{ bgcolor: '#39FF14', color: '#000000', '&:hover': { bgcolor: '#00FFFF' } }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
}

export default BusinessProfile;
