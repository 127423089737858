import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, MenuItem, Select } from '@mui/material';

const AdminSubServiceDetails = ({ subService, onSave, onCancel }) => {
  const [formData, setFormData] = useState({
    name: '',
    installTimeHours: 0,
    installTimeMinutes: 0,
    cureTimeHours: 0,
    cureTimeMinutes: 0,
    pricingStrategy: '',
    service: '',
  });

  useEffect(() => {
    if (subService) {
      setFormData({
        name: subService.name || '',
        installTimeHours: Math.floor((subService.installTime || 0) / 60),
        installTimeMinutes: (subService.installTime || 0) % 60,
        cureTimeHours: Math.floor((subService.cureTime || 0) / 60),
        cureTimeMinutes: (subService.cureTime || 0) % 60,
        pricingStrategy: subService.pricingStrategy || '',
        service: subService.service || '',
      });
    }
  }, [subService]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update numeric values for time fields and retain string values for others
    setFormData({
      ...formData,
      [name]: name.includes('Time') ? Math.max(0, parseInt(value || '0', 10)) : value,
    });
  };

  const handleSubmit = () => {
    const finalData = {
      ...formData,
      installTimeHours: formData.installTimeHours,
      installTimeMinutes: formData.installTimeMinutes,
      cureTimeHours: formData.cureTimeHours,
      cureTimeMinutes: formData.cureTimeMinutes,
    };
  
    delete finalData.installTime; // Remove if any old calculation exists
    delete finalData.cureTime;   // Remove if any old calculation exists
  
// $&

    onSave(finalData); // Send raw data to the backend
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        {subService?._id ? 'Edit Sub-Service' : 'Add Sub-Service'}
      </Typography>
      <TextField
        label="Name"
        name="name"
        value={formData.name}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <Box display="flex" gap={2} marginBottom={2}>
        <TextField
          label="Install Time (hours)"
          name="installTimeHours"
          type="number"
          value={formData.installTimeHours}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          label="Install Time (minutes)"
          name="installTimeMinutes"
          type="number"
          value={formData.installTimeMinutes}
          onChange={handleChange}
          fullWidth
        />
      </Box>
      <Box display="flex" gap={2} marginBottom={2}>
        <TextField
          label="Cure Time (hours)"
          name="cureTimeHours"
          type="number"
          value={formData.cureTimeHours}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          label="Cure Time (minutes)"
          name="cureTimeMinutes"
          type="number"
          value={formData.cureTimeMinutes}
          onChange={handleChange}
          fullWidth
        />
      </Box>
      <Box marginBottom={2}>
        <Typography>Pricing Strategy</Typography>
        <Select
          name="pricingStrategy"
          value={formData.pricingStrategy}
          onChange={handleChange}
          fullWidth
        >
          <MenuItem value="">Select Pricing Strategy</MenuItem>
          <MenuItem value="BySquareFootage">By Square Footage</MenuItem>
          <MenuItem value="ByPanel">By Panel</MenuItem>
          <MenuItem value="ByPackage">By Package</MenuItem>
        </Select>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Save
        </Button>
        <Button variant="outlined" color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default AdminSubServiceDetails;
