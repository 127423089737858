import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, TextField } from '@mui/material';

function CompleteRegistration() {
  const location = useLocation();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const [plans, setPlans] = useState([]);
  const [userData, setUserData] = useState(null);

  const authMeEndpoint = process.env.REACT_APP_AUTH_ME_ENDPOINT;
  const subscriptionPlansEndpoint = process.env.REACT_APP_SUBSCRIPTION_PLANS_ENDPOINT;
  const subscribeEndpoint = process.env.REACT_APP_SUBSCRIBE_ENDPOINT;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        if (decodedToken.exp * 1000 < Date.now()) {
          navigate('/login');
          return;
        }

        localStorage.setItem('token', token);

        // Fetch user data
        axios
          .get(authMeEndpoint, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            setUserData(response.data);
          })
          .catch((error) => {
            console.error('Error fetching user data:', error.message);
            navigate('/login');
          });
      } catch (error) {
        console.error('Error decoding token:', error.message);
        navigate('/login');
      }
    } else {
      navigate('/login');
    }
  }, [location.search, navigate, authMeEndpoint]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    axios
      .get(subscriptionPlansEndpoint, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setPlans(response.data);
      })
      .catch((error) => {
        console.error('Error fetching subscription plans:', error.message);
      });
  }, [navigate, subscriptionPlansEndpoint]);

  const initialValues = {
    selectedPlanId: '',
    subdomain: '', // Add subdomain field
  };

  const validationSchema = Yup.object({
    selectedPlanId: Yup.string().required('Please select a subscription plan'),
    subdomain: Yup.string()
      .matches(/^[a-z0-9-]+$/, 'Subdomain can only contain lowercase letters, numbers, and hyphens.')
      .required('Subdomain is required'),
  });

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      if (!stripe || !elements) {
        setSubmitting(false);
        return;
      }

      const token = localStorage.getItem('token');
      if (!token) {
        setErrors({ submit: 'You must be authenticated to complete registration.' });
        setSubmitting(false);
        return;
      }

      const selectedPlanId = values.selectedPlanId;
      const { subdomain } = values;

      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        setErrors({ payment: 'Payment information is missing or invalid.' });
        setSubmitting(false);
        return;
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        setErrors({ payment: error.message });
        setSubmitting(false);
        return;
      }

      // remove before deployment: If the backend requires updating the user record with subdomain before subscribing,
      // here you might need to call an endpoint like:
      // await axios.post(
      //   userUpdateEndpoint, 
      //   { subdomain }, 
      //   { headers: { Authorization: `Bearer ${token}` } }
      // );
      //
      // For now, we'll assume subdomain can be sent to subscribeEndpoint directly if the backend supports it.

      await axios.post(
        subscribeEndpoint,
        {
          subscriptionId: selectedPlanId,
          paymentMethodId: paymentMethod.id,
          subdomain, // Send the chosen subdomain along with subscription details
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      navigate('/dashboard');
    } catch (error) {
      setErrors({ submit: error.response?.data?.message || 'An error occurred' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <h1>Complete Registration</h1>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting, errors, handleChange, handleBlur, values }) => (
          <Form>
            <div>
              <label>Select Subscription Plan</label>
              <Field as="select" name="selectedPlanId">
                <option value="">-- Select a Plan --</option>
                {plans.map((plan) => (
                  <option key={plan._id} value={plan._id}>
                    {plan.name} - ${plan.price}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="selectedPlanId" component="div" style={{ color: 'red' }} />
            </div>

            <div>
              <label>Subdomain</label>
              <TextField
                name="subdomain"
                value={values.subdomain}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
              <ErrorMessage name="subdomain" component="div" style={{ color: 'red' }} />
            </div>

            <div>
              <label>Payment Information</label>
              <CardElement />
              {errors.payment && <div style={{ color: 'red' }}>{errors.payment}</div>}
            </div>

            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitting || !stripe || !elements}
              sx={{ mt: 2 }}
            >
              Complete Registration
            </Button>
            {errors.submit && <div style={{ color: 'red' }}>{errors.submit}</div>}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default CompleteRegistration;
