// src/components/Subscription/UpdateSubscription.jsx

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosAuth from '../../utils/axiosAuth'; // Update the import path based on your project structure

function UpdateSubscription() {
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedPlanId } = location.state || {};

  const token = localStorage.getItem('token');

  const handleUpdateSubscription = async () => {
    try {
      await axiosAuth.post(
        '/subscription/subscribe', // Base URL is automatically applied by axiosAuth
        {
          subscriptionId: selectedPlanId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      alert('Subscription updated successfully.');
      navigate('/dashboard');
    } catch (err) {
      console.error('Error updating subscription:', err.response || err);
      alert('Failed to update subscription.');
    }
  };

  return (
    <div>
      <h1>Confirm Plan Change</h1>
      <p>Are you sure you want to switch to this plan?</p>
      <button onClick={handleUpdateSubscription}>Confirm</button>
      <button onClick={() => navigate('/dashboard')}>Cancel</button>
    </div>
  );
}

export default UpdateSubscription;
