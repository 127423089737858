import React, { useState, useEffect } from 'react';
import { Box, Typography, Stepper, Step, StepLabel } from '@mui/material';
import PublicSchedulerWizard from './PublicSchedulerWizard';
import DateAndTimeSelection from './DateAndTimeSelection';
import CustomerInformationEntry from './CustomerInformationEntry';
import BookingSummary from './BookingSummary';

function SchedulerWizard() {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedSubServices, setSelectedSubServices] = useState([]);
  const [selectedSubBrands, setSelectedSubBrands] = useState({});
  const [estimatedDuration, setEstimatedDuration] = useState(0);
  const [appointmentSubtotal, setAppointmentSubtotal] = useState(0);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [customerInfo, setCustomerInfo] = useState({
    name: '',
    email: '',
    phone: '',
    notes: '',
  });

  const steps = [
    'Service Selection',
    'Date and Time Selection',
    'Customer Information Entry',
    'Booking Summary and Confirmation',
  ];

  const handleNext = () => setActiveStep((prev) => prev + 1);
  const handleBack = () => setActiveStep((prev) => prev - 1);

  // Handle service selection completion
  const handleServiceSelectionComplete = (data) => {
// $&

    setSelectedServices(data.selectedServices);
    setSelectedSubServices(data.selectedSubServices);
    setSelectedSubBrands(data.selectedSubBrands);
    setEstimatedDuration(data.appointmentDuration);
    setAppointmentSubtotal(data.appointmentSubtotal);
    handleNext();
  };

  // Handle date and time selection completion
  const handleDateAndTimeSelectionComplete = (data) => {
// $&

    setSelectedTimeSlot(data.selectedTimeSlot);
    handleNext();
  };

  // Handle customer information entry completion
  const handleCustomerInfoComplete = (info) => {
// $&

    setCustomerInfo(info);
    handleNext();
  };

  // Handle booking confirmation
  const handleBookingConfirmation = () => {
    const bookingData = {
      selectedServices,
      selectedSubServices,
      selectedSubBrands,
      estimatedDuration,
      appointmentSubtotal,
      selectedTimeSlot,
      customerInfo,
    };

// $&

    // You would make an API call here to save the booking
  };

  return (
    <Box sx={{ p: 4, backgroundColor: '#1C1C1C', color: '#C0C0C0' }}>
      <Typography variant="h4" gutterBottom sx={{ color: '#39FF14' }}>
        Scheduler Wizard
      </Typography>

      <Stepper activeStep={activeStep} sx={{ backgroundColor: 'transparent', mb: 4 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel sx={{ color: '#C0C0C0' }}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {/* Step 1: Service Selection */}
      {activeStep === 0 && (
        <PublicSchedulerWizard
          onBack={handleBack}
          onSelectionComplete={handleServiceSelectionComplete}
        />
      )}

      {/* Step 2: Date and Time Selection */}
      {activeStep === 1 && (
        <DateAndTimeSelection
          selectedSubServices={selectedSubServices}
          estimatedDuration={estimatedDuration}
          onSelectionComplete={handleDateAndTimeSelectionComplete}
          onBack={handleBack}
        />
      )}

      {/* Step 3: Customer Information Entry */}
      {activeStep === 2 && (
        <CustomerInformationEntry onBack={handleBack} onComplete={handleCustomerInfoComplete} />
      )}

      {/* Step 4: Booking Summary and Confirmation */}
      {activeStep === 3 && (
        <BookingSummary
          bookingData={{
            selectedServices,
            selectedSubServices,
            selectedSubBrands,
            appointmentSubtotal,
            estimatedDuration,
            selectedTimeSlot,
            customerInfo,
          }}
          onBack={handleBack}
          onConfirm={handleBookingConfirmation}
        />
      )}
    </Box>
  );
}

export default SchedulerWizard;
