import React, { useEffect, useState } from 'react';
import { useOwnerId } from '../../App'; // Assuming ownerId is provided via context
import { Button, Paper, Typography, Box } from '@mui/material';
import SchedulerWizard from './SchedulerWizard/SchedulerWizard'; // Correct import for SchedulerWizard

function PublicScheduler() {
  const ownerId = useOwnerId(); // Get ownerId from context
  const [businessProfile, setBusinessProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showSchedulerWizard, setShowSchedulerWizard] = useState(false);

  useEffect(() => {
    if (!ownerId) {
      console.error('Owner ID is not set. Cannot fetch business profile.');
      setLoading(false);
      return;
    }

    const fetchBusinessProfile = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_AUTH_SERVICE_URL}/api/business-profile/public/${ownerId}`
        );

        if (!response.ok) {
          throw new Error(`Error fetching business profile: ${response.statusText}`);
        }

        const data = await response.json();
        setBusinessProfile(data);
      } catch (error) {
        console.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBusinessProfile();
  }, [ownerId]);

  const handleSchedulerWizardClick = () => {
    setShowSchedulerWizard(true);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!businessProfile) {
    return <div>Business profile not available at this time. Please try again later.</div>;
  }

  return (
    <Paper elevation={3} sx={{ p: 4, mt: 4, backgroundColor: '#1C1C1C', color: '#C0C0C0' }}>
      {showSchedulerWizard ? (
        <SchedulerWizard ownerId={ownerId} />
      ) : (
        <>
          <Typography variant="h4" gutterBottom sx={{ color: '#39FF14' }}>
            Welcome to {businessProfile.businessName}
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ color: '#C0C0C0' }}>
            Here on this page, you can get accurate pricing and appointment availability while
            also being able to schedule the appointment at your convenience.
          </Typography>

          {/* Button to proceed to Scheduler Wizard */}
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 4 }}
            onClick={handleSchedulerWizardClick}
          >
            Proceed to Scheduler Wizard
          </Button>
        </>
      )}
    </Paper>
  );
}

export default PublicScheduler;
