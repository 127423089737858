import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Select, MenuItem } from '@mui/material';
import axiosInstance from '../../../utils/axios';

const AdminSubBrandDetails = ({ subBrand, onSave, onCancel }) => {
  const [formData, setFormData] = useState({
    name: '',
    brand: '',
  });
  const [brands, setBrands] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    // Fetch available brands
    const fetchBrands = async () => {
      try {
        const response = await axiosInstance.get('/admin/brands');
        setBrands(response.data);

        // If editing a sub-brand, ensure the current brand is set in the dropdown
        if (subBrand) {
          setFormData({
            name: subBrand.name || '',
            brand: subBrand.brand?._id || '', // Use the brand ID if available
          });
        }
      } catch (err) {
// $&

        setError('Failed to fetch brands.');
      }
    };

    fetchBrands();
  }, [subBrand]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    if (!formData.brand) {
      setError('Brand is required.');
      return;
    }
    onSave(formData);
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        {subBrand ? 'Edit Sub-Brand' : 'Add New Sub-Brand'}
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      <TextField
        label="Name"
        name="name"
        value={formData.name}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <Box marginBottom={2}>
        <Typography>Select a Brand:</Typography>
        <Select
          name="brand"
          value={formData.brand}
          onChange={handleChange}
          fullWidth
        >
          <MenuItem value="">Select Brand</MenuItem>
          {brands.map((brand) => (
            <MenuItem key={brand._id} value={brand._id}>
              {brand.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Save
        </Button>
        <Button variant="outlined" color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default AdminSubBrandDetails;
