import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axiosInvoiceInstance from '../../utils/axiosInvoice';
import PropTypes from 'prop-types';
import './InvoiceList.css';


const InvoiceList = ({ customerId }) => {
  const [invoices, setInvoices] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        let response;
        
        if (customerId) {
          // Fetch invoices for a specific customer if customerId is provided
          response = await axiosInvoiceInstance.get(`/invoices/customer/${customerId}`);
// $&

        } else {
            // Fetch all invoices for the current owner by explicitly including ownerId
// $&

            const ownerId = localStorage.getItem('ownerId'); // Assuming ownerId is stored in localStorage
            response = await axiosInvoiceInstance.get(`/invoices`, {
                params: { ownerId },
            });
        }

        setInvoices(response.data);
      } catch (error) {
// $&

      }
    };

    fetchInvoices();
  }, [customerId]);

  const deleteInvoice = async (id) => {
    if (!window.confirm('Are you sure you want to delete this invoice?')) {
      return;
    }
    try {
// $&

      await axiosInvoiceInstance.delete(`/invoices/${id}`);
      setInvoices((prevInvoices) => prevInvoices.filter(invoice => invoice._id !== id));
// $&

    } catch (error) {
// $&

    }
  };

  return (
    <div className="invoice-list">
      <h3>{customerId ? `Invoices` : 'All Invoices'}</h3>
      {invoices.length > 0 ? (
        <ul>
          {invoices.map((invoice) => (
            <li key={invoice._id}>
              <Link to={`/invoices/${invoice._id}`}>Invoice #{invoice.invoiceNumber}</Link>
              <button onClick={() => navigate(`/invoices/${invoice._id}/edit`)}>Edit</button>
              <button onClick={() => deleteInvoice(invoice._id)}>Delete</button>
            </li>
          ))}
        </ul>
      ) : (
        <p>No invoices found.</p>
      )}
      {console.log('Current state of invoices:', invoices)}
    </div>
  );
};

InvoiceList.propTypes = {
  customerId: PropTypes.string,
};

export default InvoiceList;
