import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import axiosInstance from '../../utils/axios';
import BusinessProfile from '../BusinessProfile/BusinessProfile';
import OnboardingProgressBar from './OnboardingProgressBar'; // Make sure to import your progress bar component
import './OnboardingWizard.css';

const OnboardingStep1 = () => {
  const [isCompleted, setIsCompleted] = useState(false);
  const [showExitDialog, setShowExitDialog] = useState(false);
  const [ownerId, setOwnerId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Extract ownerId from the token
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setOwnerId(decodedToken.userId);
      } catch (err) {
// $&

        // Handle error, perhaps redirect to login
      }
    } else {
      // If no token, redirect to login
      navigate('/login');
    }
  }, [navigate]);

  const handleSaveCompletion = async () => {
    try {
      // Update the onboarding status to currentStep = 2
      await axiosInstance.put(`/onboarding-status/${ownerId}`, {
        currentStep: 2,
      });
      setIsCompleted(true);
// $&

      navigate('/onboarding/step2'); // Automatically proceed to Step 2
    } catch (error) {
// $&

      alert('Failed to update onboarding status. Please try again.');
    }
  };

  const handleExit = () => {
    setShowExitDialog(true);
  };

  const confirmExit = () => {
    setShowExitDialog(false);
    navigate('/dashboard');
  };

  return (
    <div className="onboarding-step">
      <h1>Onboarding Wizard: Step 1</h1>
      <p>Set up your business profile to get started.</p>

      {/* Include the Progress Bar */}
      <OnboardingProgressBar currentStep={1} totalSteps={9} />

      <BusinessProfile onSave={handleSaveCompletion} />

      <div className="onboarding-actions">
        <button onClick={handleExit} className="wizard-button">
          Exit Onboarding
        </button>
      </div>

      {showExitDialog && (
        <div className="dialog">
          <p>
            Are you sure you want to exit? The app may not function correctly until onboarding is
            complete.
          </p>
          <button onClick={confirmExit} className="dialog-button">
            Confirm Exit
          </button>
          <button onClick={() => setShowExitDialog(false)} className="dialog-button">
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};

export default OnboardingStep1;
