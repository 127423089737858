// remove before deployment: This function extracts the subdomain from the hostname.
export function getSubdomain() {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');
    
    // If we have at least 3 parts, we assume the first part is a subdomain
    // Example: autopro.drivesuitecrm.com → parts[0] = 'autopro'
    if (parts.length >= 3) {
      return parts[0];
    }
  
    // If not enough parts, no subdomain present
    return null;
  }
  