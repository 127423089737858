import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';

const OnboardingProgressBar = ({ currentStep, totalSteps }) => {
  const progress = Math.round((currentStep / totalSteps) * 100);

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      <Typography variant="subtitle1" gutterBottom>
        {`Onboarding Progress: Step ${currentStep} of ${totalSteps}`}
      </Typography>
      <LinearProgress variant="determinate" value={progress} />
    </Box>
  );
};

export default OnboardingProgressBar;
