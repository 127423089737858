import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

function VehicleDetails() {
  const { id } = useParams();
  const [vehicle, setVehicle] = useState(null);
  const [vehicleImages, setVehicleImages] = useState(null); // To hold images from Evox API
  const [vehicleColors, setVehicleColors] = useState(null); // To hold colors from Evox API
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  // Fetch vehicle details from local DB and Evox API
  useEffect(() => {
    const fetchVehicle = async () => {
      try {
        // Fetch vehicle data from local API
        const response = await axios.get(`http://localhost:5002/api/vehicles/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setVehicle(response.data);

        // Fetch vehicle data from Evox API using VIF
        if (response.data.vif) {
          const evoxResponse = await axios.get(
            `https://api.evoximages.com/api/v1/vehicles/${response.data.vif}`,
            {
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_EVOX_API_KEY}`,
              },
            }
          );
          setVehicleImages(evoxResponse.data.images);
        }

        // Fetch vehicle colors from Evox API
        const colorsResponse = await axios.get(
          `https://api.evoximages.com/api/v1/vehicles/${response.data.vif}/colors`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_EVOX_API_KEY}`,
            },
          }
        );
        setVehicleColors(colorsResponse.data.colors);

      } catch (error) {
// $&

      }
    };

    fetchVehicle();
  }, [id, token]);

  // Handle vehicle deletion
  const handleDelete = async () => {
    try {
      await axios.delete(`http://localhost:5002/api/vehicles/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      navigate('/vehicles');
    } catch (error) {
// $&

    }
  };

  if (!vehicle) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>
        {vehicle.make} {vehicle.model} ({vehicle.year})
      </h1>
      <p>VIN: {vehicle.vin}</p>
      <p>License Plate: {vehicle.licensePlate}</p>
      <p>Color: {vehicle.color}</p>
      <p>Mileage: {vehicle.mileage}</p>
      <p>
        Customer: {vehicle.customer?.firstName} {vehicle.customer?.lastName}
      </p>

      {/* Display Evox API vehicle images */}
      {vehicleImages && vehicleImages.length > 0 ? (
        <div>
          <h2>Vehicle Images</h2>
          {vehicleImages.map((image, index) => (
            <img key={index} src={image.url} alt={` ${index + 1}`} width="300" />
          ))}
        </div>
      ) : (
        <p>No vehicle images available</p>
      )}

      {/* Display Evox API vehicle colors */}
      {vehicleColors && vehicleColors.length > 0 ? (
        <div>
          <h2>Available Colors</h2>
          <ul>
            {vehicleColors.map((color, index) => (
              <li key={index}>{color.name}</li>
            ))}
          </ul>
        </div>
      ) : (
        <p>No color information available</p>
      )}

      <Link to={`/vehicles/${id}/edit`}>Edit</Link>
      <button onClick={handleDelete}>Delete</button>
      <Link to="/vehicles">Back to Vehicles</Link>
    </div>
  );
}

export default VehicleDetails;
