// components/Brands/SubBrandCard.jsx
import React from 'react';
import { Box, Typography, Switch, TextField } from '@mui/material';

function SubBrandCard({ subBrand, onToggle, onPriceChange }) {
  return (
    <Box
      sx={{
        bgcolor: '#333333',
        p: 2,
        borderRadius: 2,
        mb: 1,
        ml: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <Typography sx={{ color: '#C0C0C0' }}>{subBrand.name}</Typography>
        <TextField
          value={subBrand.price || ''} // Default to empty string if undefined
          onChange={(e) => onPriceChange(subBrand._id, parseFloat(e.target.value) || 0)} // Convert to number or 0
          label="Price"
          variant="outlined"
          margin="dense"
          disabled={!subBrand.active} // Disable price input if not active
          sx={{ bgcolor: '#2C2C2C', input: { color: '#00FFFF' }, label: { color: '#C0C0C0' } }}
        />
      </Box>
      <Switch
        checked={subBrand.active}
        onChange={() => onToggle(subBrand._id)}
        color="primary"
      />
    </Box>
  );
}

export default SubBrandCard;
