import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Breadcrumbs,
  Link,
  FormControlLabel,
  Checkbox,
  Radio,
  Divider,
} from '@mui/material';
import axiosInstance from '../../../utils/axios';
import DateAndTimeSelection from './DateAndTimeSelection';
import CustomerInformationEntry from './CustomerInformationEntry';
import BookingSummary from './BookingSummary';
import axiosAuthInstance from '../../../utils/axiosAuth';


function PublicSchedulerWizard({ onBack }) {
  const [selectedServices, setSelectedServices] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedSubServices, setSelectedSubServices] = useState([]);
  const [subServiceDetails, setSubServiceDetails] = useState({});
  const [selectedSubBrands, setSelectedSubBrands] = useState({});
  const [appointmentSubtotal, setAppointmentSubtotal] = useState(0);
  const [appointmentDuration, setAppointmentDuration] = useState(0);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [customerInfo, setCustomerInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    notes: '',
  });
  const [ownerId, setOwnerId] = useState(null); // Store the ownerId
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
// $&

    fetchServices();
  }, []);

  useEffect(() => {
    calculateSubtotalAndDuration();
  }, [selectedSubServices, selectedSubBrands]);

  const fetchServices = async () => {
    try {
      const response = await axiosInstance.get('/services');
      setServices(response.data);
  
      // Extract and set ownerId from the first service (assuming all have the same ownerId)
      if (response.data.length > 0) {
        const extractedOwnerId = response.data[0].ownerId;
        setOwnerId(extractedOwnerId);
// $&

      } else {
        console.warn('No services found; ownerId cannot be set.');
      }
    } catch (error) {
// $&

    }
  };

  const handleServiceSelection = (serviceId) => {
    const isSelected = selectedServices.includes(serviceId);
    let updatedSelectedServices = [];

    if (isSelected) {
      updatedSelectedServices = selectedServices.filter((id) => id !== serviceId);
      setSelectedSubServices(selectedSubServices.filter((sub) => sub.serviceId !== serviceId));
      setSelectedSubBrands((prev) => {
        const updated = { ...prev };
        Object.keys(updated).forEach((subServiceId) => {
          if (updated[subServiceId].serviceId === serviceId) {
            delete updated[subServiceId];
          }
        });
        return updated;
      });
    } else {
      updatedSelectedServices = [...selectedServices, serviceId];
    }

    setSelectedServices(updatedSelectedServices);
  };

  const handleSubServiceSelection = async (serviceId, subServiceId) => {
    const isSelected = selectedSubServices.some(
      (sub) => sub.serviceId === serviceId && sub.subServiceId === subServiceId
    );
    let updatedSelectedSubServices = [];
  
    if (isSelected) {
      updatedSelectedSubServices = selectedSubServices.filter(
        (sub) => !(sub.serviceId === serviceId && sub.subServiceId === subServiceId)
      );
      setSelectedSubBrands((prev) => {
        const updated = { ...prev };
        delete updated[subServiceId];
        return updated;
      });
    } else {
      const service = services.find((s) => s._id === serviceId);
      const subService = service.subServices.find((ss) => ss._id === subServiceId);
      updatedSelectedSubServices = [
        ...selectedSubServices,
        {
          serviceId,
          serviceName: service.name, // Include serviceName
          subServiceId,
          subServiceName: subService.name, // Include subServiceName
        },
      ];
      await fetchSubBrands(subServiceId);
    }
  
    setSelectedSubServices(updatedSelectedSubServices);
  };

  const fetchSubBrands = async (subServiceId) => {
    try {
// $&

      const response = await axiosInstance.get(`/sub-services/${subServiceId}/populate`, {
        params: { ownerId }, // Pass ownerId to fetch sub-brands
      });
      setSubServiceDetails((prev) => ({
        ...prev,
        [subServiceId]: response.data,
      }));
    } catch (error) {
// $&

    }
  };

  const handleSubBrandSelection = (subServiceId, subBrand) => {
    setSelectedSubBrands((prev) => ({
      ...prev,
      [subServiceId]: subBrand,
    }));

    // Dynamically set ownerId based on the selected sub-brand
    if (subBrand && subBrand.ownerId) {
// $&

      setOwnerId(subBrand.ownerId);
    }
  };

  const calculateSubtotalAndDuration = () => {
    let subtotal = 0;
    let totalDuration = 0;

    selectedSubServices.forEach(({ serviceId, subServiceId }) => {
      const subService = subServiceDetails[subServiceId];
      if (subService) {
        const subBrand = selectedSubBrands[subServiceId];
        if (subBrand) {
          const pricingEntry = subService.subBrandPricing.find(
            (entry) => entry.subBrand.toString() === subBrand._id
          );
          subtotal += pricingEntry ? parseFloat(pricingEntry.price || 0) : 0;
        }
        totalDuration += subService.installTime + subService.cureTime;
      }
    });

    setAppointmentSubtotal(subtotal);
    setAppointmentDuration(totalDuration);
  };

  const handleProceed = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleDateAndTimeSelectionComplete = (data) => {
// $&

    setSelectedTimeSlot({
      startTime: data.selectedTimeSlot.startTime, // Use consistent naming
      endTime: data.selectedTimeSlot.endTime,
      bayId: data.selectedTimeSlot.bayId,
      employeeId: data.selectedTimeSlot.employeeId,
      isMultiDay: data.selectedTimeSlot.isMultiDay,
    });
    handleProceed();
  };

  const handleCustomerInfoComplete = (info) => {
    setCustomerInfo(info);
    handleProceed();
  };

  const handleBookingConfirmation = () => {
    const customerData = {
      firstName: customerInfo.firstName?.trim() || '',
      lastName: customerInfo.lastName?.trim() || '',
      email: customerInfo.email?.trim() || '',
      phone: customerInfo.phone?.trim() || '',
      address: customerInfo.address?.trim() || '',
      notes: customerInfo.notes?.trim() || '',
      ownerId, // Add ownerId
    };

    axiosInstance.post('http://localhost:5001/api/customers', { ...customerData, ownerId })
      .then((response) => {
        const customerId = response.data._id;
        const servicesData = selectedSubServices.map(({ serviceId, subServiceId }) => ({
          service: serviceId,
          subService: subServiceId,
          subBrand: selectedSubBrands[subServiceId]?._id,
        }));

        const bookingData = {
          customer: customerId,
          services: servicesData,
          startTime: selectedTimeSlot.startTime,
          endTime: selectedTimeSlot.endTime,
          isMultiDay: selectedTimeSlot.isMultiDay,
          notes: customerInfo.notes?.trim() || '',
          bay: selectedTimeSlot.bayId,
          employee: [selectedTimeSlot.employeeId],
          status: 'Active',
          ownerId, // Ensure ownerId is added to the booking data
        };

        axiosInstance.post('http://localhost:5003/api/appointments', bookingData)
          .then((response) => {
// $&

          })
          .catch((error) => {
// $&

          });
      })
      .catch((error) => {
// $&

      });
  };

  return (
    <Box sx={{ p: 4, backgroundColor: '#1C1C1C', color: '#C0C0C0' }}>
      {currentStep === 1 && (
        <>
          <Breadcrumbs aria-label="breadcrumb" sx={{ color: '#00FFFF', mb: 3 }}>
            <Link color="inherit" onClick={onBack} sx={{ cursor: 'pointer', color: '#00FFFF' }}>
              Back
            </Link>
            <Typography color="textPrimary">Step 1: Service Selection</Typography>
          </Breadcrumbs>

          <Typography variant="h4" gutterBottom sx={{ color: '#39FF14' }}>
            Public Scheduler Wizard - Step 1: Service Selection
          </Typography>

          {services.map((service) => (
            <Box key={service._id} sx={{ mt: 4 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedServices.includes(service._id)}
                    onChange={() => handleServiceSelection(service._id, service.name)}
                    sx={{ color: '#39FF14' }}
                  />
                }
                label={service.name}
                sx={{ color: '#C0C0C0' }}
              />

              {selectedServices.includes(service._id) &&
                service.subServices?.map((subService) => (
                  <Box key={subService._id} sx={{ ml: 4 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedSubServices.some(
                            (sub) => sub.subServiceId === subService._id
                          )}
                          onChange={() => handleSubServiceSelection(service._id, subService._id)}
                          sx={{ color: '#39FF14' }}
                        />
                      }
                      label={subService.name}
                      sx={{ color: '#C0C0C0' }}
                    />

                    {selectedSubServices.some((sub) => sub.subServiceId === subService._id) && (
                      <>
                        {subServiceDetails[subService._id]?.subBrands?.length > 0 ? (
                          subServiceDetails[subService._id].subBrands.map((subBrand) => (
                            <Box key={subBrand._id} sx={{ ml: 3 }}>
                              <FormControlLabel
                                control={
                                  <Radio
                                    checked={
                                      selectedSubBrands[subService._id]?._id === subBrand._id
                                    }
                                    onChange={() =>
                                      handleSubBrandSelection(subService._id, subBrand)
                                    }
                                    sx={{ color: '#39FF14' }}
                                  />
                                }
                                label={`${subBrand.name ?? 'Unnamed'} - Price: $${subServiceDetails[subService._id].subBrandPricing.find(
                                  (entry) => entry.subBrand.toString() === subBrand._id
                                )?.price ?? 'N/A'}`}
                                sx={{ color: '#C0C0C0' }}
                              />
                            </Box>
                          ))
                        ) : (
                          <Typography sx={{ ml: 4, color: '#C0C0C0' }}>
                            No sub-brands available for this sub-service.
                          </Typography>
                        )}
                      </>
                    )}
                  </Box>
                ))}
            </Box>
          ))}

          <Divider sx={{ mt: 4, mb: 2 }} />
          <Typography variant="h6" sx={{ color: '#00FFFF' }}>
            Appointment Summary
          </Typography>
          <Typography sx={{ color: '#C0C0C0' }}>
            Subtotal: ${appointmentSubtotal.toFixed(2)}
          </Typography>
          <Typography sx={{ color: '#C0C0C0' }}>
            Estimated Duration: {appointmentDuration} Minutes
          </Typography>

          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 4 }}
            onClick={handleProceed}
            disabled={selectedServices.length === 0}
          >
            Proceed to Date & Time Selection
          </Button>
        </>
      )}

{currentStep === 2 && (
         <DateAndTimeSelection
         ownerId={ownerId}
         setOwnerId={setOwnerId} // Pass setOwnerId here
         estimatedDuration={appointmentDuration}
         serviceId={selectedServices[0]}
         onSelectionComplete={handleDateAndTimeSelectionComplete}
         onBack={() => setCurrentStep(1)}
     />
      )}

      {currentStep === 3 && (
        <CustomerInformationEntry
          onBack={() => setCurrentStep(2)}
          onComplete={handleCustomerInfoComplete}
        />
      )}

{currentStep === 4 && (
  <BookingSummary
    bookingData={{
      selectedServices, // Now contains { serviceId, serviceName }
      selectedSubServices, // Now contains { serviceId, serviceName, subServiceId, subServiceName }
      selectedSubBrands,
      appointmentSubtotal,
      estimatedDuration: appointmentDuration,
      selectedTimeSlot,
      customerInfo,
    }}
    ownerId={ownerId}
    onBack={() => setCurrentStep(3)}
    onConfirm={handleBookingConfirmation}
  />
)}
    </Box>
  );
}

export default PublicSchedulerWizard;