import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../utils/axios';
import {
  DashboardContainer,
  SidebarContainer,
  MainContentContainer,
  ServiceDetailsContainer,
  SubServiceContainer,
} from './styles';
import ServiceList from '../ServiceList';
import ServiceDetails from '../ServiceDetails';
import SubServiceList from '../../Services/ServiceManagement/SubServiceList';
import SubServiceDetails from '../../Services/ServiceManagement/SubServiceDetails';
import AddSubService from '../../Services/ServiceManagement/AddSubService';

function ServiceDashboard() {
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedSubService, setSelectedSubService] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  useEffect(() => {
    let isMounted = true;

    const fetchServices = async () => {
      try {
        const response = await axiosInstance.get('/services');
        if (isMounted) {
          setServices(response.data);
          if (response.data.length > 0) {
            const firstService = response.data[0];
            setSelectedService(firstService);
            if (firstService.subServices && firstService.subServices.length > 0) {
              setSelectedSubService(firstService.subServices[0]);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    fetchServices();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleSelectService = (service) => {
    setSelectedService(service);
    if (service.subServices && service.subServices.length > 0) {
      setSelectedSubService(service.subServices[0]);
    } else {
      setSelectedSubService(null);
    }
    window.scrollTo(0, 0);
  };

  const handleSelectSubService = (subService) => {
    setSelectedSubService(subService);
  };

  const handleUpdateSubService = (updatedSubService) => {
    if (!selectedService) return;

    setSelectedService((prevService) => {
      const updatedSubServices = prevService.subServices.map((subService) =>
        subService._id === updatedSubService._id ? updatedSubService : subService
      );
      return { ...prevService, subServices: updatedSubServices };
    });
    setSelectedSubService(updatedSubService);
  };

  const handleSaveService = (updatedService) => {
    setServices((prevServices) =>
      prevServices.map((service) =>
        service._id === updatedService._id ? updatedService : service
      )
    );
    setSelectedService(updatedService);
    if (updatedService.subServices && updatedService.subServices.length > 0) {
      setSelectedSubService(updatedService.subServices[0]);
    } else {
      setSelectedSubService(null);
    }
  };

  const handleSubServiceAdded = () => {
    setRefreshTrigger((prev) => prev + 1);
  };

  const handleDeleteSubService = () => {
    setRefreshTrigger((prev) => prev + 1);
  };

  return (
    <DashboardContainer>
      <SidebarContainer>
        <ServiceList
          onSelectService={handleSelectService}
          selectedService={selectedService}
        />
      </SidebarContainer>

      <MainContentContainer>
        {selectedService && (
          <ServiceDetailsContainer>
            <ServiceDetails
              selectedService={selectedService}
              onSave={handleSaveService}
              onCancel={() => setSelectedService(null)}
            />
          </ServiceDetailsContainer>
        )}

        {selectedService && (
          <SubServiceContainer>
            <SubServiceList
              serviceId={selectedService._id}
              onSelectSubService={handleSelectSubService}
              refreshTrigger={refreshTrigger}
            />

            {selectedSubService ? (
              <SubServiceDetails
                subServiceId={selectedSubService._id}
                serviceId={selectedService._id}
                onUpdateSubService={handleUpdateSubService}
                onDeleteSubService={handleDeleteSubService}
              />
            ) : (
              <AddSubService
                serviceId={selectedService._id}
                onSubServiceAdded={handleSubServiceAdded}
              />
            )}
          </SubServiceContainer>
        )}
      </MainContentContainer>
    </DashboardContainer>
  );
}

export default ServiceDashboard;
