import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axiosCustomerInstance from '../../utils/axiosCustomer';
import { Box, Typography, Button, List, ListItem, ListItemText, Divider, Checkbox, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: '#1C1C1C',
  color: '#C0C0C0',
  minHeight: '100vh',
  padding: '2rem',
}));

const Title = styled(Typography)({
  color: '#39FF14',
  marginBottom: '1.5rem',
  textAlign: 'center',
  fontWeight: 'bold',
});

const StyledLink = styled(Link)({
  color: '#00FFFF',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
});

const AddButton = styled(Button)({
  backgroundColor: '#39FF14',
  color: '#1C1C1C',
  marginBottom: '1rem',
  '&:hover': {
    backgroundColor: '#00FF00',
  },
});

function CustomerList() {
  const [customers, setCustomers] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axiosCustomerInstance.get('/customers');
        setCustomers(response.data);
      } catch (error) {
        console.error('Error fetching customers:', error.message);
      }
    };

    fetchCustomers();
  }, []);

  const handleSelectAll = (event) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    setSelectedCustomers(checked ? customers.map((customer) => customer._id) : []);
  };

  const handleSelectCustomer = (customerId) => {
    setSelectedCustomers((prevSelected) =>
      prevSelected.includes(customerId)
        ? prevSelected.filter((id) => id !== customerId)
        : [...prevSelected, customerId]
    );
  };

  const handleDeleteSelected = async () => {
    try {
      await axiosCustomerInstance.delete('/customers/batch-delete', {
        data: { customerIds: selectedCustomers },
      });
      setCustomers((prevCustomers) =>
        prevCustomers.filter((customer) => !selectedCustomers.includes(customer._id))
      );
      setSelectedCustomers([]);
      setSelectAll(false);
    } catch (error) {
      console.error('Error deleting customers:', error.message);
    }
  };

  return (
    <Container>
      <Title variant="h4">Customer List</Title>
      <StyledLink to="/customers/add">
        <AddButton variant="contained">Add New Customer</AddButton>
      </StyledLink>
      {selectedCustomers.length > 0 && (
        <Button
          variant="contained"
          color="error"
          startIcon={<DeleteIcon />}
          onClick={handleDeleteSelected}
          sx={{ mb: 2 }}
        >
          Delete Selected
        </Button>
      )}
      <Checkbox
        checked={selectAll}
        onChange={handleSelectAll}
        color="primary"
        sx={{ color: '#39FF14' }}
      />{' '}
      Select All
      {customers.length === 0 ? (
        <Typography variant="body1" sx={{ color: '#C0C0C0' }}>
          No customers found.
        </Typography>
      ) : (
        <List sx={{ backgroundColor: '#2F2F2F', borderRadius: '8px', padding: '1rem' }}>
          {customers.map((customer) => (
            <React.Fragment key={customer._id}>
              <ListItem sx={{ display: 'flex', justifyContent: 'space-between', color: '#C0C0C0' }}>
                <Checkbox
                  checked={selectedCustomers.includes(customer._id)}
                  onChange={() => handleSelectCustomer(customer._id)}
                  color="primary"
                  sx={{ color: '#39FF14' }}
                />
                <ListItemText
                  primary={`${customer.firstName} ${customer.lastName}`}
                  secondary={customer.email}
                  sx={{ color: '#C0C0C0' }}
                />
                <Box>
                  <StyledLink to={`/customers/${customer._id}`}>
                    <Button
                      size="small"
                      variant="outlined"
                      sx={{ marginRight: '0.5rem', borderColor: '#39FF14', color: '#39FF14' }}
                    >
                      View
                    </Button>
                  </StyledLink>
                  <StyledLink to={`/customers/${customer._id}/edit`}>
                    <Button
                      size="small"
                      variant="outlined"
                      sx={{ borderColor: '#00FFFF', color: '#00FFFF' }}
                    >
                      Edit
                    </Button>
                  </StyledLink>
                </Box>
              </ListItem>
              <Divider sx={{ backgroundColor: '#C0C0C0' }} />
            </React.Fragment>
          ))}
        </List>
      )}
    </Container>
  );
}

export default CustomerList;
