import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axios';
import OnboardingProgressBar from './OnboardingProgressBar';
import './OnboardingWizard.css'; // Ensure this matches your theme
import { jwtDecode } from 'jwt-decode';

const OnboardingStep2 = () => {
  const [businessHours, setBusinessHours] = useState({
    Monday: { open: '09:00', close: '17:00', isClosed: false },
    Tuesday: { open: '09:00', close: '17:00', isClosed: false },
    Wednesday: { open: '09:00', close: '17:00', isClosed: false },
    Thursday: { open: '09:00', close: '17:00', isClosed: false },
    Friday: { open: '09:00', close: '17:00', isClosed: false },
    Saturday: { open: '09:00', close: '17:00', isClosed: true },
    Sunday: { open: '09:00', close: '17:00', isClosed: true },
  });
  const [isSaving, setIsSaving] = useState(false);
  const [showExitDialog, setShowExitDialog] = useState(false);
  const [ownerId, setOwnerId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchSchedulerSettings();
    fetchOwnerId();
  }, []);

  const fetchOwnerId = () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setOwnerId(decodedToken.userId);
      } catch (err) {
// $&

      }
    }
  };

  const fetchSchedulerSettings = async () => {
    try {
      const response = await axiosInstance.get('/scheduler-settings');
      if (response.data && response.data.businessHours) {
        setBusinessHours(response.data.businessHours);
      }
    } catch (error) {
// $&

    }
  };

  const handleBusinessHoursChange = (day, field, value) => {
    setBusinessHours({
      ...businessHours,
      [day]: {
        ...businessHours[day],
        [field]: value,
      },
    });
  };

  const handleSaveAndNext = async () => {
    setIsSaving(true);
    try {
      // Save scheduler settings
      await axiosInstance.post('/scheduler-settings', { businessHours });

      // Update onboarding status
      await axiosInstance.put(`/onboarding-status/${ownerId}`, {
        currentStep: 3,
      });

      navigate('/onboarding/step3'); // Proceed to Step 3 after saving
    } catch (error) {
// $&

      alert('Failed to save business hours. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleExit = () => {
    setShowExitDialog(true);
  };

  const confirmExit = () => {
    setShowExitDialog(false);
    navigate('/dashboard'); // Redirect to the main dashboard
  };

  return (
    <Box sx={{ mt: 3, bgcolor: '#1C1C1C', p: 3, borderRadius: 2, color: '#C0C0C0' }}>
      <Typography variant="h6" sx={{ color: '#39FF14' }}>
        Onboarding Wizard: Step 2
      </Typography>
      <p>Configure your business hours and days of operation.</p>

      {/* Progress Bar */}
      <OnboardingProgressBar currentStep={2} totalSteps={9} />

      {Object.keys(businessHours).map((day) => (
        <Box key={day} sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
          <Tooltip title="Set opening time" arrow>
            <TextField
              label={`${day} Open`}
              type="time"
              value={businessHours[day].open}
              onChange={(e) => handleBusinessHoursChange(day, 'open', e.target.value)}
              disabled={businessHours[day].isClosed}
              InputLabelProps={{ style: { color: '#C0C0C0' } }}
              InputProps={{ style: { color: '#00FFFF' } }}
            />
          </Tooltip>
          <Tooltip title="Set closing time" arrow>
            <TextField
              label={`${day} Close`}
              type="time"
              value={businessHours[day].close}
              onChange={(e) => handleBusinessHoursChange(day, 'close', e.target.value)}
              disabled={businessHours[day].isClosed}
              InputLabelProps={{ style: { color: '#C0C0C0' } }}
              InputProps={{ style: { color: '#00FFFF' } }}
            />
          </Tooltip>
          <Button
            variant="outlined"
            onClick={() => handleBusinessHoursChange(day, 'isClosed', !businessHours[day].isClosed)}
            sx={{
              color: businessHours[day].isClosed ? '#FF6347' : '#39FF14',
              borderColor: businessHours[day].isClosed ? '#FF6347' : '#39FF14',
            }}
          >
            {businessHours[day].isClosed ? 'Closed' : 'Open'}
          </Button>
        </Box>
      ))}
      <Box sx={{ mt: 3 }}>
        <Button
          variant="outlined"
          sx={{ color: '#00FFFF', borderColor: '#00FFFF', mr: 2 }}
          onClick={() => navigate('/onboarding/step1')}
        >
          Back
        </Button>
        <Button
          variant="contained"
          sx={{ bgcolor: '#39FF14', color: '#1C1C1C', '&:hover': { bgcolor: '#00FFFF' } }}
          onClick={handleSaveAndNext}
          disabled={isSaving} // Disable button while saving
        >
          {isSaving ? 'Saving...' : 'Save and Next'}
        </Button>
        <Button
          variant="contained"
          sx={{ ml: 2, bgcolor: '#FF6347', color: '#1C1C1C', '&:hover': { bgcolor: '#FF4500' } }}
          onClick={handleExit}
        >
          Exit Onboarding
        </Button>
      </Box>
      {showExitDialog && (
        <div className="dialog">
          <p>
            Are you sure you want to exit? The app may not function correctly until onboarding is complete.
          </p>
          <Button onClick={confirmExit} className="dialog-button">
            Confirm Exit
          </Button>
          <Button onClick={() => setShowExitDialog(false)} className="dialog-button">
            Cancel
          </Button>
        </div>
      )}
    </Box>
  );
};

export default OnboardingStep2;
