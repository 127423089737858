import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../utils/axios';
import AdminServiceList from './AdminServiceList';
import AdminServiceDetails from './AdminServiceDetails';
import AdminSubServiceManagement from '../SubServices/AdminSubServiceManagement';
import AdminBrandManagement from '../Brands/AdminBrandManagement';
import AdminSubBrandManagement from '../SubBrands/AdminSubBrandManagement'; // Import the SubBrand Management component
import { Box, Typography, Button } from '@mui/material';
import './AdminServiceManagement.css'; // Import the CSS file

const AdminServiceManagement = () => {
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [activeComponent, setActiveComponent] = useState('sub-services'); // Track active component
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get('/admin/services');
      setServices(response.data);
    } catch (err) {
      setError('Failed to fetch services. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleServiceClick = (service) => {
    setSelectedService(service);
  };

  const handleServiceCreate = async (newService) => {
    try {
      const response = await axiosInstance.post('/admin/services', newService);
      setServices([...services, response.data]);
      setSelectedService(response.data);
    } catch (err) {
      setError('Failed to create service. Please try again.');
    }
  };

  const handleServiceUpdate = async (updatedService) => {
    try {
      const response = await axiosInstance.put(`/admin/services/${updatedService._id}`, updatedService);
      setServices(
        services.map((service) =>
          service._id === updatedService._id ? response.data : service
        )
      );
      setSelectedService(null);
    } catch (err) {
      setError('Failed to update service. Please try again.');
    }
  };

  const handleServiceDelete = async (serviceId) => {
    try {
      await axiosInstance.delete(`/admin/services/${serviceId}`);
      setServices(services.filter((service) => service._id !== serviceId));
      setSelectedService(null);
    } catch (err) {
      setError('Failed to delete service. Please try again.');
    }
  };

  const resetSelectedService = () => {
    setSelectedService(null);
  };

  return (
    <Box className="admin-service-management">
      <Typography variant="h4" gutterBottom>
        Admin: Manage Services
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      <Box className="button-group" sx={{ marginBottom: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setSelectedService({})} // Empty object indicates creating a new service
        >
          Add New Service
        </Button>
        <Button
          variant="contained"
          color={activeComponent === 'sub-services' ? 'secondary' : 'primary'}
          onClick={() => {
            setActiveComponent('sub-services');
            setSelectedService(null);
          }}
        >
          Manage Sub-Services
        </Button>
        <Button
          variant="contained"
          color={activeComponent === 'brands' ? 'secondary' : 'primary'}
          onClick={() => {
            setActiveComponent('brands');
            setSelectedService(null);
          }}
        >
          Manage Brands
        </Button>
        <Button
          variant="contained"
          color={activeComponent === 'sub-brands' ? 'secondary' : 'primary'}
          onClick={() => {
            setActiveComponent('sub-brands');
            setSelectedService(null);
          }}
        >
          Manage Sub-Brands
        </Button>
      </Box>
      <Box display="flex">
        <Box flex={1} className="service-list">
          <AdminServiceList
            services={services}
            onServiceClick={handleServiceClick}
            onDeleteService={handleServiceDelete}
            isLoading={isLoading}
          />
        </Box>
        {selectedService && (
          <Box flex={1} className="service-details">
            <AdminServiceDetails
              service={selectedService}
              onSave={selectedService._id ? handleServiceUpdate : handleServiceCreate}
              onCancel={resetSelectedService}
            />
          </Box>
        )}
        {!selectedService && activeComponent === 'sub-services' && (
          <Box flex={1} className="sub-service-management">
            <AdminSubServiceManagement />
          </Box>
        )}
        {!selectedService && activeComponent === 'brands' && (
          <Box flex={1} className="brand-management">
            <AdminBrandManagement />
          </Box>
        )}
        {!selectedService && activeComponent === 'sub-brands' && (
          <Box flex={1} className="sub-brand-management">
            <AdminSubBrandManagement />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AdminServiceManagement;
