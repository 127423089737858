// src/components/Profile/EditProfile.jsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

function EditProfile() {
  const [initialValues, setInitialValues] = useState(null);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get('http://localhost:5000/api/profile', {
          headers: { Authorization: `Bearer ${token}` },
        });
        const userData = response.data;
        setInitialValues({
          firstName: userData.profile.firstName || '',
          lastName: userData.profile.lastName || '',
          // Add other fields as needed
        });
      } catch (err) {
// $&

        setError('Failed to load profile data.');
      }
    };

    fetchProfile();
  }, [token]);

  const validationSchema = Yup.object({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    // Add validations for other fields
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await axios.put(
        'http://localhost:5000/api/profile',
        {
          profile: {
            firstName: values.firstName,
            lastName: values.lastName,
            // Add other fields
          },
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      alert('Profile updated successfully.');
      navigate('/dashboard');
    } catch (err) {
// $&

      setError('Failed to update profile.');
    } finally {
      setSubmitting(false);
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!initialValues) {
    return <div>Loading profile...</div>;
  }

  return (
    <div>
      <h1>Edit Profile</h1>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <div>
              <label>First Name</label>
              <Field name="firstName" type="text" />
              <ErrorMessage name="firstName" component="div" />
            </div>
            <div>
              <label>Last Name</label>
              <Field name="lastName" type="text" />
              <ErrorMessage name="lastName" component="div" />
            </div>
            {/* Add other fields as needed */}
            <button type="submit" disabled={isSubmitting}>
              Save Changes
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EditProfile;
