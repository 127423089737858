// axiosCustomer.js
import axios from 'axios';

const axiosCustomerInstance = axios.create({
  baseURL: process.env.REACT_APP_CUSTOMER_SERVICE_URL, // Base URL for Customer Service
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosCustomerInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

export default axiosCustomerInstance;
