import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BayManagement from '../Bays/BayManagement';
import OnboardingProgressBar from './OnboardingProgressBar';
import axiosInstance from '../../utils/axios';
import './OnboardingWizard.css';
import { jwtDecode } from 'jwt-decode';

const OnboardingStep4 = () => {
  const [isCompleted, setIsCompleted] = useState(false);
  const [showExitDialog, setShowExitDialog] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [ownerId, setOwnerId] = useState(null);
  const [bays, setBays] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchOwnerId();
    fetchBays();
  }, []);

  const fetchOwnerId = () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setOwnerId(decodedToken.userId);
      } catch (err) {
// $&

      }
    }
  };

  const fetchBays = async () => {
    try {
      const response = await axiosInstance.get('/bays');
      setBays(response.data);
      setIsCompleted(response.data.length > 0); // Validate if at least one bay exists
    } catch (error) {
// $&

    }
  };

  const handleSaveAndNext = async () => {
    if (!isCompleted) {
      alert('Please set up at least one bay before proceeding.');
      return;
    }

    setIsSaving(true);
    try {
      // Update onboarding status, including bay details
      await axiosInstance.put(`/onboarding-status/${ownerId}`, {
        currentStep: 5,
        bays: bays.map((bay) => bay._id), // Send bay IDs to onboarding status
      });

      navigate('/onboarding/step5'); // Navigate to Step 5
    } catch (error) {
// $&

      alert('Failed to update onboarding status. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleSaveCompletion = () => {
    setIsCompleted(true); // Mark the step as completed
    fetchBays(); // Refresh bays to ensure the latest data is saved
  };

  const handleExit = () => {
    setShowExitDialog(true);
  };

  const confirmExit = () => {
    setShowExitDialog(false);
    navigate('/dashboard'); // Redirect to the main dashboard
  };

  return (
    <div className="onboarding-step">
      <h1>Onboarding Wizard: Step 4</h1>
      <p>Set up your bays to get started with scheduling.</p>

      {/* Progress Bar */}
      <OnboardingProgressBar currentStep={4} totalSteps={9} />

      {/* Integrate BayManagement Component */}
      <BayManagement onSave={handleSaveCompletion} />

      <div className="onboarding-actions">
        <button
          onClick={() => navigate('/onboarding/step3')}
          className="wizard-button"
        >
          Back
        </button>
        <button
          onClick={handleSaveAndNext}
          disabled={!isCompleted || isSaving} // Disable button until at least one bay is set up
          className={`wizard-button ${isCompleted ? '' : 'disabled'}`}
        >
          {isSaving ? 'Saving...' : 'Save and Next'}
        </button>
        <button onClick={handleExit} className="wizard-button">
          Exit Onboarding
        </button>
      </div>

      {showExitDialog && (
        <div className="dialog">
          <p>
            Are you sure you want to exit? The app may not function correctly until onboarding is complete.
          </p>
          <button onClick={confirmExit} className="dialog-button">
            Confirm Exit
          </button>
          <button onClick={() => setShowExitDialog(false)} className="dialog-button">
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};

export default OnboardingStep4;
