import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Button,
  Breadcrumbs,
  Link,
} from '@mui/material';
import axiosInstance from '../../../utils/axios';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../../../styles/CalendarCustom.css';

const localizer = momentLocalizer(moment);


function DateAndTimeSelection({ ownerId, setOwnerId,estimatedDuration, serviceId, onSelectionComplete, onBack }) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [availableMultiDayDates, setAvailableMultiDayDates] = useState([]);
  const [availableMultiDayAppointments, setAvailableMultiDayAppointments] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [loadingDates, setLoadingDates] = useState(true);
  const [loadingSlots, setLoadingSlots] = useState(false);
  const [loadingMultiDayAppointments, setLoadingMultiDayAppointments] = useState(false);
  const [schedulerSettings, setSchedulerSettings] = useState(null);
  const [closedDays, setClosedDays] = useState([]);

  useEffect(() => {
    if (serviceId) {
      fetchSchedulerSettings();
      if (estimatedDuration > 480) {
        fetchAvailableMultiDayDates();
      } else {
        fetchAvailableDates();
      }
    }
  }, [estimatedDuration, serviceId]);

  const fetchSchedulerSettings = async () => {
    try {
      const response = await axiosInstance.get(`/scheduler-settings`, {
        params: { ownerId, serviceId },
      });
  
      if (response.data.ownerId) {
        setOwnerId(response.data.ownerId); // Update ownerId using the prop method
// $&

      }
  
      const schedulerSettings = response.data;
      setSchedulerSettings(schedulerSettings);
  
      // Extract closed days from business hours
      const closedDays = Object.entries(schedulerSettings.businessHours)
        .filter(([day, details]) => details.isClosed) // Filter days marked as closed
        .map(([day]) => day); // Map to the names of closed days
  
      setClosedDays(closedDays); // Update the state with closed days
    } catch (error) {
// $&

    }
  };

  const sanitizedDates = availableMultiDayDates.map((date) => {
    const dayName = moment(date).format('dddd');
    const isClosed = closedDays.includes(dayName);
  
    return {
      title: isClosed ? 'Closed' : 'Available',
      start: moment(date).startOf('day').toDate(),
      end: moment(date).endOf('day').toDate(),
      allDay: true,
      isClosed, // Custom property to identify closed days
    };
  });
  
// $&


  const fetchAvailableDates = async () => {
    try {
      setLoadingDates(true); // Set loading state
      const response = await axiosInstance.get('/scheduler/available-dates', {
        params: { ownerId, duration: estimatedDuration, serviceId },
      });
  
      setAvailableDates(response.data); // Update state with available dates
// $&

    } catch (error) {
// $&

    } finally {
      setLoadingDates(false); // Reset loading state
    }
  };

  const fetchAvailableMultiDayDates = async () => {
    try {
      setLoadingDates(true); // Set loading state
      const response = await axiosInstance.get('/scheduler/available-multi-day-dates', {
        params: { ownerId, duration: estimatedDuration, serviceId },
      });
  
      setAvailableMultiDayDates(response.data); // Update state with multi-day dates
// $&

    } catch (error) {
// $&

    } finally {
      setLoadingDates(false); // Reset loading state
    }
  };

  const fetchAvailableSlots = async (date) => {
    try {
      setLoadingSlots(true); // Set loading state
      const formattedDate = moment(date).toISOString(); // Format date for API
      const response = await axiosInstance.get('/scheduler/available-slots', {
        params: { ownerId, date: formattedDate, duration: estimatedDuration, serviceId },
      });
  
      // Filter unique slots based on `startTime` and `bayName`
      const uniqueSlots = response.data.filter(
        (slot, index, self) =>
          index === self.findIndex((s) => s.startTime === slot.startTime && s.bayName === slot.bayName)
      );
  
      setAvailableSlots(uniqueSlots); // Update state with unique slots
// $&

    } catch (error) {
// $&

    } finally {
      setLoadingSlots(false); // Reset loading state
    }
  };

  const fetchAvailableMultiDayAppointments = async (selectedDate) => {
    try {
      setLoadingMultiDayAppointments(true);
      const formattedStartDate = moment(selectedDate).startOf('day').toISOString();
      const response = await axiosInstance.get('/scheduler/available-multi-day-slots', {
        params: { ownerId, duration: estimatedDuration, serviceId, startDate: formattedStartDate },
      });
  
// $&

  
      // Properly format slots with bay and employee information
      const formattedSlots = response.data.map((slot) => ({
        startTime: moment(slot.startTime).toDate(),
        endTime: moment(slot.endTime).toDate(),
        bayName: slot.bayName || 'Unnamed Bay',
        bayId: slot.bayId,
        employeeId: slot.employeeId, // Add employeeId
      }));
  
      setAvailableMultiDayAppointments(formattedSlots);
      setLoadingMultiDayAppointments(false);
    } catch (error) {
// $&

      setLoadingMultiDayAppointments(false);
    }
  };
  
  

  const handleDateSelection = (date) => {
    setSelectedDate(date);
    setAvailableSlots([]);
    setSelectedTimeSlot(null);
    fetchAvailableSlots(date);
  };

  const handleMultiDayDateSelection = (date) => {
    const dayName = moment(date).format('dddd');
    if (closedDays.includes(dayName)) {
      console.warn(`Date ${date} is closed and cannot be selected.`);
      return; // Prevent selection
    }
    setSelectedDate(date);
    setAvailableMultiDayAppointments([]);
    setSelectedTimeSlot(null);
    fetchAvailableMultiDayAppointments(date);
  };
  

  const handleTimeSlotSelection = (slot) => {
    setSelectedTimeSlot(slot);
  };
  
  const handleConfirmSelection = () => {
    if (selectedTimeSlot) {
      onSelectionComplete({
        selectedTimeSlot: {
          startTime: selectedTimeSlot.startTime,
          endTime: selectedTimeSlot.endTime,
          bayId: selectedTimeSlot.bayId,
          bayName: selectedTimeSlot.bayName,
          employeeId: selectedTimeSlot.employeeId, // Include employeeId
        },
      });
    }
  };

  return (
    <Box sx={{ p: 4, backgroundColor: '#1C1C1C', color: '#C0C0C0' }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ color: '#00FFFF', mb: 3 }}>
        <Link color="inherit" onClick={onBack} sx={{ cursor: 'pointer', color: '#00FFFF' }}>
          Back to Service Selection
        </Link>
        <Typography color="textPrimary">Step 2: Date and Time Selection</Typography>
      </Breadcrumbs>

      <Typography variant="h4" gutterBottom sx={{ color: '#39FF14' }}>
        Date and Time Selection
      </Typography>

      {estimatedDuration > 480 ? (
        <>
          <Typography variant="h6" sx={{ color: '#00FFFF' }}>
            Select an Available Multi-Day Start Date
          </Typography>
          {loadingDates ? (
            <Typography sx={{ mt: 2 }}>Loading available dates...</Typography>
          ) : (
            <Calendar
  localizer={localizer}
  events={sanitizedDates} // Use combined sanitized dates
  startAccessor="start"
  endAccessor="end"
  className="custom-calendar" // Add your custom class
  views={['month']}
  onSelectEvent={(event) => {
    if (!event.isClosed) {
      handleMultiDayDateSelection(event.start);
    }
  }}
  eventPropGetter={(event) => {
// $&

    if (event.isClosed) {
      return {
        className: 'rbc-day-bg-closed', // Add classes instead of inline styles
      };
    }
    return {
      className: 'rbc-day-bg-available', // Add classes instead of inline styles
    };
  }}
/>
          )}

          {/* Multi-Day Appointment Selection */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" sx={{ color: '#00FFFF' }}>
              Select an Available Start Time for {moment(selectedDate).format('MMMM Do, YYYY')}
            </Typography>
            {loadingMultiDayAppointments ? (
              <Typography sx={{ mt: 2 }}>Loading available time slots...</Typography>
            ) : (
              <Box sx={{ mt: 2 }}>
                {availableMultiDayAppointments.length > 0 ? (
                  Object.entries(
                    availableMultiDayAppointments.reduce((groupedSlots, slot) => {
                      const groupKey = `${slot.bayName || 'Unknown Bay'}`;
                      if (!groupedSlots[groupKey]) {
                        groupedSlots[groupKey] = [];
                      }
                      groupedSlots[groupKey].push(slot);
                      return groupedSlots;
                    }, {})
                  ).map(([groupKey, slots]) => (
                    <Box key={groupKey} sx={{ mb: 3 }}>
                      <Typography variant="h6" sx={{ color: '#39FF14', mb: 1 }}>
                        {groupKey}
                      </Typography>
                      {slots.map((slot, index) => (
                        <Button
                          key={index}
                          variant={selectedTimeSlot?.startTime === slot.startTime ? 'contained' : 'outlined'}
                          sx={{
                            mt: 1,
                            mr: 1,
                            color:
                              selectedTimeSlot?.startTime === slot.startTime ? '#1C1C1C' : '#39FF14',
                            backgroundColor:
                              selectedTimeSlot?.startTime === slot.startTime ? '#39FF14' : '#333',
                          }}
                          onClick={() => handleTimeSlotSelection(slot)}
                        >
                          {moment(slot.startTime).format('h:mm A')}
                        </Button>
                      ))}
                    </Box>
                  ))
                ) : (
                  <Typography sx={{ color: '#C0C0C0' }}>No available slots for the selected date.</Typography>
                )}
              </Box>
          )}
        </Box>
        </>
      ) : (
        // Single-Day Appointment Selection
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" sx={{ color: '#00FFFF' }}>
            Select an Available Date
          </Typography>
          {loadingDates ? (
            <Typography sx={{ mt: 2 }}>Loading available dates...</Typography>
          ) : (
            <Calendar
              localizer={localizer}
              events={availableDates.map((date) => ({
                title: 'Available',
                start: new Date(date),
                end: new Date(date),
                allDay: true,
              }))}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 500, margin: '20px 0', backgroundColor: '#333', color: '#C0C0C0' }}
              views={['month']}
              onSelectEvent={(event) => handleDateSelection(event.start)}
              eventPropGetter={(event) => ({
                style: {
                  backgroundColor: '#39FF14',
                  color: '#1C1C1C',
                  borderRadius: '5px',
                  border: 'none',
                },
              })}
            />
          )}

          {selectedDate && (
            <Box sx={{ mt: 4 }}>
              <Typography variant="h6" sx={{ color: '#00FFFF' }}>
                Select an Available Start Time on {moment(selectedDate).format('MMMM Do, YYYY')}
              </Typography>
              {loadingSlots ? (
                <Typography sx={{ mt: 2 }}>Loading available time slots...</Typography>
              ) : (
                <Box sx={{ mt: 2 }}>
                  {availableSlots.length > 0 ? (
                    Object.entries(
                      availableSlots
                        .filter((slot) => moment(slot.startTime).isSame(selectedDate, 'day')) // Filter slots for the selected day
                        .reduce((groupedSlots, slot) => {
                          const { bayName } = slot;
                          if (!groupedSlots[bayName]) {
                            groupedSlots[bayName] = [];
                          }
                          groupedSlots[bayName].push(slot);
                          return groupedSlots;
                        }, {})
                    ).map(([bayName, slots]) => (
                      <Box key={bayName} sx={{ mb: 3 }}>
                        <Typography variant="h6" sx={{ color: '#39FF14', mb: 1 }}>
                          Bay: {bayName || 'Unknown Bay'}
                        </Typography>
                        {slots.map((slot, index) => (
                          <Button
                            key={index}
                            variant={
                              selectedTimeSlot?.startTime === slot.startTime &&
                              selectedTimeSlot?.bayName === bayName
                                ? 'contained'
                                : 'outlined'
                            }
                            sx={{
                              mt: 1,
                              mr: 1,
                              color:
                                selectedTimeSlot?.startTime === slot.startTime &&
                                selectedTimeSlot?.bayName === bayName
                                  ? '#1C1C1C'
                                  : '#39FF14',
                              backgroundColor:
                                selectedTimeSlot?.startTime === slot.startTime &&
                                selectedTimeSlot?.bayName === bayName
                                  ? '#39FF14'
                                  : '#333',
                            }}
                            onClick={() => handleTimeSlotSelection(slot)}
                          >
                            {`${moment(slot.startTime).format('h:mm A')}`}
                          </Button>
                        ))}
                      </Box>
                    ))
                  ) : (
                    <Typography sx={{ color: '#C0C0C0' }}>
                      No available slots for the selected date.
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}

      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 4 }}
        onClick={handleConfirmSelection}
        disabled={!selectedTimeSlot}
      >
        Confirm Selections
      </Button>
      {selectedTimeSlot && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6" sx={{ color: '#00FFFF' }}>
            Appointment Start Time: {moment(selectedTimeSlot.startTime).format('MMMM Do, YYYY, h:mm A')}
          </Typography>
          <Typography variant="h6" sx={{ color: '#00FFFF' }}>
            Appointment End Time: {moment(selectedTimeSlot.endTime).format('MMMM Do, YYYY, h:mm A')}
          </Typography>
          <Typography variant="h6" sx={{ color: '#00FFFF' }}>
            {selectedTimeSlot.bayName}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default DateAndTimeSelection;
