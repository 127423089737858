import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import EmployeeList from '../Employees/EmployeeList';
import NewEmployeeForm from '../Employees/NewEmployeeForm';
import OnboardingProgressBar from './OnboardingProgressBar';
import './OnboardingWizard.css'; // Ensure this matches the high-tech theme
import axiosInstance from '../../utils/axios';
import { jwtDecode } from 'jwt-decode';

const OnboardingStep3 = () => {
  const [isCompleted, setIsCompleted] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [showExitDialog, setShowExitDialog] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [ownerId, setOwnerId] = useState(null);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchOwnerId();
    fetchEmployees();
  }, []);

  const fetchOwnerId = () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setOwnerId(decodedToken.userId);
      } catch (err) {
// $&

        setError('Failed to decode session token.');
      }
    }
  };

  const fetchEmployees = async () => {
    try {
      const response = await axiosInstance.get('/employees');
      setEmployees(response.data);
      setIsCompleted(response.data.length > 0); // Mark completed if employees exist
    } catch (error) {
// $&

      setError('Failed to fetch employees.');
    }
  };

  const handleSaveAndNext = async () => {
    if (!isCompleted) {
      alert('Please add at least one employee before proceeding.');
      return;
    }

    setIsSaving(true);
    try {
      // Update onboarding status with employee list
      await axiosInstance.put(`/onboarding-status/${ownerId}`, {
        currentStep: 4, // Proceed to the next step
        employees: employees.map((employee) => employee._id), // Include employee IDs
      });

      navigate('/onboarding/step4'); // Navigate to Step 4
    } catch (error) {
// $&

      alert('Failed to update onboarding status. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleExit = () => {
    setShowExitDialog(true);
  };

  const confirmExit = () => {
    setShowExitDialog(false);
    navigate('/dashboard'); // Redirect to the main dashboard
  };

  const handleEmployeeAdded = () => {
    fetchEmployees(); // Refresh the employee list after adding
  };

  return (
    <div className="onboarding-step">
      <h1>Onboarding Wizard: Step 3</h1>
      <p>Add and manage your employees to ensure smooth operations.</p>

      {/* Progress Bar */}
      <OnboardingProgressBar currentStep={3} totalSteps={9} />

      {error && <p className="error-message">{error}</p>}

      <div className="employee-section">
        <NewEmployeeForm onEmployeeAdded={handleEmployeeAdded} /> {/* Add Employee Form */}
        <EmployeeList employees={employees} onEmployeesUpdated={fetchEmployees} /> {/* Employee List */}
      </div>

      <div className="onboarding-actions">
        <button
          onClick={() => navigate('/onboarding/step2')}
          className="wizard-button"
        >
          Back
        </button>
        <button
          onClick={handleSaveAndNext}
          className="wizard-button"
          disabled={isSaving} // Disable button while saving
        >
          {isSaving ? 'Saving...' : 'Save and Next'}
        </button>
        <button onClick={handleExit} className="wizard-button">
          Exit Onboarding
        </button>
      </div>

      {showExitDialog && (
        <div className="dialog">
          <p>
            Are you sure you want to exit? The app may not function correctly until onboarding is complete.
          </p>
          <button onClick={confirmExit} className="dialog-button">
            Confirm Exit
          </button>
          <button onClick={() => setShowExitDialog(false)} className="dialog-button">
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};

export default OnboardingStep3;
