// src/components/Shared/PrivateRoute.jsx

import React from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

function PrivateRoute({ children, roles }) {
  const token = localStorage.getItem('token');

  if (!token) {
    console.warn('[PrivateRoute] No token found, redirecting to login.');
    return <Navigate to="/login" />;
  }

  try {
    const decodedToken = jwtDecode(token);
// $&


    const currentTime = Date.now() / 1000;

    if (decodedToken.exp < currentTime) {
      console.warn('[PrivateRoute] Token has expired, redirecting to login.');
      localStorage.removeItem('token');
      return <Navigate to="/login" />;
    }

    const userRole = decodedToken.accountLevelRole || decodedToken.appLevelRole;
    console.log('[PrivateRoute] User Role:', userRole);


    if (roles && !roles.includes(userRole)) {
      console.warn('[PrivateRoute] User does not have required role, redirecting to home.');
      return <Navigate to="/" />;
    }

    return children;
  } catch (error) {
// $&

    return <Navigate to="/login" />;
  }
}


export default PrivateRoute;
