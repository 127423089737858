import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import VehicleSearch from '../VehicleSearch/VehicleSearch'; // Import the VehicleSearch component

function EditVehicle() {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    make: '',
    model: '',
    year: '',
    vin: '',
    licensePlate: '',
    color: '',
    mileage: '',
    vif: '',
  });
  const [error, setError] = useState('');
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVehicle = async () => {
      try {
        const response = await axios.get(`http://localhost:5002/api/vehicles/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setFormData(response.data);
      } catch (error) {
// $&

      }
    };
    fetchVehicle();
  }, [id, token]);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Callback when VIF is found
  const handleVifFound = (vif) => {
    setFormData((prevData) => ({ ...prevData, vif }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        `http://localhost:5002/api/vehicles/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate('/vehicles');
    } catch (err) {
      setError(err.response?.data?.message || 'Error updating vehicle');
    }
  };

  return (
    <div>
      <h2>Edit Vehicle</h2>

      {/* Vehicle Search Component to find the VIF */}
      <VehicleSearch onVifFound={handleVifFound} />

      <form onSubmit={handleSubmit}>
        <div>
          <label>Make</label>
          <input type="text" name="make" value={formData.make} onChange={handleInputChange} required />
        </div>
        <div>
          <label>Model</label>
          <input type="text" name="model" value={formData.model} onChange={handleInputChange} required />
        </div>
        <div>
          <label>Year</label>
          <input type="number" name="year" value={formData.year} onChange={handleInputChange} required />
        </div>
        <div>
          <label>VIN</label>
          <input type="text" name="vin" value={formData.vin} onChange={handleInputChange} />
        </div>
        <div>
          <label>License Plate</label>
          <input type="text" name="licensePlate" value={formData.licensePlate} onChange={handleInputChange} />
        </div>
        <div>
          <label>Color</label>
          <input type="text" name="color" value={formData.color} onChange={handleInputChange} />
        </div>
        <div>
          <label>Mileage</label>
          <input type="number" name="mileage" value={formData.mileage} onChange={handleInputChange} />
        </div>

        {/* Display the VIF */}
        {formData.vif && <p>Vehicle VIF: {formData.vif}</p>}

        <button type="submit">Update Vehicle</button>
        {error && <p>{error}</p>}
      </form>
    </div>
  );
}

export default EditVehicle;
