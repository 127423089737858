import React, { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import ChooseServices from './ChooseServices';
import AssignBrandsToOwner from './AssignBrandsToOwner';
import AssignBrandsToServices from './AssignBrandsToServices';
import AddASubServiceOnboarding from './AddASubServiceOnboarding';
import OnboardingStep5Confirmation from './OnboardingStep5Confirmation';
import { Box, Typography, CircularProgress } from '@mui/material';
import axiosInstance from '../../utils/axios';
import './OnboardingWizard.css';

const OnboardingStep5 = ({ onComplete }) => {
  const [step, setStep] = useState(1); // Always start from step 1
  const [loading, setLoading] = useState(false); // Manage loading state
  const [error, setError] = useState('');
  const [ownerId, setOwnerId] = useState(null); // Extract ownerId from token

  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);

  // Extract ownerId from the token on mount
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError('Authentication token is missing.');
      return;
    }

    try {
      const decodedToken = jwtDecode(token);
      setOwnerId(decodedToken.userId);
    } catch (err) {
// $&

      setError('Invalid authentication token.');
    }
  }, []);

  // Update onboarding status on the backend
  const updateOnboardingStatus = async (currentStep) => {
    if (!ownerId) return;

    try {
      await axiosInstance.put(`/onboarding-status/${ownerId}`, {
        currentStep,
        services: selectedServices,
        brands: selectedBrands,
      });
    } catch (err) {
// $&

    }
  };

  const handleNextStep = async () => {
    const nextStep = step + 1;
    setStep(nextStep);
    await updateOnboardingStatus(nextStep);
  };

  const handlePreviousStep = async () => {
    const previousStep = step - 1;
    setStep(previousStep);
    await updateOnboardingStatus(previousStep);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box className="onboarding-step">
      <Typography variant="h4" gutterBottom>
        Step 5: Service Configuration
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      {step === 1 && (
        <ChooseServices
          ownerId={ownerId}
          onNext={(services) => {
            setSelectedServices(services);
            handleNextStep();
          }}
          onBack={handlePreviousStep} // Optional: Step 1 can omit this
        />
      )}
      {step === 2 && (
        <AssignBrandsToOwner
          ownerId={ownerId}
          onNext={handleNextStep}
          onBack={handlePreviousStep}
        />
      )}
      {step === 3 && (
        <AssignBrandsToServices
          ownerId={ownerId}
          services={selectedServices}
          onNext={(brands) => {
            setSelectedBrands(brands);
            handleNextStep();
          }}
          onBack={handlePreviousStep}
        />
      )}
      {step === 4 && (
        <AddASubServiceOnboarding
          ownerId={ownerId}
          onNext={handleNextStep}
          onBack={handlePreviousStep}
        />
      )}
      {step === 5 && (
        <OnboardingStep5Confirmation
          ownerId={ownerId}
          onComplete={onComplete}
          onBack={handlePreviousStep}
        />
      )}
    </Box>
  );
};

export default OnboardingStep5;
