import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosCustomerInstance from '../../utils/axiosCustomer'; // Import the configured Axios instance
import CustomerForm from './CustomerForm';

function EditCustomer() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        // Fetch customer details
        const response = await axiosCustomerInstance.get(`/customers/${id}`);
        setInitialValues(response.data);
      } catch (error) {
        console.error('Error fetching customer data:', error.message);
      }
    };

    fetchCustomer();
  }, [id]);

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      // Update customer details
      await axiosCustomerInstance.put(`/customers/${id}`, values);
      navigate(`/customers/${id}`);
    } catch (error) {
      console.error('Error updating customer:', error.message);
      setErrors({ submit: error.response?.data?.message || 'An error occurred' });
    } finally {
      setSubmitting(false);
    }
  };

  if (!initialValues) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Edit Customer</h1>
      <CustomerForm initialValues={initialValues} onSubmit={handleSubmit} />
    </div>
  );
}

export default EditCustomer;
