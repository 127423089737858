import React, { useEffect, useState } from 'react';
import axiosInstance from '../../../utils/axios';
import { Box, Tabs, Tab } from '@mui/material';

function SubServiceList({ serviceId, onSelectSubService, refreshTrigger }) {
  const [value, setValue] = useState(0);
  const [subServices, setSubServices] = useState([]);

  useEffect(() => {
    // Fetch the sub-services for this serviceId
    async function fetchSubServices() {
      try {
        const response = await axiosInstance.get(`/services/${serviceId}/sub-services`);
        setSubServices(response.data);
      } catch (error) {
// $&

      }
    }
    fetchSubServices();
  }, [serviceId, refreshTrigger]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue < subServices.length) {
      onSelectSubService(subServices[newValue]);
    } else {
      onSelectSubService(null);
    }
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="sub-service tabs"
      >
        {subServices.map((subService, index) => (
          <Tab key={subService._id} label={subService.name} />
        ))}
        <Tab label="Add Sub-Service" />
      </Tabs>
    </Box>
  );
}

export default SubServiceList;
