// src/components/SubscriptionPlans/SubscriptionPlans.jsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosAuth from '../../utils/axiosAuth'; // Update the import path to match your project structure

function SubscriptionPlans() {
  const [plans, setPlans] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch subscription plans from the backend
    axiosAuth
      .get('/subscription/plans') // Base URL is already configured in axiosAuth
      .then((response) => {
        setPlans(response.data);
      })
      .catch((error) => {
        console.error('Error fetching subscription plans:', error);
      });
  }, []);

  const handleChoosePlan = (planId) => {
    const isAuthenticated = !!localStorage.getItem('token');
    const state = { selectedPlanId: planId };
  
    if (isAuthenticated) {
      // User is logged in; navigate to subscription update flow
      navigate('/update-subscription', { state });
    } else {
      // User is not logged in; navigate to registration
      navigate('/register', { state });
    }
  };

  return (
    <div>
      <h1>Choose a Subscription Plan</h1>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        {plans.map((plan) => (
          <div key={plan._id} style={{ border: '1px solid #ccc', padding: '20px' }}>
            <h2>{plan.name}</h2>
            <p>Price: ${plan.price}</p>
            <p>Duration: {plan.duration}</p>
            <ul>
              {plan.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
            <button onClick={() => handleChoosePlan(plan._id)}>Choose Plan</button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SubscriptionPlans;
