import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axios';
import {
  ServiceListContainer,
  ServiceListHeader,
  StyledList,
  StyledListItem,
  StyledListItemText,
} from './ServiceListStyles';

function ServiceList({ onSelectService, selectedService }) {
  const [services, setServices] = useState([]);

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
      const response = await axiosInstance.get('/services');
      setServices(response.data);
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  };

  return (
    <ServiceListContainer elevation={3}>
      <ServiceListHeader variant="h4">Services</ServiceListHeader>
      <StyledList>
        {services.map((service) => (
          <StyledListItem
            button
            key={service._id}
            onClick={() => onSelectService(service)}
            isSelected={selectedService && selectedService._id === service._id}
          >
            <StyledListItemText
              primary={service.name}
              isSelected={selectedService && selectedService._id === service._id}
            />
          </StyledListItem>
        ))}
      </StyledList>
    </ServiceListContainer>
  );
}

export default ServiceList;
