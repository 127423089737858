// BrandManagement.jsx

import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axios';
import SubBrandManagement from './SubBrandManagement';
import {
  Paper,
  Typography,
  TextField,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Box,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Add, Delete, Edit, ExpandLess, ExpandMore, Save, Cancel } from '@mui/icons-material';

function BrandManagement() {
  const [brands, setBrands] = useState([]);
  const [newBrand, setNewBrand] = useState('');
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [isEditingBrand, setIsEditingBrand] = useState(null);
  const [editBrandName, setEditBrandName] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [brandToDelete, setBrandToDelete] = useState(null);

  useEffect(() => {
    fetchBrands();
  }, []);

  const fetchBrands = async () => {
    try {
      const response = await axiosInstance.get('/brands');
      setBrands(response.data);
    } catch (error) {
// $&

    }
  };

  const handleAddBrand = async () => {
    if (!newBrand.trim()) return;
    try {
      const response = await axiosInstance.post('/brands', { name: newBrand.trim() });
      setBrands([...brands, response.data]);
      setNewBrand('');
    } catch (error) {
// $&

    }
  };

  const handleOpenDialog = (brand) => {
    setBrandToDelete(brand);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setBrandToDelete(null);
  };

  const confirmDeleteBrand = async () => {
    if (brandToDelete) {
      try {
        await axiosInstance.delete(`/brands/${brandToDelete._id}`);
        setBrands(brands.filter((brand) => brand._id !== brandToDelete._id));
        if (selectedBrand && selectedBrand._id === brandToDelete._id) {
          setSelectedBrand(null);
        }
        handleCloseDialog();
      } catch (error) {
// $&

        handleCloseDialog();
      }
    }
  };

  const handleEditBrand = (brand) => {
    setIsEditingBrand(brand._id);
    setEditBrandName(brand.name);
  };

  const handleUpdateBrand = async (brandId) => {
    if (!editBrandName.trim()) return;
    try {
      const response = await axiosInstance.put(`/brands/${brandId}`, { name: editBrandName.trim() });
      setBrands(
        brands.map((brand) => (brand._id === brandId ? { ...brand, name: response.data.name } : brand))
      );
      setIsEditingBrand(null);
      setEditBrandName('');
    } catch (error) {
// $&

    }
  };

  const handleSelectBrand = (brand) => {
    if (selectedBrand && selectedBrand._id === brand._id) {
      setSelectedBrand(null);
    } else {
      setSelectedBrand(brand);
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Brand Management
      </Typography>

      <Box display="flex" alignItems="center" mb={2}>
        <TextField
          label="New Brand Name"
          variant="outlined"
          value={newBrand}
          onChange={(e) => setNewBrand(e.target.value)}
          sx={{ mr: 2, flexGrow: 1 }}
        />
        <Button variant="contained" color="primary" onClick={handleAddBrand} startIcon={<Add />}>
          Add Brand
        </Button>
      </Box>

      {brands.length > 0 ? (
        <List>
          {brands.map((brand) => (
            <Box key={brand._id}>
              <ListItem
                button
                onClick={() => handleSelectBrand(brand)}
                sx={{ bgcolor: 'background.paper', mb: 1, borderRadius: 1 }}
              >
                {isEditingBrand === brand._id ? (
                  <>
                    <TextField
                      variant="outlined"
                      value={editBrandName}
                      onChange={(e) => setEditBrandName(e.target.value)}
                      sx={{ mr: 2 }}
                    />
                    <IconButton onClick={() => handleUpdateBrand(brand._id)} color="primary">
                      <Save />
                    </IconButton>
                    <IconButton onClick={() => setIsEditingBrand(null)} color="secondary">
                      <Cancel />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <ListItemText
                      primary={
                        <Typography variant="h6" color="text.primary">
                          {brand.name}
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton onClick={() => handleEditBrand(brand)} color="primary">
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => handleOpenDialog(brand)} color="secondary">
                        <Delete />
                      </IconButton>
                      <IconButton onClick={() => handleSelectBrand(brand)} edge="end">
                        {selectedBrand && selectedBrand._id === brand._id ? <ExpandLess /> : <ExpandMore />}
                      </IconButton>
                    </ListItemSecondaryAction>
                  </>
                )}
              </ListItem>

              <Collapse in={selectedBrand && selectedBrand._id === brand._id} timeout="auto" unmountOnExit>
                <Box sx={{ pl: 4 }}>
                  <SubBrandManagement brand={brand} onBrandUpdate={fetchBrands} />
                </Box>
              </Collapse>
            </Box>
          ))}
        </List>
      ) : (
        <Typography variant="body1">No brands available.</Typography>
      )}

      {/* Confirmation Dialog for Deleting Brand */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="delete-brand-dialog-title"
        aria-describedby="delete-brand-dialog-description"
      >
        <DialogTitle id="delete-brand-dialog-title">Delete Brand</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-brand-dialog-description">
            Are you sure you want to delete the brand{' '}
            <strong>{brandToDelete?.name}</strong>? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={confirmDeleteBrand} color="primary" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

export default BrandManagement;
