import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Box,
  Divider,
} from '@mui/material';
import axiosAppointmentInstance from '../../utils/axiosAppointment'; // Update the import path as needed
import axiosCustomerInstance from '../../utils/axiosCustomer';
import moment from 'moment';

function AppointmentCard({ appointment, open, handleClose, refreshAppointments }) {
  const [customerData, setCustomerData] = useState(null);
  const [startTime, setStartTime] = useState(appointment.startTime);
  const [endTime, setEndTime] = useState(appointment.endTime);
  const [notes, setNotes] = useState(appointment.notes);
  const [status, setStatus] = useState(appointment.status);
  const [progress, setProgress] = useState(appointment.progress);

  useEffect(() => {
    const fetchCustomerData = async () => {
      try {
        if (appointment.customer) {
          const response = await axiosCustomerInstance.get(`/customers/${appointment.customer}`);
          setCustomerData(response.data);
        }
      } catch (error) {
// $&

      }
    };

    fetchCustomerData();
  }, [appointment.customer]);

  const handleReschedule = async () => {
    try {
      await axiosAppointmentInstance.put(`/appointments/${appointment._id}`, {
        startTime,
        endTime,
        notes,
        progress,
      });
      refreshAppointments();
      handleClose();
    } catch (error) {
// $&

    }
  };

  const handleCancelOrActivate = async () => {
    try {
      if (status === 'Cancelled') {
        await axiosAppointmentInstance.put(`/appointments/${appointment._id}`, {
          status: 'Active',
        });
        setStatus('Active');
      } else {
        await axiosAppointmentInstance.put(`/appointments/${appointment._id}/cancel`);
        setStatus('Cancelled');
      }
      refreshAppointments();
      handleClose();
    } catch (error) {
// $&

    }
  };

  const handleDelete = async () => {
    try {
      await axiosAppointmentInstance.delete(`/appointments/${appointment._id}`);
      refreshAppointments();
      handleClose();
    } catch (error) {
// $&

    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Appointment Details</DialogTitle>
      <DialogContent sx={{ bgcolor: '#1C1C1C', color: '#C0C0C0' }}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" sx={{ color: '#00FFFF' }}>
            Customer: {customerData ? `${customerData.firstName} ${customerData.lastName}` : 'Loading...'}
          </Typography>
          <Typography variant="subtitle1" sx={{ color: '#00FFFF' }}>
            Vehicle: {appointment.vehicle?.make} {appointment.vehicle?.model}
          </Typography>
          <Divider sx={{ bgcolor: '#2C2C2C', my: 2 }} />
          
          <Typography variant="subtitle1" sx={{ color: '#39FF14' }}>
            Services:
          </Typography>
          {appointment.services.map((serviceItem, index) => (
            <Box key={index} sx={{ pl: 2, mb: 1 }}>
              <Typography variant="body2">Service: {serviceItem.service?.name}</Typography>
              <Typography variant="body2">Sub-Service: {serviceItem.subService?.name}</Typography>
              <Typography variant="body2">Brand: {serviceItem.subBrand?.name}</Typography>
            </Box>
          ))}

          <Divider sx={{ bgcolor: '#2C2C2C', my: 2 }} />

          <TextField
            label="Start Time"
            type="datetime-local"
            value={moment(startTime).format('YYYY-MM-DDTHH:mm')}
            onChange={(e) => setStartTime(e.target.value)}
            fullWidth
            sx={{ mt: 2, bgcolor: '#2C2C2C', color: '#C0C0C0' }}
          />
          <TextField
            label="End Time"
            type="datetime-local"
            value={moment(endTime).format('YYYY-MM-DDTHH:mm')}
            onChange={(e) => setEndTime(e.target.value)}
            fullWidth
            sx={{ mt: 2, bgcolor: '#2C2C2C', color: '#C0C0C0' }}
          />

          <TextField
            label="Notes"
            multiline
            rows={4}
            value={notes || ''}
            onChange={(e) => setNotes(e.target.value)}
            fullWidth
            sx={{ mt: 2, bgcolor: '#2C2C2C', color: '#C0C0C0' }}
          />

          <TextField
            label="Status"
            value={status}
            disabled
            fullWidth
            sx={{ mt: 2, bgcolor: '#2C2C2C', color: '#C0C0C0' }}
          />

          <TextField
            label="Progress"
            value={progress}
            onChange={(e) => setProgress(e.target.value)}
            select
            fullWidth
            SelectProps={{
              native: true,
            }}
            sx={{ mt: 2, bgcolor: '#2C2C2C', color: '#C0C0C0' }}
          >
            <option value="Not Started">Not Started</option>
            <option value="In Progress">In Progress</option>
            <option value="Completed">Completed</option>
          </TextField>

          <Divider sx={{ bgcolor: '#2C2C2C', my: 2 }} />

          <Typography variant="subtitle1" sx={{ color: '#00FFFF' }}>
            Assigned Bay: {appointment.bay?.bayName}
          </Typography>
          <Typography variant="subtitle1" sx={{ color: '#00FFFF' }}>
            Assigned Employee(s): {appointment.employee?.map(emp => `${emp.firstName} ${emp.lastName}`).join(', ')}
          </Typography>
        </Box>
      </DialogContent>
      
      <DialogActions sx={{ bgcolor: '#1C1C1C' }}>
        <Button onClick={handleCancelOrActivate} color={status === 'Cancelled' ? 'primary' : 'warning'}>
          {status === 'Cancelled' ? 'Activate Appointment' : 'Cancel Appointment'}
        </Button>
        <Button onClick={handleDelete} color="error">
          Delete Appointment
        </Button>
        <Button onClick={handleReschedule} color="primary">
          Save Changes
        </Button>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default AppointmentCard;
