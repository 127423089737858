import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {
  Root,
  FormContainer,
  FormField,
  PlanContainer,
  PaymentContainer,
  RegisterButton,
  GoogleButton,
} from './styles';

function Registration() {
  const location = useLocation();
  const navigate = useNavigate();
  const preSelectedPlanId = location.state?.selectedPlanId || '';

  const [plans, setPlans] = useState([]);
  const stripe = useStripe();
  const elements = useElements();

  const subscriptionPlansEndpoint = process.env.REACT_APP_SUBSCRIPTION_PLANS_ENDPOINT;
  const registerEndpoint = process.env.REACT_APP_AUTH_REGISTER_ENDPOINT;
  const googleRegisterEndpoint = process.env.REACT_APP_GOOGLE_REGISTER_ENDPOINT;
 

  useEffect(() => {
    axios
      .get(subscriptionPlansEndpoint)
      .then((response) => setPlans(response.data))
      .catch((error) => console.error('Error fetching subscription plans:', error.message));
  }, [subscriptionPlansEndpoint]);

  const initialValues = {
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    selectedPlanId: preSelectedPlanId,
    subdomain: '',
  };

  const validationSchema = Yup.object({
    username: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().required('Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords must match')
      .required('Required'),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    selectedPlanId: Yup.string().required('Please select a subscription plan'),
    subdomain: Yup.string()
      .matches(/^[a-z0-9-]+$/, 'Subdomain can only contain lowercase letters, numbers, and hyphens.')
      .required('Subdomain is required'),
  });

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      if (!stripe || !elements) {
        setErrors({ payment: 'Stripe.js is not loaded. Please try again later.' });
        setSubmitting(false);
        return;
      }
  
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        setErrors({ payment: 'Payment information is missing or invalid.' });
        setSubmitting(false);
        return;
      }
  
      // Create a payment method using Stripe
      const { error: paymentError, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
  
      if (paymentError) {
        setErrors({ payment: paymentError.message });
        setSubmitting(false);
        return;
      }
  
      // First, register the user
      const registerResponse = await axios.post(registerEndpoint, {
        username: values.username,
        email: values.email,
        password: values.password,
        profile: {
          firstName: values.firstName,
          lastName: values.lastName,
        },
        accountLevelRole: 'Owner',
        subscriptionPlan: values.selectedPlanId,
        subdomain: values.subdomain,
      });
  
      const { token, userId } = registerResponse.data;
      localStorage.setItem('token', token);
  
      // Second, handle the subscription using the .env endpoint
      await axios.post(
        process.env.REACT_APP_SUBSCRIBE_ENDPOINT,
        {
          subscriptionId: values.selectedPlanId,
          paymentMethodId: paymentMethod.id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      // Redirect to the dashboard
      navigate('/dashboard');
    } catch (error) {
      console.error('Error during registration and subscription:', error.response?.data || error.message);
      setErrors({
        submit: error.response?.data?.message || 'An error occurred during registration.',
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleGoogleRegister = () => {
    window.location.href = googleRegisterEndpoint;
  };

  return (
    <Root>
      <h1>Register</h1>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting, errors }) => (
          <Form>
            <FormContainer>
              <FormField>
                <label>Username</label>
                <Field name="username" type="text" />
                <ErrorMessage name="username" component="div" />
              </FormField>

              <FormField>
                <label>Email</label>
                <Field name="email" type="email" />
                <ErrorMessage name="email" component="div" />
              </FormField>

              <FormField>
                <label>Password</label>
                <Field name="password" type="password" />
                <ErrorMessage name="password" component="div" />
              </FormField>

              <FormField>
                <label>Confirm Password</label>
                <Field name="confirmPassword" type="password" />
                <ErrorMessage name="confirmPassword" component="div" />
              </FormField>

              <FormField>
                <label>First Name</label>
                <Field name="firstName" type="text" />
                <ErrorMessage name="firstName" component="div" />
              </FormField>

              <FormField>
                <label>Last Name</label>
                <Field name="lastName" type="text" />
                <ErrorMessage name="lastName" component="div" />
              </FormField>

              <FormField>
                <label>Subdomain</label>
                <Field name="subdomain" type="text" />
                <ErrorMessage name="subdomain" component="div" />
              </FormField>

              <PlanContainer>
                <label>Select Subscription Plan</label>
                <Field as="select" name="selectedPlanId">
                  <option value="">-- Select a Plan --</option>
                  {plans.map((plan) => (
                    <option key={plan._id} value={plan._id}>
                      {plan.name} - ${plan.price}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="selectedPlanId" component="div" />
              </PlanContainer>

              <PaymentContainer>
                <label>Payment Information</label>
                <CardElement />
                {errors.payment && <div>{errors.payment}</div>}
              </PaymentContainer>

              <RegisterButton type="submit" disabled={isSubmitting || !stripe || !elements}>
                Register
              </RegisterButton>
              {errors.submit && <div>{errors.submit}</div>}
            </FormContainer>
          </Form>
        )}
      </Formik>

      <GoogleButton onClick={handleGoogleRegister}>Register with Google</GoogleButton>
    </Root>
  );
}

export default Registration;
