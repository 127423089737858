import { styled } from '@mui/system';
import { Paper, List, ListItem, ListItemText, Typography } from '@mui/material';

export const ServiceListContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  margin: theme.spacing(2), // Separate from edges
  borderRadius: theme.shape.borderRadius * 2, // More rounded
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)', // Raised effect
  maxHeight: 'calc(100vh - 64px)', // Adjust height dynamically for viewport
  overflowY: 'auto', // Allow scrolling when necessary
  border: `1px solid ${theme.palette.divider}`, // Optional border for minimalist style
  scrollbarWidth: 'thin', // Firefox: thin scrollbar
  '&::-webkit-scrollbar': {
    width: '0', // Chrome/Safari: hidden scrollbar by default
  },
  '&:hover::-webkit-scrollbar': {
    width: '8px', // Show scrollbar on hover
  },
  '&:hover::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.action.hover,
    borderRadius: theme.shape.borderRadius,
  },
}));

export const ServiceListHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
  fontWeight: 'bold',
}));

export const StyledList = styled(List)(({ theme }) => ({
  padding: 0,
}));

export const StyledListItem = styled(ListItem)(({ theme, isSelected }) => ({
  backgroundColor: isSelected ? theme.palette.action.selected : 'inherit',
  borderRadius: theme.shape.borderRadius, // Rounded list items
  marginBottom: theme.spacing(1),
  transition: 'background-color 0.3s ease, color 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.text.primary,
  },
}));

export const StyledListItemText = styled(ListItemText)(({ theme, isSelected }) => ({
  color: isSelected ? theme.palette.primary.contrastText : theme.palette.text.secondary,
}));
