// SubBrandManagement.jsx

import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axios';
import PricingManagement from '../Pricing/PricingManagement';
import {
  Typography,
  TextField,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Box,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Add, Delete, Edit, ExpandLess, ExpandMore, Save, Cancel } from '@mui/icons-material';

function SubBrandManagement({ brand, onBrandUpdate }) {
  const [subBrands, setSubBrands] = useState([]);
  const [newSubBrand, setNewSubBrand] = useState('');
  const [selectedSubBrand, setSelectedSubBrand] = useState(null);
  const [isEditingSubBrand, setIsEditingSubBrand] = useState(null);
  const [editSubBrandName, setEditSubBrandName] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [subBrandToDelete, setSubBrandToDelete] = useState(null);

  useEffect(() => {
    fetchSubBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand]);

  const fetchSubBrands = async () => {
    try {
      const response = await axiosInstance.get(`/brands/${brand._id}/sub-brands`);
      setSubBrands(response.data);
    } catch (error) {
// $&

    }
  };

  const handleAddSubBrand = async () => {
    if (!newSubBrand.trim()) return;
    try {
      const response = await axiosInstance.post(`/brands/${brand._id}/sub-brands`, {
        name: newSubBrand.trim(),
      });
      setSubBrands([...subBrands, response.data]);
      setNewSubBrand('');
      onBrandUpdate();
    } catch (error) {
// $&

    }
  };

  const handleOpenDialog = (subBrand) => {
    setSubBrandToDelete(subBrand);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSubBrandToDelete(null);
  };

  const confirmDeleteSubBrand = async () => {
    if (subBrandToDelete) {
      try {
        await axiosInstance.delete(`/brands/${brand._id}/sub-brands/${subBrandToDelete._id}`);
        setSubBrands(subBrands.filter((subBrand) => subBrand._id !== subBrandToDelete._id));
        if (selectedSubBrand && selectedSubBrand._id === subBrandToDelete._id) {
          setSelectedSubBrand(null);
        }
        onBrandUpdate();
        handleCloseDialog();
      } catch (error) {
// $&

        handleCloseDialog();
      }
    }
  };

  const handleEditSubBrand = (subBrand) => {
    setIsEditingSubBrand(subBrand._id);
    setEditSubBrandName(subBrand.name);
  };

  const handleUpdateSubBrand = async (subBrandId) => {
    if (!editSubBrandName.trim()) return;
    try {
      const response = await axiosInstance.put(
        `/brands/${brand._id}/sub-brands/${subBrandId}`,
        { name: editSubBrandName.trim() }
      );
      setSubBrands(
        subBrands.map((subBrand) =>
          subBrand._id === subBrandId ? { ...subBrand, name: response.data.name } : subBrand
        )
      );
      setIsEditingSubBrand(null);
      setEditSubBrandName('');
      onBrandUpdate();
    } catch (error) {
// $&

    }
  };

  const handleSelectSubBrand = (subBrand) => {
    if (selectedSubBrand && selectedSubBrand._id === subBrand._id) {
      setSelectedSubBrand(null);
    } else {
      setSelectedSubBrand(subBrand);
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" gutterBottom>
        Sub-Brands for {brand.name}
      </Typography>

      <Box display="flex" alignItems="center" mb={2}>
        <TextField
          label="New Sub-Brand Name"
          variant="outlined"
          value={newSubBrand}
          onChange={(e) => setNewSubBrand(e.target.value)}
          sx={{ mr: 2, flexGrow: 1 }}
        />
        <Button variant="contained" color="primary" onClick={handleAddSubBrand} startIcon={<Add />}>
          Add Sub-Brand
        </Button>
      </Box>

      {subBrands.length > 0 ? (
        <List>
          {subBrands.map((subBrand) => (
            <Box key={subBrand._id}>
              <ListItem
                button
                onClick={() => handleSelectSubBrand(subBrand)}
                sx={{ bgcolor: 'background.paper', mb: 1, borderRadius: 1 }}
              >
                {isEditingSubBrand === subBrand._id ? (
                  <>
                    <TextField
                      variant="outlined"
                      value={editSubBrandName}
                      onChange={(e) => setEditSubBrandName(e.target.value)}
                      sx={{ mr: 2 }}
                    />
                    <IconButton onClick={() => handleUpdateSubBrand(subBrand._id)} color="primary">
                      <Save />
                    </IconButton>
                    <IconButton onClick={() => setIsEditingSubBrand(null)} color="secondary">
                      <Cancel />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <ListItemText
                      primary={
                        <Typography variant="body1" color="text.primary">
                          {subBrand.name}
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton onClick={() => handleEditSubBrand(subBrand)} color="primary">
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => handleOpenDialog(subBrand)} color="secondary">
                        <Delete />
                      </IconButton>
                      <IconButton onClick={() => handleSelectSubBrand(subBrand)} edge="end">
                        {selectedSubBrand && selectedSubBrand._id === subBrand._id ? <ExpandLess /> : <ExpandMore />}
                      </IconButton>
                    </ListItemSecondaryAction>
                  </>
                )}
              </ListItem>

              <Collapse in={selectedSubBrand && selectedSubBrand._id === subBrand._id} timeout="auto" unmountOnExit>
                <Box sx={{ pl: 4 }}>
                  {/* Pricing Strategy Management */}
                  <PricingManagement brandId={brand._id} subBrand={subBrand} />
                </Box>
              </Collapse>
            </Box>
          ))}
        </List>
      ) : (
        <Typography variant="body1">No sub-brands available for this brand.</Typography>
      )}

      {/* Confirmation Dialog for Deleting Sub-Brand */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="delete-subbrand-dialog-title"
        aria-describedby="delete-subbrand-dialog-description"
      >
        <DialogTitle id="delete-subbrand-dialog-title">Delete Sub-Brand</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-subbrand-dialog-description">
            Are you sure you want to delete the sub-brand{' '}
            <strong>{subBrandToDelete?.name}</strong>? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={confirmDeleteSubBrand} color="primary" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default SubBrandManagement;
