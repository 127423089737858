import React, { useState } from 'react';
import AdminBrandList from './AdminBrandList';
import AdminBrandDetails from './AdminBrandDetails';
import { Box, Button, Typography } from '@mui/material';
import axiosInstance from '../../../utils/axios';

const AdminBrandManagement = () => {
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [isAdding, setIsAdding] = useState(false);

  const handleAddBrand = () => {
    setSelectedBrand(null);
    setIsAdding(true);
  };

  const handleEditBrand = (brand) => {
    setSelectedBrand(brand);
    setIsAdding(true);
  };

  const handleSaveBrand = async (brandData) => {
    try {
      if (selectedBrand) {
        await axiosInstance.put(`/admin/brands/${selectedBrand._id}`, brandData);
      } else {
        await axiosInstance.post('/admin/brands', brandData);
      }
      setIsAdding(false);
    } catch (err) {
// $&

    }
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Admin: Brand Management
      </Typography>
      {isAdding ? (
        <AdminBrandDetails
          brand={selectedBrand}
          onSave={handleSaveBrand}
          onCancel={() => setIsAdding(false)}
        />
      ) : (
        <Box>
          <Button variant="contained" color="primary" onClick={handleAddBrand}>
            Add New Brand
          </Button>
          <AdminBrandList onEditBrand={handleEditBrand} />
        </Box>
      )}
    </Box>
  );
};

export default AdminBrandManagement;
