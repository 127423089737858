import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInvoiceInstance from '../../utils/axiosInvoice';

const InvoiceDetails = () => {
  const { id } = useParams();
  const [invoice, setInvoice] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
// $&

        const response = await axiosInvoiceInstance.get(`/invoices/${id}`);
        setInvoice(response.data);
      } catch (error) {
// $&

        if (error.response?.status === 404) {
          alert('Invoice not found or you do not have access.');
          navigate('/invoices'); // Redirect to the invoices list page
        }
      }
    };

    fetchInvoice();
  }, [id, navigate]);

  if (!invoice) return <p>Loading...</p>;

  return (
    <div className="invoice-details">
      <h2>Invoice #{invoice.invoiceNumber}</h2>
      <p>Customer ID: {invoice.customerId}</p>
      <p>Date: {new Date(invoice.date).toLocaleDateString()}</p>
      <p>Due Date: {new Date(invoice.dueDate).toLocaleDateString()}</p>
      <p>Status: {invoice.status}</p>
      <h3>Items</h3>
      <ul>
        {invoice.items.map((item) => (
          <li key={item._id}>
            <span>Description: {item.serviceId?.name || 'N/A'}</span>
            <span>Quantity: {item.quantity}</span>
            <span>Total: ${item.total.toFixed(2)}</span>
          </li>
        ))}
      </ul>
      <h3>Payments</h3>
      <ul>
        {invoice.payments.map((payment) => (
          <li key={payment._id}>
            <span>Amount: ${payment.amount.toFixed(2)}</span>
            <span>Date: {new Date(payment.date).toLocaleDateString()}</span>
            <span>Method: {payment.method}</span>
          </li>
        ))}
      </ul>
      <button onClick={() => navigate(`/invoices/${id}/payment`)}>Add Payment</button>
    </div>
  );
};

export default InvoiceDetails;
