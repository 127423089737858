import { styled } from '@mui/system';

export const DashboardContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100vh',
}));

export const SidebarContainer = styled('div')(({ theme }) => ({
  width: '20%',
  borderRight: `1px solid ${theme.palette.divider}`,
  height: '100vh',
  overflowY: 'auto',
  backgroundColor: theme.palette.background.paper,
  position: 'relative',
}));

export const MainContentContainer = styled('div')(({ theme }) => ({
  width: '80%',
  padding: theme.spacing(3),
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.default,
  position: 'relative',
}));

export const ServiceDetailsContainer = styled('div')(({ theme }) => ({
  flex: '0 1 auto',
  marginBottom: theme.spacing(3),
}));

export const SubServiceContainer = styled('div')(({ theme }) => ({
  flex: '1 1 auto',
}));
