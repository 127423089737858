import React from 'react';
import { Box, Typography, IconButton, List, ListItem, ListItemText } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const AdminSubBrandList = ({ subBrands, onSubBrandClick, onDeleteSubBrand }) => {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Sub-Brands
      </Typography>
      <List>
        {subBrands.map((subBrand) => (
          <ListItem key={subBrand._id} divider>
            <ListItemText primary={subBrand.name} secondary={subBrand.description} />
            <IconButton onClick={() => onSubBrandClick(subBrand)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => onDeleteSubBrand(subBrand._id)}>
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default AdminSubBrandList;
