// WizardSetup.jsx

import React, { useState } from 'react';
import Step1 from './WizardSteps/Step1';
import Step2 from './WizardSteps/Step2';
import Step3 from './WizardSteps/Step3';
import Step4 from './WizardSteps/Step4';
import { Stepper, Step, StepLabel, Box } from '@mui/material';

function WizardSetup({ service, isNew }) {
  const [currentStep, setCurrentStep] = useState(1);
  const [serviceData, setServiceData] = useState(service || {});

  const steps = ['Basic Details', 'Associate Sub-Brands', 'Set Up Pricing', 'Review and Confirm'];

  const nextStep = () => setCurrentStep((prev) => prev + 1);
  const prevStep = () => setCurrentStep((prev) => prev - 1);

  const handleServiceUpdate = (updatedData) => {
    setServiceData((prevData) => ({ ...prevData, ...updatedData }));
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <Step1
            serviceData={serviceData}
            handleServiceUpdate={handleServiceUpdate}
            nextStep={nextStep}
            isNew={isNew}
          />
        );
      case 2:
        return (
          <Step2
            serviceData={serviceData}
            handleServiceUpdate={handleServiceUpdate}
            nextStep={nextStep}
            prevStep={prevStep}
            isNew={isNew}
          />
        );
      case 3:
        return (
          <Step3
            serviceData={serviceData}
            handleServiceUpdate={handleServiceUpdate}
            nextStep={nextStep}
            prevStep={prevStep}
            isNew={isNew}
          />
        );
      case 4:
        return (
          <Step4
            serviceData={serviceData}
            prevStep={prevStep}
            isNew={isNew}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box>
      <Stepper activeStep={currentStep - 1} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label} completed={currentStep - 1 > index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box mt={4}>{renderStep()}</Box>
    </Box>
  );
}

export default WizardSetup;
