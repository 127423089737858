import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../../../utils/axios';
import {
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
} from '@mui/material';
import { Delete, Edit, Save, Cancel } from '@mui/icons-material';

function SubServiceManagement({ serviceId, onServiceUpdate }) {
  const [subServices, setSubServices] = useState([]);
  const [newSubService, setNewSubService] = useState({
    name: '',
    installTime: '',
    cureTime: '',
  });
  const [isEditingSubService, setIsEditingSubService] = useState(null);
  const [editSubServiceData, setEditSubServiceData] = useState({
    name: '',
    installTime: '',
    cureTime: '',
  });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedSubServiceId, setSelectedSubServiceId] = useState('');

  const fetchSubServices = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`/services/${serviceId}/sub-services`);
      setSubServices(response.data);
    } catch (error) {
// $&

    }
  }, [serviceId]);

  useEffect(() => {
    fetchSubServices();
  }, [fetchSubServices]);

  const handleAddSubService = async () => {
    if (
      !newSubService.name.trim() ||
      !newSubService.installTime.trim() ||
      !newSubService.cureTime.trim()
    )
      return;
    try {
      const response = await axiosInstance.post(`/services/${serviceId}/sub-services`, {
        name: newSubService.name.trim(),
        installTime: newSubService.installTime.trim(),
        cureTime: newSubService.cureTime.trim(),
      });
      setSubServices([...subServices, response.data]);
      setNewSubService({ name: '', installTime: '', cureTime: '' });
      onServiceUpdate(); // Refresh the service data
    } catch (error) {
// $&

    }
  };

  const handleEditSubService = (subService) => {
    setIsEditingSubService(subService._id);
    setEditSubServiceData({
      name: subService.name,
      installTime: subService.installTime,
      cureTime: subService.cureTime,
    });
  };

  const handleUpdateSubService = async (subServiceId) => {
    // Ensure the name is not empty
    if (!editSubServiceData.name.trim()) return;
  
    try {
      // Prepare data for the update request
      const updatedData = {
        name: editSubServiceData.name.trim(), // Always trim the name
        installTime: editSubServiceData.installTime !== '' ? editSubServiceData.installTime : undefined,
        cureTime: editSubServiceData.cureTime !== '' ? editSubServiceData.cureTime : undefined,
      };
  
      // Send the update request
      const response = await axiosInstance.put(`/services/${serviceId}/sub-services/${subServiceId}`, updatedData);
  
      // Update state with the new sub-service data
      setSubServices(
        subServices.map((subService) =>
          subService._id === subServiceId
            ? {
                ...subService,
                name: response.data.name,
                installTime: response.data.installTime,
                cureTime: response.data.cureTime,
              }
            : subService
        )
      );
  
      // Reset editing state
      setIsEditingSubService(null);
      setEditSubServiceData({ name: '', installTime: '', cureTime: '' });
  
      // Refresh service data
      onServiceUpdate();
    } catch (error) {
// $&

    }
  };

  const handleOpenDeleteDialog = (subServiceId) => {
    setSelectedSubServiceId(subServiceId);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setSelectedSubServiceId('');
  };

  const handleDeleteSubService = async () => {
    try {
      await axiosInstance.delete(`/services/${serviceId}/sub-services/${selectedSubServiceId}`);
      setSubServices(subServices.filter((subService) => subService._id !== selectedSubServiceId));
      setDeleteDialogOpen(false);
      onServiceUpdate();
    } catch (error) {
// $&

    }
  };

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Sub-Services for Service
      </Typography>

      {/* Add New Sub-Service Input */}
      <Box mb={2}>
        <TextField
          label="New Sub-Service Name"
          value={newSubService.name}
          onChange={(e) => setNewSubService({ ...newSubService, name: e.target.value })}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Install Time (in minutes)"
          value={newSubService.installTime}
          onChange={(e) => setNewSubService({ ...newSubService, installTime: e.target.value })}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Cure Time (in minutes)"
          value={newSubService.cureTime}
          onChange={(e) => setNewSubService({ ...newSubService, cureTime: e.target.value })}
          fullWidth
          margin="normal"
        />
        <Button variant="contained" color="primary" onClick={handleAddSubService}>
          Add Sub-Service
        </Button>
      </Box>

      {/* Sub-Services List */}
      <List>
        {subServices.map((subService) => (
          <Box key={subService._id} mb={1}>
            <ListItem sx={{ bgcolor: 'background.paper', borderRadius: 1 }}>
              {isEditingSubService === subService._id ? (
                <>
                  <TextField
                    label="Edit Sub-Service Name"
                    variant="outlined"
                    value={editSubServiceData.name}
                    onChange={(e) =>
                      setEditSubServiceData({ ...editSubServiceData, name: e.target.value })
                    }
                    sx={{ mr: 2 }}
                  />
                  <TextField
                    label="Edit Install Time (in minutes)"
                    variant="outlined"
                    value={editSubServiceData.installTime}
                    onChange={(e) =>
                      setEditSubServiceData({
                        ...editSubServiceData,
                        installTime: e.target.value,
                      })
                    }
                    sx={{ mr: 2 }}
                  />
                  <TextField
                    label="Edit Cure Time (in minutes)"
                    variant="outlined"
                    value={editSubServiceData.cureTime}
                    onChange={(e) =>
                      setEditSubServiceData({
                        ...editSubServiceData,
                        cureTime: e.target.value,
                      })
                    }
                    sx={{ mr: 2 }}
                  />
                  <IconButton color="primary" onClick={() => handleUpdateSubService(subService._id)}>
                    <Save />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => setIsEditingSubService(null)}>
                    <Cancel />
                  </IconButton>
                </>
              ) : (
                <>
                  <ListItemText
                    primary={`${subService.name} - Install Time: ${subService.installTime} mins - Cure Time: ${subService.cureTime} mins`}
                  />
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <IconButton edge="end" onClick={() => handleEditSubService(subService)}>
                      <Edit />
                    </IconButton>
                    <IconButton edge="end" onClick={() => handleOpenDeleteDialog(subService._id)}>
                      <Delete color="error" />
                    </IconButton>
                  </Box>
                </>
              )}
            </ListItem>
          </Box>
        ))}
      </List>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>Are you sure you want to delete this sub-service?</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDeleteSubService} color="primary" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SubServiceManagement;
