// components/Invoice/InvoiceDashboard.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import InvoiceList from './InvoiceList';
import './InvoiceDashboard.css';

const InvoiceDashboard = () => {
  return (
    <div className="invoice-dashboard">
      <h2>Invoice Dashboard</h2>
      <div className="button-group">
        <Link to="/invoices/new" className="btn btn-primary">Create New Invoice</Link>
        <Link to="/settings/tax-rates" className="btn btn-secondary">Sales Tax Settings</Link>
      </div>
      <InvoiceList />
    </div>
  );
};

export default InvoiceDashboard;
