import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from '@mui/material';
import axiosInstance from '../../utils/axios';
import { jwtDecode } from 'jwt-decode';
import OnboardingProgressBar from './OnboardingProgressBar';

const AssignBrandsToServices = ({ onNext, onBack }) => {
  const [services, setServices] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [ownerId, setOwnerId] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  // Decode token to fetch ownerId
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setOwnerId(decodedToken.userId);
      } catch (err) {
// $&

        setError('Failed to decode session token.');
      }
    } else {
      setError('No session token found.');
    }
  }, []);

  // Fetch services and brands for the owner
  useEffect(() => {
    if (ownerId) {
      fetchServices();
      fetchBrands();
    }
  }, [ownerId]);

  const fetchServices = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/services');
      setServices(response.data);
    } catch (err) {
// $&

      setError('Failed to load services.');
    } finally {
      setLoading(false);
    }
  };

  const fetchBrands = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/brands');
      setBrands(response.data);
    } catch (err) {
// $&

      setError('Failed to load brands.');
    } finally {
      setLoading(false);
    }
  };

  const handleBrandSelect = (serviceId, brandId) => {
    setSelectedBrands((prev) => {
      const currentSelection = prev[serviceId] || [];
      const isSelected = currentSelection.includes(brandId);

      return {
        ...prev,
        [serviceId]: isSelected
          ? currentSelection.filter((id) => id !== brandId)
          : [...currentSelection, brandId],
      };
    });
  };

  const assignBrandsToServices = async () => {
    setIsSaving(true);
    try {
      const serviceBrandMapping = Object.entries(selectedBrands).map(([serviceId, brandIds]) => ({
        serviceId: serviceId.toString(),
        brandIds: brandIds.map((id) => id.toString()),
      }));

      await axiosInstance.put(`/services/assign-brands-bulk`, { serviceBrandMapping });

      // Update onboarding progress
      await axiosInstance.put(`/onboarding-status/${ownerId}`, {
        currentStep: 7,
        brands: selectedBrands,
      });

      onNext();
    } catch (err) {
// $&

      setError('Failed to assign brands to services.');
    } finally {
      setIsSaving(false);
    }
  };

  const skipSelection = async () => {
    setIsSaving(true);
    try {
      await axiosInstance.put(`/onboarding-status/${ownerId}`, {
        currentStep: 7,
      });

      onNext();
    } catch (err) {
// $&

      setError('Failed to skip selection.');
    } finally {
      setIsSaving(false);
    }
  };

  if (loading) {
    return <CircularProgress className="loading-spinner" />;
  }

  if (!services.length || !brands.length) {
    return (
      <Box>
        <Typography color="error">
          {error || 'No services or brands available. Please check your setup.'}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 3, bgcolor: '#1C1C1C', p: 3, borderRadius: 2, color: '#C0C0C0' }}>
      <Typography variant="h6" sx={{ color: '#39FF14' }}>
        Assign Brands to Services
      </Typography>

      {/* Progress Bar */}
      <OnboardingProgressBar currentStep={7} totalSteps={11} />

      {error && <Typography color="error">{error}</Typography>}
      {services.map((service) => (
        <Box key={service._id} mt={2}>
          <Typography variant="h6">{service.name}</Typography>
          <FormGroup>
            {brands.map((brand) => (
              <FormControlLabel
                key={brand._id}
                control={
                  <Checkbox
                    checked={selectedBrands[service._id]?.includes(brand._id) || false}
                    onChange={() => handleBrandSelect(service._id, brand._id)}
                  />
                }
                label={brand.name}
              />
            ))}
          </FormGroup>
        </Box>
      ))}
      <Box mt={4} display="flex" justifyContent="space-between">
        <Button
          variant="outlined"
          onClick={onBack}
          sx={{ color: '#00FFFF', borderColor: '#00FFFF', '&:hover': { bgcolor: '#2C2C2C' } }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={assignBrandsToServices}
          disabled={!Object.keys(selectedBrands).length || isSaving}
          sx={{ bgcolor: '#39FF14', color: '#000000', '&:hover': { bgcolor: '#00FFFF' } }}
        >
          {isSaving ? 'Saving...' : 'Assign Brands to Services'}
        </Button>
        <Button
          variant="contained"
          onClick={skipSelection}
          sx={{ bgcolor: '#708090', color: '#FFFFFF', '&:hover': { bgcolor: '#A9A9A9' } }}
        >
          Already Chosen
        </Button>
      </Box>
    </Box>
  );
};

export default AssignBrandsToServices;
