// ServiceManagement.jsx

import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../utils/axios';
import SubServiceManagement from './SubServiceManagement';
import {
  Paper,
  Typography,
  TextField,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Box,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Add, Delete, Edit, ExpandLess, ExpandMore, Save, Cancel } from '@mui/icons-material';

function ServiceManagement() {
  const [services, setServices] = useState([]);
  const [newService, setNewService] = useState('');
  const [selectedService, setSelectedService] = useState(null);
  const [isEditingService, setIsEditingService] = useState(null);
  const [editServiceName, setEditServiceName] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [serviceToDelete, setServiceToDelete] = useState(null);

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
      const response = await axiosInstance.get('/services');
      setServices(response.data);
    } catch (error) {
// $&

    }
  };

  const handleAddService = async () => {
    if (!newService.trim()) return;
    try {
      const response = await axiosInstance.post('/services', { name: newService.trim() });
      setServices([...services, response.data]);
      setNewService('');
    } catch (error) {
// $&

    }
  };

  const handleOpenDialog = (service) => {
    setServiceToDelete(service);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setServiceToDelete(null);
  };

  const confirmDeleteService = async () => {
    if (serviceToDelete) {
      try {
        await axiosInstance.delete(`/services/${serviceToDelete._id}`);
        setServices(services.filter((service) => service._id !== serviceToDelete._id));
        if (selectedService && selectedService._id === serviceToDelete._id) {
          setSelectedService(null);
        }
        handleCloseDialog();
      } catch (error) {
// $&

        handleCloseDialog();
      }
    }
  };

  const handleEditService = (service) => {
    setIsEditingService(service._id);
    setEditServiceName(service.name);
  };

  const handleUpdateService = async (serviceId) => {
    if (!editServiceName.trim()) return;
    try {
      const response = await axiosInstance.put(`/services/${serviceId}`, { name: editServiceName.trim() });
      setServices(
        services.map((service) =>
          service._id === serviceId ? { ...service, name: response.data.name } : service
        )
      );
      setIsEditingService(null);
      setEditServiceName('');
    } catch (error) {
// $&

    }
  };

  const handleSelectService = (service) => {
    if (selectedService && selectedService._id === service._id) {
      setSelectedService(null);
    } else {
      setSelectedService(service);
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Service Management
      </Typography>

      <Box display="flex" alignItems="center" mb={2}>
        <TextField
          label="New Service Name"
          variant="outlined"
          value={newService}
          onChange={(e) => setNewService(e.target.value)}
          sx={{ mr: 2, flexGrow: 1 }}
        />
        <Button variant="contained" color="primary" onClick={handleAddService} startIcon={<Add />}>
          Add Service
        </Button>
      </Box>

      {services.length > 0 ? (
        <List>
          {services.map((service) => (
            <Box key={service._id}>
              <ListItem
                button
                onClick={() => handleSelectService(service)}
                sx={{ bgcolor: 'background.paper', mb: 1, borderRadius: 1 }}
              >
                {isEditingService === service._id ? (
                  <>
                    <TextField
                      variant="outlined"
                      value={editServiceName}
                      onChange={(e) => setEditServiceName(e.target.value)}
                      sx={{ mr: 2 }}
                    />
                    <IconButton onClick={() => handleUpdateService(service._id)} color="primary">
                      <Save />
                    </IconButton>
                    <IconButton onClick={() => setIsEditingService(null)} color="secondary">
                      <Cancel />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <ListItemText
                      primary={
                        <Typography variant="h6" color="text.primary">
                          {service.name}
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton onClick={() => handleEditService(service)} color="primary">
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => handleOpenDialog(service)} color="secondary">
                        <Delete />
                      </IconButton>
                      <IconButton onClick={() => handleSelectService(service)} edge="end">
                        {selectedService && selectedService._id === service._id ? <ExpandLess /> : <ExpandMore />}
                      </IconButton>
                    </ListItemSecondaryAction>
                  </>
                )}
              </ListItem>

              <Collapse in={selectedService && selectedService._id === service._id} timeout="auto" unmountOnExit>
                <Box sx={{ pl: 4 }}>
                  <SubServiceManagement serviceId={service._id} onServiceUpdate={fetchServices} />
                </Box>
              </Collapse>
            </Box>
          ))}
        </List>
      ) : (
        <Typography variant="body1">No services available.</Typography>
      )}

      {/* Confirmation Dialog for Deleting Service */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="delete-service-dialog-title"
        aria-describedby="delete-service-dialog-description"
      >
        <DialogTitle id="delete-service-dialog-title">Delete Service</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-service-dialog-description">
            Are you sure you want to delete the service{' '}
            <strong>{serviceToDelete?.name}</strong>? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={confirmDeleteService} color="primary" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

export default ServiceManagement;
