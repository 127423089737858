// src/contexts/ThemeContext.js
import React, { createContext, useContext, useState, useMemo } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const ThemeContext = createContext();

export const useThemeMode = () => useContext(ThemeContext);

export function ThemeContextProvider({ children }) {
  const [mode, setMode] = useState('light');

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            main: '#39FF14', // Neon Green
          },
          background: {
            default: mode === 'dark' ? '#1C1C1C' : '#F5F5F5',
            paper: mode === 'dark' ? '#333' : '#FFF',
          },
          text: {
            primary: mode === 'dark' ? '#FFF' : '#000',
            secondary: mode === 'dark' ? '#CCC' : '#555',
          },
        },
      }),
    [mode]
  );

  const toggleTheme = () => setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));

  return (
    <ThemeContext.Provider value={{ toggleTheme }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
}
