import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function GoogleLoginButton() {
  const navigate = useNavigate();
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
// $&

// $&

// $&



  const handleGoogleLogin = useCallback(
    async (googleResponse) => {
      try {
        const id_token = googleResponse.credential; // Google's ID token
        console.log('Google ID token:', id_token); // Debug log to verify the token

        const response = await axios.post(
            'http://localhost:5000/api/google-oauth/google-token-to-jwt',
            { id_token }
          );
        const { token } = response.data;

        // Save the token and navigate to the dashboard
        localStorage.setItem('token', token);
        navigate('/dashboard');
      } catch (error) {
// $&

        alert('Failed to login with Google.');
      }
    },
    [navigate]
  );

  useEffect(() => {
// $&

    if (!window.google) {
// $&

      return;
    }

    /* Initialize Google Login */
    window.google.accounts.id.initialize({
      client_id: googleClientId,
      callback: handleGoogleLogin,
    });

    /* Render Google Login Button */
    window.google.accounts.id.renderButton(
      document.getElementById('google-signin-button'),
      { theme: 'outline', size: 'large' } // Customize button appearance
    );

    /* Optional: Prompt One Tap Login */
    window.google.accounts.id.prompt();
  }, [googleClientId, handleGoogleLogin]);

  return <div id="google-signin-button"></div>;
}

export default GoogleLoginButton;
