import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';
import axiosInstance from '../../utils/axios';
import './BayManagement.css'; // Import the CSS file for custom styles

function BayManagement({ onSave }) {
  const [bays, setBays] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [open, setOpen] = useState(false);
  const [bayData, setBayData] = useState({
    bayName: '',
    availableHours: { start: '', end: '' },
    daysAvailable: [],
    assignedEmployees: [],
  });
  const [editingBay, setEditingBay] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    fetchBays();
    fetchEmployees();
  }, []);

  const fetchBays = async () => {
    try {
      const response = await axiosInstance.get('/bays');
      setBays(response.data);
      setIsCompleted(response.data.length > 0); // Mark step complete if at least one bay exists
      if (response.data.length > 0 && onSave) onSave(); // Notify onboarding wizard
    } catch (error) {
// $&

    }
  };

  const fetchEmployees = async () => {
    try {
      const response = await axiosInstance.get('/employees');
      setEmployees(response.data);
    } catch (error) {
// $&

    }
  };

  const handleSave = async () => {
    try {
      if (editingBay) {
        await axiosInstance.put(`/bays/${editingBay._id}`, bayData);
      } else {
        await axiosInstance.post('/bays', bayData);
      }
      fetchBays();
      handleClose();
    } catch (error) {
// $&

    }
  };

  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(`/bays/${id}`);
      fetchBays();
    } catch (error) {
// $&

    }
  };

  const handleClose = () => {
    setOpen(false);
    setEditingBay(null);
    setBayData({
      bayName: '',
      availableHours: { start: '', end: '' },
      daysAvailable: [],
      assignedEmployees: [],
    });
  };

  return (
    <div className="bay-management">
      <Typography variant="h5" className="header">
        Bay Management
      </Typography>
      <Button
        variant="contained"
        onClick={() => setOpen(true)}
        className="add-bay-button"
      >
        Add Bay
      </Button>
      <Box className="bay-list">
        {bays.map((bay) => (
          <Box key={bay._id} className="bay-item">
            <Typography className="bay-info">
              <strong>{bay.bayName}</strong> - {bay.availableHours.start} to {bay.availableHours.end} (
              {bay.daysAvailable.join(', ')})
            </Typography>
            <Typography className="bay-info">
              Assigned Employees: {bay.assignedEmployees
                .map((emp) => `${emp.firstName} ${emp.lastName}`)
                .join(', ')}
            </Typography>
            <Button
              onClick={() => {
                setEditingBay(bay);
                setBayData(bay);
                setOpen(true);
              }}
              className="edit-button"
            >
              Edit
            </Button>
            <Button onClick={() => handleDelete(bay._id)} className="delete-button">
              Delete
            </Button>
          </Box>
        ))}
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editingBay ? 'Edit Bay' : 'Add Bay'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Bay Name"
            value={bayData.bayName}
            onChange={(e) => setBayData({ ...bayData, bayName: e.target.value })}
            fullWidth
            margin="normal"
            InputLabelProps={{ style: { color: '#C0C0C0' } }}
            InputProps={{ style: { color: '#00FFFF' } }}
          />
          <TextField
            label="Start Time"
            type="time"
            value={bayData.availableHours.start}
            onChange={(e) =>
              setBayData({
                ...bayData,
                availableHours: { ...bayData.availableHours, start: e.target.value },
              })
            }
            fullWidth
            margin="normal"
            InputLabelProps={{ style: { color: '#C0C0C0' } }}
            InputProps={{ style: { color: '#00FFFF' } }}
          />
          <TextField
            label="End Time"
            type="time"
            value={bayData.availableHours.end}
            onChange={(e) =>
              setBayData({
                ...bayData,
                availableHours: { ...bayData.availableHours, end: e.target.value },
              })
            }
            fullWidth
            margin="normal"
            InputLabelProps={{ style: { color: '#C0C0C0' } }}
            InputProps={{ style: { color: '#00FFFF' } }}
          />
          <Box>
            {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(
              (day) => (
                <FormControlLabel
                  key={day}
                  control={
                    <Checkbox
                      checked={bayData.daysAvailable.includes(day)}
                      onChange={(e) => {
                        const newDays = e.target.checked
                          ? [...bayData.daysAvailable, day]
                          : bayData.daysAvailable.filter((d) => d !== day);
                        setBayData({ ...bayData, daysAvailable: newDays });
                      }}
                    />
                  }
                  label={day}
                />
              )
            )}
          </Box>
          <FormControl fullWidth margin="normal">
            <InputLabel>Assign Employees</InputLabel>
            <Select
              multiple
              value={bayData.assignedEmployees}
              onChange={(e) => setBayData({ ...bayData, assignedEmployees: e.target.value })}
              renderValue={(selected) =>
                selected
                  .map((empId) => {
                    const employee = employees.find((e) => e._id === empId);
                    return employee ? `${employee.firstName} ${employee.lastName}` : '';
                  })
                  .join(', ')
              }
            >
              {employees.map((employee) => (
                <MenuItem key={employee._id} value={employee._id}>
                  {employee.firstName} {employee.lastName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default BayManagement;
