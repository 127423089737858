// Step2.jsx

import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../../utils/axios';
import {
  Paper,
  Typography,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
} from '@mui/material';

function Step2({ serviceData, handleServiceUpdate, nextStep, prevStep }) {
  const [brands, setBrands] = useState([]);
  const [selectedSubBrands, setSelectedSubBrands] = useState(serviceData.subBrands || []);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await axiosInstance.get('/brands');
        setBrands(response.data);
      } catch (error) {
// $&

      }
    };
    fetchBrands();
  }, []);

  const handleSubBrandSelection = (subBrandId) => {
    setSelectedSubBrands((prev) =>
      prev.includes(subBrandId) ? prev.filter((id) => id !== subBrandId) : [...prev, subBrandId]
    );
  };

  const handleSubmit = async () => {
    try {
      const response = await axiosInstance.put(`/services/${serviceData._id}`, {
        subBrands: selectedSubBrands,
      });
      handleServiceUpdate(response.data);
      nextStep();
    } catch (error) {
// $&

    }
  };

  return (
    <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Step 2: Associate Sub-Brands
      </Typography>
      {brands.map((brand) => (
        <Box key={brand._id} sx={{ mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            {brand.name}
          </Typography>
          <FormGroup>
            {brand.subBrands.map((subBrand) => (
              <FormControlLabel
                key={subBrand._id}
                control={
                  <Checkbox
                    checked={selectedSubBrands.includes(subBrand._id)}
                    onChange={() => handleSubBrandSelection(subBrand._id)}
                  />
                }
                label={subBrand.name}
              />
            ))}
          </FormGroup>
        </Box>
      ))}
      <Box sx={{ mt: 2 }}>
        <Button variant="outlined" color="secondary" onClick={prevStep} sx={{ mr: 2 }}>
          Back
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Next
        </Button>
      </Box>
    </Paper>
  );
}

export default Step2;
