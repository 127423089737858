import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosCustomerInstance from '../../utils/axiosCustomer';
import axiosServiceInstance from '../../utils/axiosAppointment';
import axiosInvoiceInstance from '../../utils/axiosInvoice';
import './InvoiceForm.css'; // Import CSS for responsive design and theme

// Utility function to format dates
const formatDate = (isoDate) => {
    if (!isoDate) return '';
    const date = new Date(isoDate);
    return date.toISOString().split('T')[0]; // Format as "yyyy-MM-dd"
  };

const InvoiceForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    customerId: '',
    items: [{ serviceId: '', subServiceId: '', subBrandId: '', quantity: 1, price: 0, total: 0 }],
    invoiceNumber: '',
    date: formatDate(new Date()),
    dueDate: formatDate(new Date()),
    terms: 'Due on receipt',
    subtotal: 0,
    tax: null, // Store the ObjectId of the selected tax
    taxRate: 0, // Store the numeric tax rate
    total: 0,
    notes: '',
    status: 'Draft'
  });
  const [customers, setCustomers] = useState([]);
  const [services, setServices] = useState([]);
  const [subBrandDetails, setSubBrandDetails] = useState({});
  const [query, setQuery] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [salesTaxes, setSalesTaxes] = useState([]);

  useEffect(() => {
    if (id) {
      fetchInvoice(id);
    }
    fetchCustomers();
    fetchServicesAndSubServices();
  }, [id]);

  const fetchInvoice = async (invoiceId) => {
    try {
      const response = await axiosInvoiceInstance.get(`/invoices/${invoiceId}`);
      const invoiceData = response.data;
      setFormData({
        ...invoiceData,
        date: formatDate(invoiceData.date), // Format date for input
        dueDate: formatDate(invoiceData.dueDate), // Format dueDate for input
      });
    } catch (error) {
// $&

    }
  };

  const handleDateChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const fetchCustomers = async () => {
    try {
      const response = await axiosCustomerInstance.get('/customers');
      setCustomers(response.data);
    } catch (error) {
// $&

    }
  };

  const fetchServicesAndSubServices = async () => {
    try {
      const response = await axiosServiceInstance.get('/services');
      const servicesWithSubServices = await Promise.all(
        response.data.map(async (service) => {
          const subServiceResponse = await axiosServiceInstance.get(`/services/${service._id}/sub-services`);
          const subServices = subServiceResponse.data;
          return { ...service, subServices };
        })
      );

      setServices(servicesWithSubServices);

      const subBrandIds = [];
      servicesWithSubServices.forEach(service =>
        service.subServices.forEach(subService =>
          subService.subBrandPricing.forEach(pricing => {
            if (!subBrandIds.includes(pricing.subBrand)) subBrandIds.push(pricing.subBrand);
          })
        )
      );

      if (subBrandIds.length) {
        const subBrandResponse = await axiosServiceInstance.post('/sub-brands/multiple', { subBrandIds });
        const subBrandMap = {};
        subBrandResponse.data.forEach(subBrand => {
          subBrandMap[subBrand._id] = subBrand;
        });
        setSubBrandDetails(subBrandMap);
      }
    } catch (error) {
// $&

    }
  };

  const handleCustomerSearch = async (event) => {
    const searchQuery = event.target.value.trim();
    setQuery(searchQuery);
    if (!searchQuery) return;
    try {
      const response = await axiosCustomerInstance.get(`/customers/search`, {
        params: { query: searchQuery },
      });
      setCustomers(response.data);
    } catch (error) {
// $&

    }
  };

  const handleCustomerSelect = (customerId) => {
    const customer = customers.find(c => c._id === customerId);
    setSelectedCustomer(customer);
    setFormData({ ...formData, customerId });
    setQuery('');
  };

  const handleChangeCustomer = () => {
    setSelectedCustomer(null);
    setFormData({ ...formData, customerId: '' });
  };

  const handleItemChange = (index, field, value) => {
    const updatedItems = [...formData.items];
    updatedItems[index][field] = value;

    if (field === 'subServiceId') {
      updatedItems[index].subBrandId = '';
      updatedItems[index].price = 0;
    }

    if (field === 'subBrandId') {
      const selectedService = services.find(service => service.subServices.some(sub => sub._id === updatedItems[index].subServiceId));
      const selectedSubService = selectedService?.subServices.find(sub => sub._id === updatedItems[index].subServiceId);
      const selectedSubBrandPricing = selectedSubService?.subBrandPricing.find(pricing => pricing.subBrand === value && pricing.active);

      updatedItems[index].price = selectedSubBrandPricing ? selectedSubBrandPricing.price : 0;
    }

    updatedItems[index].total = updatedItems[index].quantity * updatedItems[index].price;
    setFormData({ ...formData, items: updatedItems });
    updateTotals(updatedItems);
  };

  const updateTotals = (items) => {
    const subtotal = items.reduce((acc, item) => acc + item.total, 0);
    const tax = subtotal * (formData.taxRate / 100);
    setFormData({ ...formData, subtotal, tax, total: subtotal + tax });
  };

  useEffect(() => {
    const fetchSalesTaxes = async () => {
      try {
        const response = await axiosInvoiceInstance.get('/sales-taxes');
        setSalesTaxes(response.data);
      } catch (error) {
// $&

      }
    };
    fetchSalesTaxes();
  }, []);

  const handleSalesTaxChange = (taxId) => {
    const selectedTax = salesTaxes.find(tax => tax._id === taxId);
    const taxRate = selectedTax ? (selectedTax.combinedRate || (selectedTax.stateRate + selectedTax.countyRate)) : 0;

    setFormData({ 
      ...formData, 
      tax: taxId, // Set ObjectId for the selected tax
      taxRate, // Set the numeric rate
      total: formData.subtotal + formData.subtotal * (taxRate / 100)
    });
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        await axiosInvoiceInstance.put(`/invoices/${id}`, formData);
      } else {
        const response = await axiosInvoiceInstance.post('/invoices', formData);
        setFormData((prevData) => ({
          ...prevData,
          invoiceNumber: response.data.invoiceNumber // Set invoiceNumber from backend response if new
        }));
      }
      navigate('/invoices');
    } catch (error) {
// $&

    }
  };

  return (
    <div className="invoice-form">
      <h2 className="form-title">{id ? 'Edit Invoice' : 'Create New Invoice'}</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-section-horizontal">
          <div className="form-group">
            <label>Invoice No.</label>
            <input type="text" value={formData.invoiceNumber} disabled />
          </div>
          <div className="form-group">
            <label>Terms</label>
            <select
              value={formData.terms}
              onChange={(e) => setFormData({ ...formData, terms: e.target.value })}
            >
              <option value="Due on receipt">Due on receipt</option>
              <option value="Net 15">Net 15</option>
              <option value="Net 30">Net 30</option>
              <option value="Net 60">Net 60</option>
            </select>
          </div>
          <div className="form-group">
          <label>Invoice Date</label>
            <input
              type="date"
              value={formData.date}
              onChange={(e) => handleDateChange('date', e.target.value)}
            />
          </div>
          
          <div className="form-group">
            <label>Due Date</label>
            <input
              type="date"
              value={formData.dueDate}
              onChange={(e) => handleDateChange('dueDate', e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Status</label>
            <select
              value={formData.status}
              onChange={(e) => setFormData({ ...formData, status: e.target.value })}
            >
              <option value="Draft">Draft</option>
              <option value="Sent">Sent</option>
              <option value="Paid">Paid</option>
              <option value="Partial">Partial</option>
              <option value="Overdue">Overdue</option>
            </select>
          </div>
        </div>

        <div className="form-section">
          <label>Customer</label>
          {selectedCustomer ? (
            <div className="selected-customer">
              <span>{selectedCustomer.firstName} {selectedCustomer.lastName}</span>
              <button type="button" onClick={handleChangeCustomer} className="change-customer-button">Change Customer</button>
            </div>
          ) : (
            <>
              <input
                type="text"
                placeholder="Search customer"
                value={query}
                onChange={handleCustomerSearch}
              />
              <select onChange={(e) => handleCustomerSelect(e.target.value)} value={formData.customerId}>
                <option value="">Select a customer</option>
                {customers.map((customer) => (
                  <option key={customer._id} value={customer._id}>
                    {customer.firstName} {customer.lastName}
                  </option>
                ))}
              </select>
            </>
          )}
        </div>

        <div className="form-section">
          <label>Products/Services</label>
          {formData.items.map((item, index) => (
            <div key={index} className="invoice-item item-row">
              <div className="form-group">
                <label>Service</label>
                <select
                  value={item.serviceId}
                  onChange={(e) => handleItemChange(index, 'serviceId', e.target.value)}
                >
                  <option value="">Select a service</option>
                  {services.map((service) => (
                    <option key={service._id} value={service._id}>
                      {service.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>Sub-Service</label>
                <select
                  value={item.subServiceId}
                  onChange={(e) => handleItemChange(index, 'subServiceId', e.target.value)}
                >
                  <option value="">Select a sub-service</option>
                  {services
                    .find((service) => service._id === item.serviceId)
                    ?.subServices.map((subService) => (
                      <option key={subService._id} value={subService._id}>
                        {subService.name}
                      </option>
                    ))}
                </select>
              </div>

              <div className="form-group">
                <label>Sub-Brand</label>
                <select value={item.subBrandId} onChange={(e) => handleItemChange(index, 'subBrandId', e.target.value)}>
                  <option value="">Select a sub-brand</option>
                  {services
                    .find(service => service._id === item.serviceId)
                    ?.subServices.find(subService => subService._id === item.subServiceId)
                    ?.subBrandPricing.filter(pricing => pricing.active)
                    .map(pricing => (
                      <option key={pricing.subBrand} value={pricing.subBrand}>
                        {subBrandDetails[pricing.subBrand]?.name || 'Unknown'} - ${pricing.price || 0}
                      </option>
                    ))}
                </select>
              </div>

              <div className="form-group">
                <label>Quantity</label>
                <input
                  type="number"
                  placeholder="Qty"
                  value={item.quantity}
                  onChange={(e) => handleItemChange(index, 'quantity', Number(e.target.value))}
                />
              </div>

              <div className="form-group">
                <label>Rate</label>
                <input type="number" placeholder="Rate" value={item.price} disabled />
              </div>

              <div className="form-group">
                <label>Total</label>
                <input type="number" placeholder="Total" value={item.total} disabled />
              </div>
            </div>
          ))}
          <button type="button" className="add-item-button" onClick={() => setFormData({ ...formData, items: [...formData.items, { serviceId: '', subServiceId: '', subBrandId: '', quantity: 1, price: 0, total: 0 }] })}>
            Add product or service
          </button>
        </div>

        <div className="subtotal-section">
          <label>Subtotal:</label>
          <span>${formData.subtotal.toFixed(2)}</span>
        </div>

        <div className="sales-tax"> 
          <label>Sales Tax</label>
          <select
            value={formData.tax}
            onChange={(e) => handleSalesTaxChange(e.target.value)}
          >
            <option value="">Select Sales Tax</option>
            {salesTaxes.map((tax) => (
              <option key={tax._id} value={tax._id}>
                {tax.name} - {tax.combinedRate || (tax.stateRate + tax.countyRate)}%
              </option>
            ))}
          </select>
        </div>

        <div className="total-section">
          <label>Total:</label>
          <span>${formData.total.toFixed(2)}</span>
        </div>

        <button type="submit" className="submit-button">{id ? 'Update Invoice' : 'Create Invoice'}</button>
      </form>
    </div>
  );
};

export default InvoiceForm;
