import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from '../../utils/axiosInvoice';

const SalesTaxList = () => {
  const [taxRates, setTaxRates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchTaxRates();
  }, []);

  const fetchTaxRates = async () => {
    try {
      setLoading(true);
      setError(null);

      // Make API request using the JWT token in headers (handled by axiosInvoice)
      const response = await axios.get('/sales-taxes');

      if (response.data.length === 0) {
        setError('No tax rates available.');
        return;
      }

      setTaxRates(response.data);
    } catch (error) {
// $&


      // Handle errors from the backend
      if (error.response?.status === 401) {
        setError('Authorization failed. Please log in again.');
      } else {
        setError('Failed to load tax rates. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const deleteTaxRate = async (id) => {
    try {
      // Delete tax rate without explicitly passing ownerId
      await axios.delete(`/sales-taxes/${id}`);
      setTaxRates((prev) => prev.filter((tax) => tax._id !== id));
    } catch (error) {
// $&

      alert('Failed to delete the tax rate. Please try again.');
    }
  };

  return (
    <div className="sales-tax-list">
      <h2>Sales Tax Rates</h2>
      <Link to="/settings/tax-rates/new" className="add-button">
        Add New Tax Rate
      </Link>

      {loading ? (
        <p>Loading tax rates...</p>
      ) : error ? (
        <p className="error-message">{error}</p>
      ) : taxRates.length > 0 ? (
        <ul>
          {taxRates.map((tax) => (
            <li key={tax._id}>
              <span>{tax.name}</span> - 
              <span>
                {tax.type === 'Combined'
                  ? `${tax.combinedRate}% (Combined)`
                  : tax.type === 'StateOnly'
                  ? `${tax.stateRate}% (State Only)`
                  : `${tax.countyRate}% (County Only)`}
              </span>
              <Link to={`/settings/tax-rates/edit/${tax._id}`} className="edit-button">
                Edit
              </Link>
              <button onClick={() => deleteTaxRate(tax._id)} className="delete-button">
                Delete
              </button>
            </li>
          ))}
        </ul>
      ) : (
        <p>No tax rates available.</p>
      )}
    </div>
  );
};

export default SalesTaxList;
