// OwnerDashboard.jsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import axiosAuth from '../../utils/axiosAuth';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Corrected import statement
import OnboardingProgressBar from '../Onboarding/OnboardingProgressBar';
import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './OwnerDashboard.css'; // Ensure this is after the grid styles
import Card from '../Card/Card';

function OwnerDashboard() {
  const [user, setUser] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [onboardingStatus, setOnboardingStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [onboardingProgress, setOnboardingProgress] = useState(0);
  const navigate = useNavigate();

  const token = localStorage.getItem('token');

  // Define default layout
  const defaultLayout = [
    { i: 'subscription', x: 0, y: 0, w: 4, h: 4 },
    { i: 'account', x: 4, y: 0, w: 4, h: 4 },
    { i: 'onboarding', x: 8, y: 0, w: 4, h: 4 },
    // Add more sections as needed
  ];

  // Initialize layout state with saved layout or default
  const [layout, setLayout] = useState(() => {
    const savedLayout = localStorage.getItem('ownerDashboardLayout');
    return savedLayout ? JSON.parse(savedLayout) : defaultLayout;
  });

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }

    // Decode the token to check for expiration
    try {
      const decodedToken = jwtDecode(token);
      if (decodedToken.exp * 1000 < Date.now()) {
        throw new Error('Token expired');
      }
    } catch (err) {
      console.error('Invalid or expired token:', err);
      localStorage.removeItem('token');
      navigate('/login');
      return;
    }

    const fetchData = async () => {
      try {
        // Fetch user profile
        const profileResponse = await axiosAuth.get('/profile', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUser(profileResponse.data);

        // Fetch subscription details
        const subscriptionResponse = await axiosAuth.get('/subscription/current', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSubscription(subscriptionResponse.data);

        // Fetch onboarding status
        const onboardingResponse = await axios.get('onboarding-status/me', {
          headers: { Authorization: `Bearer ${token}` },
        });
        const { currentStep } = onboardingResponse.data;

        setOnboardingStatus(onboardingResponse.data);
        setOnboardingProgress((currentStep / 5) * 100); // Assuming 5 steps total
      } catch (err) {
        if (err.response?.status === 401) {
          localStorage.removeItem('token');
          navigate('/login');
        } else {
          setError(err.response?.data?.message || 'Failed to load dashboard data.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token, navigate]);

  const handleCancelSubscription = async () => {
    try {
      await axiosAuth.post(
        `/subscription/cancel`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Refresh subscription data
      const updatedSubscription = await axiosAuth.get(`/subscription/current`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSubscription(updatedSubscription.data);
      alert('Subscription cancellation scheduled.');
    } catch (err) {
      alert('Failed to cancel subscription.');
    }
  };

  const handleChangePlan = () => {
    navigate('/plans', { state: { isChangingPlan: true } });
  };

  const handleOnboarding = () => {
    const step = onboardingStatus?.currentStep || 1;
    navigate(`/onboarding/step${step}`); // Navigate to the current onboarding step
  };

  if (loading) {
    return <div className="loading">Loading your dashboard, please wait...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (!user) {
    return <div className="error">We could not load your user data. Please try refreshing the page.</div>;
  }

  const userName = user.profile
    ? `${user.profile.firstName} ${user.profile.lastName}`
    : 'Guest';

  // Handle layout changes
  const handleLayoutChange = (newLayout) => {
    setLayout(newLayout);
    localStorage.setItem('ownerDashboardLayout', JSON.stringify(newLayout));
  };

  return (
    <div className="owner-dashboard">
      <h1 className="welcome-header">Welcome, {userName}</h1>
      <GridLayout
        className="layout"
        layout={layout}
        cols={12}
        rowHeight={30}
        width={1200}
        draggableHandle=".card-header"
        onLayoutChange={handleLayoutChange}
        draggable={true}
        resizable={true}
      >
        {/* Subscription Section */}
        <div key="subscription">
          <Card title="Your Subscription">
            {subscription ? (
              <div className="subscription-info">
                <p>Plan: {subscription.name}</p>
                <p>Price: ${subscription.price}</p>
                <p>Status: Active</p>
                <button className="dashboard-button" onClick={handleChangePlan}>
                  Change Plan
                </button>
                <button className="dashboard-button" onClick={handleCancelSubscription}>
                  Cancel Subscription
                </button>
              </div>
            ) : (
              <div>
                <p>You do not have an active subscription.</p>
                <button className="dashboard-button" onClick={() => navigate('/plans')}>
                  Choose a Plan
                </button>
              </div>
            )}
          </Card>
        </div>

        {/* Account Details Section */}
        <div key="account">
          <Card title="Account Details">
            <p>Email: {user.email || 'N/A'}</p>
            <button className="dashboard-button" onClick={() => navigate('/profile/edit')}>
              Edit Profile
            </button>
          </Card>
        </div>

        {/* Onboarding Section */}
        <div key="onboarding">
          <Card title="Onboarding">
            <button className="dashboard-button" onClick={handleOnboarding}>
              Continue Onboarding
            </button>
            <OnboardingProgressBar
              currentStep={onboardingStatus?.currentStep || 1}
              totalSteps={9}
            />
          </Card>
        </div>

        {/* Add more sections as needed */}
      </GridLayout>
    </div>
  );
}

export default OwnerDashboard;
