import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from '@mui/material';
import { Edit, Delete, Lock } from '@mui/icons-material';
import axiosInstance from '../../utils/axios';
import NewEmployeeForm from './NewEmployeeForm';
import EmployeeForm from './EmployeeForm';
import AppUserForm from './AppUserForm.jsx';

function EmployeeList() {
  const [employees, setEmployees] = useState([]); // Store employees
  const [newEmployeeFormOpen, setNewEmployeeFormOpen] = useState(false); // Add Employee Form
  const [employeeFormOpen, setEmployeeFormOpen] = useState(false); // Edit Employee Form
  const [userFormOpen, setUserFormOpen] = useState(false); // App User Form
  const [selectedEmployee, setSelectedEmployee] = useState(null); // Selected employee for any form

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      const response = await axiosInstance.get('/employees');
      setEmployees(response.data);
    } catch (error) {
// $&

    }
  };

  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(`/employees/${id}`);
      fetchEmployees();
    } catch (error) {
// $&

    }
  };

  const handleFormSave = () => {
    fetchEmployees(); // Refresh the employee list after saving
  };

  return (
    <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Employee Management
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setSelectedEmployee(null); // Reset selected employee
          setNewEmployeeFormOpen(true); // Open Add Employee Form
        }}
      >
        Add Employee
      </Button>
      <List>
        {employees.map((employee) => (
          <ListItem key={employee._id}>
            <ListItemText
              primary={`${employee.firstName} ${employee.lastName}`}
              secondary={`Role: ${employee.role}`}
            />
            {/* Edit Employee Button */}
            <IconButton
              onClick={() => {
                setSelectedEmployee(employee); // Set selected employee for editing
                setEmployeeFormOpen(true); // Open Edit Employee Form
              }}
            >
              <Edit />
            </IconButton>
            {/* Delete Employee Button */}
            <IconButton onClick={() => handleDelete(employee._id)}>
              <Delete />
            </IconButton>
            {/* Credentials/User Info Button */}
            <IconButton
              onClick={() => {
                setSelectedEmployee(employee); // Set selected employee for credentials
                setUserFormOpen(true); // Open User Form
              }}
            >
              <Lock />
            </IconButton>
          </ListItem>
        ))}
      </List>

      {/* Add Employee Form */}
      {newEmployeeFormOpen && (
        <NewEmployeeForm
          open={newEmployeeFormOpen}
          onClose={() => setNewEmployeeFormOpen(false)}
          onSave={handleFormSave}
        />
      )}

      {/* Edit Employee Form */}
      {employeeFormOpen && (
        <EmployeeForm
          open={employeeFormOpen}
          onClose={() => setEmployeeFormOpen(false)}
          onSave={handleFormSave}
          initialData={selectedEmployee} // Pass the selected employee for editing
        />
      )}

      {/* App User Form */}
      {userFormOpen && (
        <AppUserForm
          open={userFormOpen}
          onClose={() => setUserFormOpen(false)}
          onSave={handleFormSave}
          selectedEmployee={selectedEmployee} // Pass the selected employee for credentials
        />
      )}
    </Paper>
  );
}

export default EmployeeList;
