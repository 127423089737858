import { styled } from '@mui/system';

export const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: '100vh',
}));

export const FormContainer = styled('div')(({ theme }) => ({
  maxWidth: 600,
  width: '100%',
  marginTop: theme.spacing(2),
}));

export const FormField = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const PlanContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

export const PaymentContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

export const GoogleButton = styled('button')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
  backgroundColor: '#4285F4', // Google Blue
  color: '#fff',
  width: '33%', // Constrain to 1/3 of the container width
  maxWidth: 200, // Ensure it doesn't stretch too wide
  padding: theme.spacing(1, 2),
  border: 'none',
  borderRadius: theme.shape.borderRadius,
  fontSize: '0.9rem',
  cursor: 'pointer',
  textTransform: 'none',
  marginTop: theme.spacing(2),
  '&:hover': {
    backgroundColor: '#357AE8',
  },
  '& .google-icon': {
    width: '20px',
    height: '20px',
  },
}));

export const RegisterButton = styled('button')(({ theme }) => ({
  display: 'block',
  width: '33%', // Constrain to 1/3 of the container width
  maxWidth: 200, // Ensure it doesn't stretch too wide
  padding: theme.spacing(1),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  border: 'none',
  borderRadius: theme.shape.borderRadius,
  fontSize: '1rem',
  cursor: 'pointer',
  textTransform: 'uppercase',
  marginTop: theme.spacing(2),
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));
