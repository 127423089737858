import React, { useState, useEffect } from 'react';
import { TextField, Button, Box } from '@mui/material';

const AdminServiceDetails = ({ service, onSave, onCancel }) => {
  const [serviceData, setServiceData] = useState(service);

  useEffect(() => {
    setServiceData(service);
  }, [service]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setServiceData({ ...serviceData, [name]: value });
  };

  const handleSubmit = () => {
    onSave(serviceData);
  };

  return (
    <Box>
      <TextField
        label="Service Name"
        name="name"
        value={serviceData.name || ''}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Description"
        name="description"
        value={serviceData.description || ''}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <Box mt={2}>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Save
        </Button>
        <Button onClick={onCancel} variant="outlined" sx={{ marginLeft: 2 }}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default AdminServiceDetails;
