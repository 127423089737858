import React, { useRef } from 'react';
import { useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import { TextField } from '@mui/material';

const libraries = ['places'];

const AddressAutocomplete = ({ label, value, onChange }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, // Use your Google API Key
    libraries, // Use the predefined constant
  });

  const autocompleteRef = useRef(null);

  const handlePlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (place && place.formatted_address) {
      onChange(place.formatted_address);
    }
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Autocomplete
      onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
      onPlaceChanged={handlePlaceChanged}
    >
      <TextField
        label={label}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        fullWidth
        margin="normal"
        InputLabelProps={{ style: { color: '#C0C0C0' } }}
        InputProps={{ style: { color: '#00FFFF' } }}
      />
    </Autocomplete>
  );
};

export default AddressAutocomplete;
