import React, { useEffect, useState, useCallback } from 'react';
import SubServiceManagement from '../../ServiceManagement/SubServiceManagement';
import axiosInstance from '../../../../utils/axios';
import { Typography, Box, Button } from '@mui/material';

function Step3({ serviceData, handleServiceUpdate, nextStep, prevStep, isNew }) {
  const [subServices, setSubServices] = useState([]);

  // Define fetchSubServices using useCallback to avoid creating a new function every render
  const fetchSubServices = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`/services/${serviceData._id}/sub-services`);
      setSubServices(response.data);
    } catch (error) {
// $&

    }
  }, [serviceData._id]);

  useEffect(() => {
    if (serviceData._id) {
      fetchSubServices();
    }
  }, [serviceData._id, fetchSubServices]);

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Step 3: Manage Sub-Services
      </Typography>
      <Box>
        {/* Pass subServices to SubServiceManagement so it's used */}
        <SubServiceManagement serviceId={serviceData._id} subServices={subServices} onServiceUpdate={fetchSubServices} />
      </Box>
      <Box mt={4}>
        <Button variant="contained" color="secondary" onClick={prevStep}>
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={nextStep}
          sx={{ ml: 2 }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}

export default Step3;
