import React, { useState } from 'react';
import { Box, Typography, Button, Breadcrumbs, Link } from '@mui/material';
import moment from 'moment';
import axiosInstance from '../../../utils/axios';
import axiosCustomerInstance from '../../../utils/axiosCustomer'; // Import axios instance for CustomerService

function BookingSummary({ bookingData, onBack, onConfirm, ownerId }) {
  const {
    selectedServices,
    selectedSubServices,
    selectedSubBrands,
    appointmentSubtotal,
    estimatedDuration,
    selectedTimeSlot,
    customerInfo,
  } = bookingData;

  const [isConfirming, setIsConfirming] = useState(false);

  const handleConfirmBooking = async () => {
    try {
      setIsConfirming(true); // Disable the button to prevent duplicate submissions

      // Ensure ownerId is present
      if (!ownerId) {
// $&

        setIsConfirming(false);
        return;
      }

      // Ensure employeeId is provided
      if (!selectedTimeSlot.employeeId) {
// $&

        setIsConfirming(false);
        return;
      }

      // Prepare customer data
      const customerData = {
        firstName: customerInfo.firstName?.trim() || '',
        lastName: customerInfo.lastName?.trim() || '',
        email: customerInfo.email?.trim() || '',
        phone: customerInfo.phone?.trim() || '',
        address: customerInfo.address?.trim() || '',
        notes: customerInfo.notes?.trim() || '',
        ownerId, // Add ownerId to associate the customer with the correct account
      };

// $&


      // Create customer in CustomerService
      const customerResponse = await axiosCustomerInstance.post('/customers', customerData);
      const customerId = customerResponse.data._id;

// $&


      // Prepare service data for the booking
      const servicesData = selectedSubServices.map(({ serviceId, subServiceId }) => ({
        service: serviceId,
        subService: subServiceId,
        subBrand: selectedSubBrands[subServiceId]?._id,
      }));

      // Ensure employeeId is an array (required by validation rules)
      const employeeIds = selectedTimeSlot.employeeId ? [selectedTimeSlot.employeeId] : [];

      // Validate employee array
      if (employeeIds.length === 0) {
// $&

        setIsConfirming(false);
        return;
      }

      // Prepare final booking data
      const bookingData = {
        customer: customerId,
        services: servicesData,
        startTime: selectedTimeSlot.startTime,
        endTime: selectedTimeSlot.endTime,
        notes: customerInfo.notes?.trim() || '',
        bay: selectedTimeSlot.bayId,
        employee: employeeIds,
        status: 'Active',
        ownerId, // Associate the appointment with the correct account
      };

// $&


      // Submit the booking to the Scheduler Service
      const bookingResponse = await axiosInstance.post('/appointments', bookingData);
// $&


      // Proceed to the next step or show a confirmation message
      onConfirm();
    } catch (error) {
// $&

    } finally {
      setIsConfirming(false); // Re-enable the button
    }
  };

  return (
    <Box sx={{ p: 4, backgroundColor: '#1C1C1C', color: '#C0C0C0' }}>
      {/* Breadcrumb or Back Button */}
      <Breadcrumbs aria-label="breadcrumb" sx={{ color: '#00FFFF', mb: 3 }}>
        <Link color="inherit" onClick={onBack} sx={{ cursor: 'pointer', color: '#00FFFF' }}>
          Back to Customer Information Entry
        </Link>
        <Typography color="textPrimary">Step 4: Booking Summary and Confirmation</Typography>
      </Breadcrumbs>

      <Typography variant="h4" gutterBottom sx={{ color: '#39FF14' }}>
        Booking Summary and Confirmation
      </Typography>

      {/* Appointment Details */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" sx={{ color: '#00FFFF', mb: 2 }}>
          Appointment Details
        </Typography>
        {selectedTimeSlot.isMultiDay ? (
  <>
    <Typography sx={{ color: '#C0C0C0' }}>
      Start Date and Time: {moment(selectedTimeSlot.startTime).format('MMMM Do, YYYY, h:mm A')}
    </Typography>
    <Typography sx={{ color: '#C0C0C0' }}>
      End Date and Time: {moment(selectedTimeSlot.endTime).format('MMMM Do, YYYY, h:mm A')}
    </Typography>
  </>
) : (
  <>
    <Typography sx={{ color: '#C0C0C0' }}>
      Date: {moment(selectedTimeSlot.startTime).format('MMMM Do, YYYY')}
    </Typography>
    <Typography sx={{ color: '#C0C0C0' }}>
      Time: {`${moment(selectedTimeSlot.startTime).format('h:mm A')} - ${moment(selectedTimeSlot.endTime).format('h:mm A')}`}
    </Typography>
  </>
)}
        <Typography sx={{ color: '#C0C0C0' }}>
          Estimated Duration: {estimatedDuration} Minutes
        </Typography>
        <Typography sx={{ color: '#C0C0C0' }}>
          Subtotal: ${appointmentSubtotal.toFixed(2)}
        </Typography>

        <Typography variant="h6" sx={{ color: '#00FFFF', mt: 3 }}>
          Selected Services
        </Typography>
        {selectedSubServices.map(({ serviceName, subServiceName, subServiceId }) => {
  const subBrand = selectedSubBrands[subServiceId];
  return (
    <Box key={subServiceId} sx={{ ml: 3, mb: 1 }}>
      <Typography sx={{ color: '#C0C0C0' }}> Service: {serviceName}</Typography>
      <Typography sx={{ color: '#C0C0C0' }}>Sub-Service: {subServiceName}</Typography> 
      <Typography sx={{ color: '#C0C0C0' }}>Brand Line: {subBrand ? subBrand.name : 'None'}</Typography>
            </Box>
          );
        })}

        <Typography variant="h6" sx={{ color: '#00FFFF', mt: 3 }}>
          Customer Information
        </Typography>
        <Typography sx={{ color: '#C0C0C0' }}>
          Name: {customerInfo.firstName} {customerInfo.lastName}
        </Typography>
        <Typography sx={{ color: '#C0C0C0' }}>Email: {customerInfo.email}</Typography>
        <Typography sx={{ color: '#C0C0C0' }}>Phone: {customerInfo.phone}</Typography>
        <Typography sx={{ color: '#C0C0C0' }}>Notes: {customerInfo.notes}</Typography>
      </Box>

      {/* Confirm Button */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
        <Button variant="contained" color="secondary" onClick={onBack}>
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleConfirmBooking}
          disabled={isConfirming}
        >
          {isConfirming ? 'Confirming...' : 'Confirm Booking'}
        </Button>
      </Box>
    </Box>
  );
}

export default BookingSummary;
