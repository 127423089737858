// Step1.jsx

import React, { useState } from 'react';
import axiosInstance from '../../../../utils/axios';
import { Paper, Typography, TextField, Button } from '@mui/material';

function Step1({ serviceData, handleServiceUpdate, nextStep, isNew }) {
  const [name, setName] = useState(serviceData.name || '');
  const [description, setDescription] = useState(serviceData.description || '');

  const handleSubmit = async () => {
    const data = { name, description };
    try {
      const response = isNew
        ? await axiosInstance.post('/services', data)
        : await axiosInstance.put(`/services/${serviceData._id}`, data);

      handleServiceUpdate(response.data);
      nextStep();
    } catch (error) {
// $&

    }
  };

  return (
    <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Step 1: Basic Service Details
      </Typography>
      <TextField
        label="Service Name"
        variant="outlined"
        fullWidth
        value={name}
        onChange={(e) => setName(e.target.value)}
        sx={{ mb: 3 }}
      />
      <TextField
        label="Description"
        variant="outlined"
        fullWidth
        multiline
        rows={4}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        sx={{ mb: 3 }}
      />
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Next
      </Button>
    </Paper>
  );
}

export default Step1;
