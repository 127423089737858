import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  Divider,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axios';
import OnboardingProgressBar from './OnboardingProgressBar'; // Import the progress bar
import './OnboardingWizard.css'; // Ensure shared styles

const OnboardingStep5Confirmation = ({ ownerId, onBack }) => {
  const [services, setServices] = useState([]);
  const [brands, setBrands] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Add navigation hook

  useEffect(() => {
    fetchOwnerData();
  }, []);

  const fetchOwnerData = async () => {
    setIsLoading(true);
    try {
      const [servicesResponse, brandsResponse] = await Promise.all([
        axiosInstance.get('/services'),
        axiosInstance.get('/brands'),
      ]);
      setServices(servicesResponse.data);
      setBrands(brandsResponse.data);
    } catch (err) {
// $&

      setError('Failed to fetch owner data.');
    } finally {
      setIsLoading(false);
    }
  };

  const updateOnboardingStatus = async () => {
    setIsSaving(true);
    try {
      await axiosInstance.put(`/onboarding-status/${ownerId}`, {
        currentStep: 9,
      });
      navigate('/dashboard'); // Navigate to the dashboard after completion
    } catch (err) {
// $&

      setError('Failed to update onboarding status.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = async (type, id, parentId = null) => {
    try {
      if (type === 'service') {
        await axiosInstance.delete(`/services/${id}`);
        setServices((prev) => prev.filter((service) => service._id !== id));
      } else if (type === 'brand') {
        await axiosInstance.delete(`/brands/${id}`);
        setBrands((prev) => prev.filter((brand) => brand._id !== id));
      } else if (type === 'sub-service') {
        await axiosInstance.delete(`/sub-services/${id}`);
        setServices((prev) =>
          prev.map((service) =>
            service._id === parentId
              ? {
                  ...service,
                  subServices: service.subServices.filter((sub) => sub._id !== id),
                }
              : service
          )
        );
      } else if (type === 'sub-brand') {
        await axiosInstance.delete(`/sub-brands/${id}`);
        setBrands((prev) =>
          prev.map((brand) =>
            brand._id === parentId
              ? {
                  ...brand,
                  subBrands: brand.subBrands.filter((sub) => sub._id !== id),
                }
              : brand
          )
        );
      }
    } catch (err) {
// $&

      setError(`Failed to delete ${type}.`);
    }
  };

  if (isLoading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Confirm Your Setup
      </Typography>
      <Typography>
        Review your services, brands, and their respective sub-services and sub-brands. You can make adjustments before finalizing. You may also make adjustments after onboarding.
      </Typography>

      {/* Progress Bar */}
      <OnboardingProgressBar currentStep={9} totalSteps={9} />

      <Box mt={4}>
        <Typography variant="h6">Services</Typography>
        <List>
          {services.map((service) => (
            <React.Fragment key={service._id}>
              <ListItem>
                <Typography>{service.name}</Typography>
                <IconButton
                  color="secondary"
                  onClick={() => handleDelete('service', service._id)}
                >
                  <Delete />
                </IconButton>
              </ListItem>
              {service.subServices && service.subServices.length > 0 && (
                <List sx={{ pl: 4 }}>
                  {service.subServices.map((subService) => (
                    <ListItem key={subService._id}>
                      <Typography>{subService.name}</Typography>
                      <IconButton
                        color="secondary"
                        onClick={() =>
                          handleDelete('sub-service', subService._id, service._id)
                        }
                      >
                        <Delete />
                      </IconButton>
                    </ListItem>
                  ))}
                </List>
              )}
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </Box>

      <Box mt={4}>
        <Typography variant="h6">Brands</Typography>
        <List>
          {brands.map((brand) => (
            <React.Fragment key={brand._id}>
              <ListItem>
                <Typography>{brand.name}</Typography>
                <IconButton
                  color="secondary"
                  onClick={() => handleDelete('brand', brand._id)}
                >
                  <Delete />
                </IconButton>
              </ListItem>
              {brand.subBrands && brand.subBrands.length > 0 && (
                <List sx={{ pl: 4 }}>
                  {brand.subBrands.map((subBrand) => (
                    <ListItem key={subBrand._id}>
                      <Typography>{subBrand.name}</Typography>
                      <IconButton
                        color="secondary"
                        onClick={() =>
                          handleDelete('sub-brand', subBrand._id, brand._id)
                        }
                      >
                        <Delete />
                      </IconButton>
                    </ListItem>
                  ))}
                </List>
              )}
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </Box>

      <Box mt={4} display="flex" justifyContent="space-between">
        <Button variant="outlined" color="secondary" onClick={onBack}>
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={updateOnboardingStatus}
          disabled={isSaving}
        >
          {isSaving ? 'Saving...' : 'Confirm and Complete'}
        </Button>
      </Box>
    </Box>
  );
};

export default OnboardingStep5Confirmation;
