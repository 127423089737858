import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import axiosInstance from '../../utils/axios';
import { jwtDecode } from 'jwt-decode';
import OnboardingProgressBar from './OnboardingProgressBar';

const AssignBrandsToOwner = ({ onNext, onBack }) => {
  const [brands, setBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [ownerId, setOwnerId] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    // Decode the token and extract ownerId
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setOwnerId(decodedToken.userId);
      } catch (err) {
// $&

        setError('Failed to decode session token.');
      }
    } else {
      setError('No session token found.');
    }
  }, []);

  useEffect(() => {
    fetchBrands();
  }, []);

  const fetchBrands = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/admin/brands/');
      setBrands(response.data);
    } catch (error) {
// $&

      setError('Failed to load brands.');
    } finally {
      setLoading(false);
    }
  };

  const handleBrandSelect = (brandId) => {
    setSelectedBrands((prev) =>
      prev.includes(brandId) ? prev.filter((id) => id !== brandId) : [...prev, brandId]
    );
  };

  const assignBrands = async () => {
    if (!ownerId) {
      setError('Owner ID is missing.');
      return;
    }

    setIsSaving(true);
    try {
      const payload = {
        brandIds: selectedBrands,
        ownerId,
      };
// $&

      await axiosInstance.post('/admin/brands/assign-bulk', payload);

      // Update onboarding status
      await axiosInstance.put(`/onboarding-status/${ownerId}`, {
        currentStep: 6,
        brands: selectedBrands, // Add the selected brands to the onboarding status
      });

      onNext(selectedBrands);
    } catch (error) {
// $&

      setError('Failed to assign selected brands. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const skipSelection = async () => {
    if (!ownerId) {
      setError('Owner ID is missing.');
      return;
    }

    setIsSaving(true);
    try {
      // Update onboarding status without assigning brands
      await axiosInstance.put(`/onboarding-status/${ownerId}`, {
        currentStep: 6,
      });

      onNext([]);
    } catch (error) {
// $&

      setError('Failed to skip selection. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Box sx={{ mt: 3, bgcolor: '#1C1C1C', p: 3, borderRadius: 2, color: '#C0C0C0' }}>
      <Typography variant="h6" sx={{ color: '#39FF14' }}>
        Select Brands
      </Typography>

      {/* Progress Bar */}
      <OnboardingProgressBar currentStep={6} totalSteps={9} />

      {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
      {loading ? (
        <Typography sx={{ mt: 2 }}>Loading brands...</Typography>
      ) : (
        <FormGroup sx={{ mt: 2 }}>
          {brands.map((brand) => (
            <FormControlLabel
              key={brand._id}
              control={
                <Checkbox
                  checked={selectedBrands.includes(brand._id)}
                  onChange={() => handleBrandSelect(brand._id)}
                />
              }
              label={brand.name}
            />
          ))}
        </FormGroup>
      )}
      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="outlined"
          onClick={onBack}
          sx={{ color: '#00FFFF', borderColor: '#00FFFF', '&:hover': { bgcolor: '#2C2C2C' } }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={assignBrands}
          disabled={!selectedBrands.length || !ownerId || isSaving}
          sx={{ bgcolor: '#39FF14', color: '#000000', '&:hover': { bgcolor: '#00FFFF' } }}
        >
          {isSaving ? 'Saving...' : 'Assign Selected Brands'}
        </Button>
        <Button
          variant="contained"
          onClick={skipSelection}
          sx={{ bgcolor: '#708090', color: '#FFFFFF', '&:hover': { bgcolor: '#A9A9A9' } }}
        >
          Already Chosen
        </Button>
      </Box>
    </Box>
  );
};

export default AssignBrandsToOwner;
