// src/index.js

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import { ThemeContextProvider } from './context/ThemeContext'; // Import ThemeContextProvider

// Create the root container
const container = document.getElementById('root');
const root = createRoot(container);

// Render the app
root.render(
  <ThemeContextProvider> {/* Wrap with ThemeContextProvider */}
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ThemeContextProvider>
);
