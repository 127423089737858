import React, { useState, useEffect, useCallback } from 'react';
import './VehicleSearch.css';
import axiosVehicle from '../../utils/axiosVehicle'; // Update to your Axios instance path

const VehicleSearch = ({ userJwt }) => {
  const [years, setYears] = useState([]);
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [trims, setTrims] = useState([]);
  const [colors, setColors] = useState([]);
  const [images, setImages] = useState([]);

  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMake, setSelectedMake] = useState('');
  const [selectedModel, setSelectedModel] = useState('');
  const [selectedTrim, setSelectedTrim] = useState('');
  const [selectedColor, setSelectedColor] = useState('');

  const cache = new Map(); // In-memory cache for frontend data

  // Helper function to fetch data with caching
  const fetchData = useCallback(async (url, cacheKey, setState) => {
    try {
      if (cache.has(cacheKey)) {
        console.log(`Cache hit for ${cacheKey}`);
        setState(cache.get(cacheKey));
        return;
      }

      console.log(`Cache miss for ${cacheKey}. Fetching from API...`);
      const response = await axiosVehicle.get(url, {
        headers: { Authorization: `Bearer ${userJwt}` },
      });

      cache.set(cacheKey, response.data); // Cache the data
      setState(response.data);
    } catch (error) {
      console.error(`Error fetching data from ${url}:`, error.response?.data || error.message);
    }
  }, [cache, userJwt]);

  useEffect(() => {
    fetchData('/vehicles/years', 'years', setYears);
  }, [fetchData]);

  useEffect(() => {
    if (selectedYear) {
      fetchData(`/vehicles/makes/${selectedYear}`, `makes:${selectedYear}`, setMakes);
    } else {
      setMakes([]);
    }
  }, [selectedYear, fetchData]);

  useEffect(() => {
    if (selectedYear && selectedMake) {
      fetchData(
        `/vehicles/models/${selectedYear}/${selectedMake}`,
        `models:${selectedYear}:${selectedMake}`,
        setModels
      );
    } else {
      setModels([]);
    }
  }, [selectedYear, selectedMake, fetchData]);

  useEffect(() => {
    if (selectedYear && selectedMake && selectedModel) {
      fetchData(
        `/vehicles/trims/${selectedYear}/${selectedMake}/${selectedModel}`,
        `trims:${selectedYear}:${selectedMake}:${selectedModel}`,
        setTrims
      );
    } else {
      setTrims([]);
    }
  }, [selectedYear, selectedMake, selectedModel, fetchData]);

  useEffect(() => {
    if (selectedYear && selectedMake && selectedModel && selectedTrim) {
      fetchData(
        `/vehicles/colors/${selectedYear}/${selectedMake}/${selectedModel}/${selectedTrim}`,
        `colors:${selectedYear}:${selectedMake}:${selectedModel}:${selectedTrim}`,
        setColors
      );
    } else {
      setColors([]);
    }
  }, [selectedYear, selectedMake, selectedModel, selectedTrim, fetchData]);

  useEffect(() => {
    if (selectedYear && selectedMake && selectedModel && selectedTrim && selectedColor) {
      fetchData(
        `/vehicles/images/${selectedYear}/${selectedMake}/${selectedModel}/${selectedTrim}/${selectedColor}`,
        `images:${selectedYear}:${selectedMake}:${selectedModel}:${selectedTrim}:${selectedColor}`,
        setImages
      );
    } else {
      setImages([]);
    }
  }, [selectedYear, selectedMake, selectedModel, selectedTrim, selectedColor, fetchData]);

  return (
    <div>
      <h1>Vehicle Search</h1>
      <div>
        <label>Year:</label>
        <select value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
          <option value="">Select Year</option>
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label>Make:</label>
        <select value={selectedMake} onChange={(e) => setSelectedMake(e.target.value)} disabled={!selectedYear}>
          <option value="">Select Make</option>
          {makes.map((make) => (
            <option key={make} value={make}>
              {make}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label>Model:</label>
        <select value={selectedModel} onChange={(e) => setSelectedModel(e.target.value)} disabled={!selectedMake}>
          <option value="">Select Model</option>
          {models.map((model) => (
            <option key={model} value={model}>
              {model}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label>Trim:</label>
        <select value={selectedTrim} onChange={(e) => setSelectedTrim(e.target.value)} disabled={!selectedModel}>
          <option value="">Select Trim</option>
          {trims.map((trim) => (
            <option key={trim} value={trim}>
              {trim}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label>Color:</label>
        <select value={selectedColor} onChange={(e) => setSelectedColor(e.target.value)} disabled={!selectedTrim}>
          <option value="">Select Color</option>
          {colors.map((color) => (
            <option key={color} value={color}>
              {color}
            </option>
          ))}
        </select>
      </div>
      <div>
        <h2>Images:</h2>
        {images.length > 0 ? (
          images.map((image, index) => <img key={index} src={image} alt={`Vehicle ${index}`} />)
        ) : (
          <p>No images available</p>
        )}
      </div>
    </div>
  );
};

export default VehicleSearch;
