import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material';
import axiosInstance from '../../utils/axios'; // Use the configured axiosInstance

function AppUserForm({ open, onClose, onSave, selectedEmployee }) {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (selectedEmployee?._id) {
      fetchUserCredentials(selectedEmployee._id);
    }
  }, [selectedEmployee]);

  const fetchUserCredentials = async (employeeId) => {
    try {
// $&

      const response = await axiosInstance.get(`/users/${employeeId}`);
      const { username, email } = response.data;
      setUsername(username);
      setEmail(email);
    } catch (error) {
// $&

    }
  };

  const handleSave = async () => {
    try {
      if (!selectedEmployee) {
// $&

        return;
      }

      const updatedData = {
        username,
        email,
        ...(password && { password }), // Only include password if updated
      };

// $&


      await axiosInstance.put(`/users/${selectedEmployee._id}`, updatedData);

      onSave(); // Refresh employee list or perform any required action
      onClose(); // Close the dialog
    } catch (error) {
// $&

    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit User Credentials</DialogTitle>
      <DialogContent>
        <TextField
          label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          margin="normal"
          placeholder="Leave blank to keep current password"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AppUserForm;
