import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosAuth from '../../utils/axiosAuth';
import './AdminDashboard.css';

function AdminDashboard() {
  const [subscriptions, setSubscriptions] = useState([]);
  const [users, setUsers] = useState([]);
  const [loadingSubscriptions, setLoadingSubscriptions] = useState(true);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const subscriptionsResponse = await axiosAuth.get(
          '/subscription/plans',
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setSubscriptions(subscriptionsResponse.data);
      } catch (err) {
// $&

        setError('Failed to load subscription plans.');
      } finally {
        setLoadingSubscriptions(false);
      }
    };

    const fetchUsers = async () => {
      try {
        const usersResponse = await axiosAuth.get('/users', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUsers(usersResponse.data);
      } catch (err) {
// $&

        setError('Failed to load users.');
      } finally {
        setLoadingUsers(false);
      }
    };

    fetchSubscriptions();
    fetchUsers();
  }, [token]);

  const handleCreatePlan = () => {
    navigate('/admin/create-plan');
  };

  const handleEditPlan = (planId) => {
    navigate(`/admin/edit-plan/${planId}`);
  };

  const handleDeletePlan = async (planId) => {
    if (window.confirm('Are you sure you want to delete this plan?')) {
      try {
        await axiosAuth.delete(`/subscription/plans/${planId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSubscriptions(subscriptions.filter((plan) => plan._id !== planId));
        alert('Subscription plan deleted successfully.');
      } catch (err) {
// $&

        alert('Failed to delete subscription plan.');
      }
    }
  };

  const handleManageUser = (userId) => {
    navigate(`/admin/users/${userId}`);
  };

  const handleServiceManagement = () => {
    navigate('/admin/services');
  };

  if (loadingSubscriptions || loadingUsers) {
    return <div className="loading">Loading admin dashboard...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="admin-dashboard">
      <h1>Admin Dashboard</h1>

      <h2>Manage Services</h2>
      <button onClick={handleServiceManagement}>Manage Services</button>

      <h2>Subscription Plans</h2>
      <button onClick={handleCreatePlan}>Create New Plan</button>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Price</th>
            <th>Duration</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {subscriptions.map((plan) => (
            <tr key={plan._id}>
              <td>{plan.name}</td>
              <td>${plan.price}</td>
              <td>{plan.duration}</td>
              <td>
                <button onClick={() => handleEditPlan(plan._id)}>Edit</button>
                <button onClick={() => handleDeletePlan(plan._id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <h2>Users</h2>
      <table>
        <thead>
          <tr>
            <th>Username</th>
            <th>Email</th>
            <th>Role</th>
            <th>Subscription</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user._id}>
              <td>{user.username}</td>
              <td>{user.email}</td>
              <td>{user.accountLevelRole || user.appLevelRole}</td>
              <td>{user.subscriptionPlan ? user.subscriptionPlan.name : 'None'}</td>
              <td>
                <button onClick={() => handleManageUser(user._id)}>Manage</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AdminDashboard;
