import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Alert,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material';
import AddSubService from '../Services/ServiceManagement/AddSubService';
import axiosInstance from '../../utils/axios';
import OnboardingProgressBar from './OnboardingProgressBar'; // Import Progress Bar

const AddASubServiceOnboarding = ({ ownerId, onNext, onBack }) => {
  const [services, setServices] = useState([]);
  const [selectedServiceId, setSelectedServiceId] = useState('');
  const [subServices, setSubServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (ownerId) {
      fetchServices();
    }
  }, [ownerId]);

  useEffect(() => {
    if (selectedServiceId) {
      fetchSubServices();
    }
  }, [selectedServiceId]);

  const fetchServices = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/services`);
      setServices(response.data);
    } catch (err) {
// $&

      setError('Failed to fetch services. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const fetchSubServices = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/services/${selectedServiceId}/sub-services`);
      setSubServices(response.data);
    } catch (err) {
// $&

      setError('Failed to fetch sub-services.');
    } finally {
      setLoading(false);
    }
  };

  const handleServiceSelect = (event) => {
    setSelectedServiceId(event.target.value);
    setSubServices([]); // Clear sub-services list on service change
    setSuccessMessage(''); // Clear success message when selecting a new service
  };

  const handleSubServiceAdded = (newSubService) => {
    setSuccessMessage('Sub-service added successfully!');
    setSubServices((prevSubServices) => [...prevSubServices, newSubService]);
  };

  const updateOnboardingStatus = async () => {
    setIsSaving(true);
    try {
      await axiosInstance.put(`/onboarding-status/${ownerId}`, {
        currentStep: 8,
      });
      onNext();
    } catch (err) {
// $&

      setError('Failed to update onboarding status.');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Box sx={{ mt: 3, bgcolor: '#1C1C1C', p: 3, borderRadius: 2, color: '#C0C0C0' }}>
      {/* Progress Bar */}
      <OnboardingProgressBar currentStep={8} totalSteps={9} />

      <Typography variant="h6" gutterBottom sx={{ color: '#39FF14' }}>
        Add Sub-Services to Your Services
      </Typography>
      <Typography sx={{ mb: 2 }}>
        Select a service to add sub-services. You may skip this step and add them later if you prefer.
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      {successMessage && <Alert severity="success" sx={{ mb: 2 }}>{successMessage}</Alert>}
      <Select
        fullWidth
        value={selectedServiceId}
        onChange={handleServiceSelect}
        displayEmpty
        sx={{
          mb: 2,
          bgcolor: '#2C2C2C',
          color: '#00FFFF',
          '& .MuiSelect-icon': { color: '#00FFFF' },
        }}
      >
        <MenuItem value="" disabled>
          Select a Service
        </MenuItem>
        {services.map((service) => (
          <MenuItem key={service._id} value={service._id}>
            {service.name}
          </MenuItem>
        ))}
      </Select>
      {selectedServiceId && (
        <>
          <AddSubService
            serviceId={selectedServiceId}
            onSubServiceAdded={handleSubServiceAdded}
          />
          <Box sx={{ mt: 3, bgcolor: '#2C2C2C', p: 2, borderRadius: 1 }}>
            <Typography variant="h6" sx={{ color: '#39FF14' }}>
              Current Sub-Services
            </Typography>
            {loading ? (
              <CircularProgress sx={{ color: '#39FF14', mt: 2 }} />
            ) : subServices.length ? (
              <List>
                {subServices.map((subService) => (
                  <React.Fragment key={subService._id}>
                    <ListItem>
                      <ListItemText
                        primary={subService.name}
                        secondary={`Install Time: ${subService.installTime} min, Cure Time: ${subService.cureTime} min`}
                        sx={{ color: '#00FFFF' }}
                      />
                    </ListItem>
                    <Divider sx={{ bgcolor: '#39FF14' }} />
                  </React.Fragment>
                ))}
              </List>
            ) : (
              <Typography sx={{ color: '#C0C0C0', mt: 2 }}>
                No sub-services added yet.
              </Typography>
            )}
          </Box>
        </>
      )}
      <Box mt={3} display="flex" justifyContent="space-between">
        <Button
          variant="outlined"
          sx={{ color: '#00FFFF', borderColor: '#00FFFF' }}
          onClick={onBack}
        >
          Back
        </Button>
        <Button
          variant="contained"
          sx={{ bgcolor: '#39FF14', color: '#000000', '&:hover': { bgcolor: '#00FFFF' } }}
          onClick={updateOnboardingStatus}
          disabled={isSaving}
        >
          {isSaving ? 'Saving...' : 'Next'}
        </Button>
      </Box>
    </Box>
  );
};

export default AddASubServiceOnboarding;
