import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Table, TableBody, TableCell, TableHead, TableRow, CircularProgress, IconButton } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import axiosInstance from '../../../utils/axios';

const AdminBrandList = ({ onEditBrand }) => {
  const [brands, setBrands] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchBrands();
  }, []);

  const fetchBrands = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get('/admin/brands');
      setBrands(response.data);
    } catch (err) {
// $&

      setError('Failed to fetch brands.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (brandId) => {
    const updatedBrands = brands.filter((brand) => brand._id !== brandId);
    setBrands(updatedBrands); // Optimistically update the UI
    try {
      await axiosInstance.delete(`/admin/brands/${brandId}`);
    } catch (error) {
// $&

      setBrands([...updatedBrands, brands.find((brand) => brand._id === brandId)]); // Rollback on error
    }
  };

  if (isLoading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Admin: Manage Brands
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {brands.map((brand) => (
            <TableRow key={brand._id}>
              <TableCell>{brand.name}</TableCell>
              <TableCell>{brand.description}</TableCell>
              <TableCell>
                <IconButton onClick={() => onEditBrand(brand)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDelete(brand._id)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default AdminBrandList;
