import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme, Switch, Box, Typography } from '@mui/material';
import {
  Root,
  TopBar,
  Title,
  Subtitle,
  PlanContainer,
  PlanCard,
  PlanTitle,
  PlanDescription,
  PlanPrice,
  SignUpButton,
  SmallButton,
} from './styles';
import { useThemeMode } from '../../context/ThemeContext';
import axiosAuth from '../../utils/axiosAuth';

function Home() {
  const [plans, setPlans] = useState([]);
  const navigate = useNavigate();
  const theme = useTheme();
  const { toggleTheme } = useThemeMode();

  useEffect(() => {
    axiosAuth
      .get('/subscription/plans')
      .then((response) => setPlans(response.data))
      .catch((error) => console.error('Error fetching subscription plans:', error));
  }, []);

  const handleSignUp = (planId) => {
    navigate('/register', { state: { selectedPlanId: planId } });
  };

  return (
    <Root>
      <TopBar>
        {/* Dark/Light Mode Toggle */}
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="body2">
            {theme.palette.mode === 'dark' ? 'Dark Mode' : 'Light Mode'}
          </Typography>
          <Switch
            checked={theme.palette.mode === 'dark'}
            onChange={toggleTheme}
            inputProps={{ 'aria-label': 'toggle dark mode' }}
          />
        </Box>
        {/* Login Button */}
        <SmallButton onClick={() => navigate('/login')}>Login</SmallButton>
      </TopBar>

      <Title>Welcome to DriveSuite CRM!</Title>
      <Subtitle>Select a subscription plan to get started:</Subtitle>
      <PlanContainer>
        {plans.map((plan) => (
          <PlanCard key={plan._id}>
            <PlanTitle>{plan.name}</PlanTitle>
            <PlanDescription>{plan.description}</PlanDescription>
            <PlanPrice>Price: ${plan.price}</PlanPrice>
            <SignUpButton onClick={() => handleSignUp(plan._id)}>Sign Up</SignUpButton>
          </PlanCard>
        ))}
      </PlanContainer>
    </Root>
  );
}

export default Home;
